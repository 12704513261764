import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        "input"
    ];

    private inputTargets: HTMLInputElement[];

    reset(event: Event): void {
        event.preventDefault();

        this.inputTargets.forEach((input) => {
            if (input.type === "checkbox" || input.type === "radio") {
                this.resetState(input, false);
            } else {
                this.resetValue(input, false);
            }
        });
    }

    resetToDefault(): void {
        this.inputTargets.forEach((input) => {
            if (input.type === "checkbox" || input.type === "radio") {
                this.resetState(input, true);
            } else {
                this.resetValue(input, true);
            }
        });
    }

    resetForm(): void {
        (this.element as HTMLFormElement).reset();
    }

    private resetValue(input, useDefault): void {
        if (useDefault) input.value = input.defaultValue;
        else input.value = input.dataset.resetValue;

        input.dispatchEvent(new Event('change'));
    }

    private resetState(input, useDefault): void {
        if (useDefault) input.checked = input.defaultChecked;
        else input.checked = input.dataset.resetState === "true";

        input.dispatchEvent(new Event('change'));
    }
}
