import { Controller } from "stimulus";
import Sentry from "@utils/sentry";
import { selectizeOf } from "@utils/selectize";

export default class extends Controller {
    static targets = ["mediaInput", "otherInput", "additionalProject"];
    private projectTechnologyWithStoreDescription: Array<string> = [
        "Android",
        "iOS"
    ];

    mediaInputTarget: HTMLInputElement;
    otherInputTarget: HTMLInputElement;
    additionalProjectTarget: HTMLDivElement;
    hasAdditionalProjectTarget: boolean;

    connect() {
        if (this.hasAdditionalProjectTarget) {
            this.additionalProjectTarget.style.display = "none";
        }

        if (this.otherInputTarget.value === "") {
            this.hideOther();
        } else {
            this.markOtherOption();
        }
    }

    onChangeMediaField() {
        if (this.mediaInputTarget.value === "Other ") {
            this.showOther();
        } else {
            this.hideOther();
        }

        if (this.hasAdditionalProjectTarget) {
            if (this.projectTechnologyWithStoreDescription.includes(this.mediaInputTarget.value)) {
                this.additionalProjectTarget.style.display = "block";
            } else {
                this.additionalProjectTarget.style.display = "none";
            }
        }
    }

    private showOther() {
        this.otherInputTarget.classList.remove("hidden");
    }

    private hideOther() {
        this.otherInputTarget.classList.add("hidden");
    }

    private markOtherOption() {
        this.mediaInputTarget.value = "Other ";
        selectizeOf(this.mediaInputTarget)
            .then((selectize) => selectize.setValue("Other "))
            .catch(err => {
                Sentry.notify(err);
            });
    }
}
