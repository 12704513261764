import { SynSuiteIndicatorNotifications } from "@phrase/syntax-ui";
import { Controller } from "stimulus";
import { createApp } from "vue/dist/vue.esm-bundler.js";
import { reactive, h } from "vue";

export default class extends Controller {
    static targets = ["mountElement"];
    mountElementTarget: HTMLElement;
    private attrs;

    connect() {
        this.attrs = JSON.parse(this.data.get("attrs"));
        const props = reactive({
            subscription: this.attrs.subscription,
            products: this.attrs.products,
            displayedIndicators: JSON.parse(this.data.get("displayedIndicators")),
            locale: this.data.get("locale")
        });
        const app = createApp({
            render: () => h({
                template: this.template(),
                props: ["subscription", "products", "displayedIndicators"]
            },
            props)
        });
        app.component("SynSuiteIndicatorNotifications", SynSuiteIndicatorNotifications);
        app.mount(this.mountElementTarget);
    }

    private template(): string {
        return `<SynSuiteIndicatorNotifications v-bind="$props">
        </SynSuiteIndicatorNotifications>`;
    }
}
