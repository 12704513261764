import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["form"];
    formTarget: HTMLFormElement;

    download(e: Event) {
        e.preventDefault();
        this.formTarget.submit();
    }
}
