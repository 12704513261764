import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "form",
        "accessControlForm",
        "localeUpdateForm",
        "localeCodeSelect"
    ];

    formTarget: HTMLFormElement;
    accessControlFormTarget: HTMLDivElement;
    localeUpdateFormTarget: HTMLDivElement;
    localeCodeSelectTarget: HTMLSelectElement;

    private modalDialog: HTMLDivElement;
    private accessControlTitle: string;
    private oldLocaleCode: string;

    connect() {
        this.oldLocaleCode = this.localeCodeSelectTarget.value;
        this.accessControlTitle = this.data.get('access-control-title');
    }

    confirmLocaleAccess(event) {
        if (this.membershipDLACount > 0 && this.isLocaleChanged()) {

            this.accessControlFormTarget.classList.toggle('hidden');
            this.localeUpdateFormTarget.classList.toggle('hidden');

            this.modalDialog = this.accessControlFormTarget.closest(".modal-dialog");
            this.modalDialog.classList.remove('modal-lg');
            this.modalDialog.querySelector('.modal-header .modal-title').innerHTML = this.accessControlTitle;

            this.oldLocaleCode = this.localeCodeSelectTarget.value;

            event.stopPropagation();
            event.preventDefault();
        }
    }

    isLocaleChanged(): boolean {
        return(this.localeCodeSelectTarget.value != this.oldLocaleCode);
    }

    private get membershipDLACount(): number { return parseInt(this.data.get('memberships-count')); }
}
