import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "headerSidebarToggle",
    ];

    headerSidebarToggleTarget: HTMLElement;

    private sidebarLocalStorageKey = "Side navigation state";

    connect() {
        if (this.shouldSidebarBeHidden()) {
            this.hideSidebar();
        }

        if (this.isSmallScreen()) {
            this.closeSidebar();
        }

        if (this.isSidebarVisible()) {
            this.headerSidebarToggleTarget.classList.add("hidden");
        } else {
            this.headerSidebarToggleTarget.classList.remove("hidden");
        }
    }

    hideSidebar() {
        this.closeSidebar();
        this.setStoredSidebarState({ isClosed: true });
    }

    showSidebar() {
        this.openSidebar();
        this.setStoredSidebarState({ isClosed: false });
    }

    private setStoredSidebarState({ isClosed }: { isClosed: boolean }) {
        const newState = JSON.stringify({ closed: isClosed });
        localStorage.setItem(this.sidebarLocalStorageKey, newState);
        window.dispatchEvent(new StorageEvent('storage', {
            key: this.sidebarLocalStorageKey,
            newValue: newState,
            storageArea: window.localStorage,
        }));
    }

    private closeSidebar() {
        this.element.classList.remove("syn_page-layout--show-sidebar");
        this.headerSidebarToggleTarget.classList.remove("hidden");
    }

    private openSidebar() {
        this.element.classList.add("syn_page-layout--show-sidebar");
        this.headerSidebarToggleTarget.classList.add("hidden");
    }

    private isSidebarVisible(): boolean {
        return this.element.classList.contains("syn_page-layout--show-sidebar");
    }

    private shouldSidebarBeHidden(): boolean {
        const data = JSON.parse(localStorage.getItem("Side navigation state"));
        return (data != null && data.closed);
    }

    private isSmallScreen(): boolean {
        return window.matchMedia("(max-width: 991px)").matches;
    }
}
