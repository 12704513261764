import { camelize } from "@utils/camelize";
import { Controller } from "stimulus";
import Sentry from "@utils/sentry";
import DataUtils from "@utils/data_utils";

import { ModalContainer } from '@utils/types';
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = [
        "modal", "autoShowModal"
    ];

    private modalTarget: HTMLDivElement & ModalContainer;
    private autoShowModalTarget: HTMLButtonElement;
    private initialModalHtml;
    private hasModalTarget: boolean;
    private hasAutoShowModalTarget: boolean;

    connect() {
        this.element[camelize(this.identifier)] = this;

        if (this.hasModalTarget) {
            this.initialModalHtml = this.modalTarget.outerHTML;
        }

        if (this.hasAutoShowModalTarget) {
            setTimeout(() => {
                this.autoShowModalTarget.dataset.autoShow === "true" && this.show();
            });
        }
    }

    disconnect() {
        if (this.hasModalTarget) {
            this.removeModalOpenState();
            this.modalTarget.removeEventListener('jquery:modal:hide', this.resetModal.bind(this));
        }
    }

    resetModal() {
        if (this.hasModalTarget) {
            this.modalTarget.outerHTML = this.initialModalHtml;
        }
    }

    show(event?: MouseEvent) {
        event?.preventDefault();
        if (!this.data.get("surpressBSModal")) {
            this.modalTarget.modal.show();
        }

        const target = event?.currentTarget as HTMLElement;

        DataUtils.request(
            this.getPath(target),
            void 0,
            {
                parse: false
            }, {
                method: "GET",
                credentials: "same-origin",
                headers: { "X-Requested-With": "XMLHttpRequest" }
            })
            .then(data => data.text())
            .then(text => {
                this.removeModalBackdrop();

                this.modalTarget.outerHTML = text;
                this.modalTarget.addEventListener('jquery:modal:hide', this.resetModal.bind(this));
            })
            .catch((err: string) => {
                this.element.dispatchEvent(new Event("remote-modal:error"));
                Sentry.notify(err);
            });
    }

    removeModalOpenState() {
        // @HACK: remove bootstrap modal open state
        document.body.classList.remove("modal-open");
    }

    removeModalBackdrop() {
        // @HACK: remove bootstrap backdrop
        const modalBackdrop = document.querySelector(".modal-backdrop");
        if (modalBackdrop !== null) DomUtils.remove(modalBackdrop);
    }

    getPath(target?: HTMLElement): string {
        if (this.hasAutoShowModalTarget) {
            return this.autoShowModalTarget.dataset.remoteModalPath;
        } else {
            return target?.dataset?.remoteModalPath || this.data.get('path');
        }
    }
}
