import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        'newAccountProjectJobTemplatePath'
    ];

    private newAccountProjectJobTemplatePathTarget: HTMLInputElement;

    selectProject(_e) {
        const newTemplateURL = this.newAccountProjectJobTemplatePathTarget.getElementsByTagName('input')[0].value;
        window.location.href = newTemplateURL;
    }
}
