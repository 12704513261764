import { Controller } from "stimulus";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = ["trigger", "collapsableElement"];

    private triggerTarget: HTMLLinkElement;
    private collapsableElementTargets: HTMLElement[];


    toggle(event: Event) {
        event.preventDefault();
        this.triggerTarget.blur();
        if (this.isClosed) {
            this.show();
        } else {
            this.hide();
        }
    }

    private show() {
        this.collapsableElementTargets.forEach(DomUtils.showElement);

        if (this.data.has("label-hide")) {
            this.triggerTarget.innerText = this.data.get("label-hide");
        } else if (this.data.has("icon-hide")) {
            this.triggerTarget.dataset.icon = this.data.get("icon-hide");
        }
    }

    private hide() {
        this.collapsableElementTargets.forEach(DomUtils.hideElement);

        if (this.data.has("label-show")) {
            this.triggerTarget.innerText = this.data.get("label-show");
        } else if (this.data.has("icon-show")) {
            this.triggerTarget.dataset.icon = this.data.get("icon-show");
        }
    }

    private get isClosed(): boolean {
        return this.collapsableElementTargets.every(DomUtils.elementIsHidden);
    }
}
