import { Controller } from "stimulus";
import Spinner from "@utils/spinner";
import { debounce } from "@utils/debounce";
import { camelize } from "@utils/camelize";

export default class extends Controller {
    static targets = [
        "form",
        "button",
        "resultList",
        "locale",
        "locales",
        "localeInput",
        "localeSelect",
        "localeSelectFrame",
        "localeFrame",
        "project",
        "projects",
        "projectInput",
        "projectSelect",
        "searchInput",
        "entityToggle",
        "sortingInput",
        "sortingSelect",
        "sortingSelectFrame",
    ];

    element: HTMLElement;
    spinner: Spinner;

    formTarget: HTMLFormElement;
    buttonTarget: HTMLButtonElement;
    resultListTarget: HTMLElement;
    localeTarget: HTMLLIElement;
    localesTarget: HTMLElement;
    localeInputTarget: HTMLInputElement;
    localeSelectTarget: HTMLElement;
    localeSelectFrameTarget: HTMLElement;
    localeFrameTarget: HTMLElement;
    projectTarget: HTMLLIElement;
    projectsTarget: HTMLElement;
    projectSelectTarget: HTMLElement;
    projectInputTarget: HTMLInputElement;
    localeProjectTarget: HTMLElement;
    searchInputTarget: HTMLInputElement;
    entityToggleTarget: HTMLButtonElement;
    sortingInputTarget: HTMLInputElement;
    sortingSelectTarget: HTMLElement;
    sortingSelectTargets: HTMLElement[];
    sortingSelectFrameTarget: HTMLElement;

    hasResultListTarget: boolean;
    hasSortingInputTarget: boolean;
    hasSortingSelectFrameTarget: boolean;
    localeCode: string;
    projectCode: string;

    connect() {
        this.element[camelize(this.identifier)] = this;
        this.spinner = new Spinner(this.hasResultListTarget ? this.resultListTarget : this.element);

        this.localeCode = this.formTarget.dataset.initialLocale;
        this.localeInputTarget.value = this.localeCode ? this.localeCode : null;

        this.projectCode = this.formTarget.dataset.initialProject;
        this.projectInputTarget.value = this.projectCode ? this.projectCode : null;

        if (this.formTarget.dataset.initialQuery) {
            this.reload();
        }
    }

    update(event: CustomEvent) {
        if (this.hasResultListTarget) {
            this.resultListTarget.innerHTML = event.detail.data;
        }

        this.spinner.stop();
    }

    focusFirstResult(event: CustomEvent) {
        (this.resultListTarget.querySelector(".syn_list.syn_scrollbar")?.firstElementChild as HTMLDivElement)?.focus?.({ focusVisible: true } as FocusOptions);
    }

    switchEntity(event: CustomEvent) {
        const entityTarget = <HTMLElement>event.target;
        this.formTarget.setAttribute("action", entityTarget.dataset.url);
        this.localeInputTarget.setAttribute("name", entityTarget.dataset.localeFilterInputName);
        this.projectInputTarget.setAttribute("name", entityTarget.dataset.projectFilterInputName);
        if (this.hasSortingSelectFrameTarget) {
            let sortingFrameSrc = this.sortingSelectFrameTarget.dataset.src.replace(/entity=(?:key|job|project)/, "entity=" + entityTarget.dataset.entity);
            sortingFrameSrc = sortingFrameSrc.replace(/name=(?:sorting|jobs_search%5Bsort_by%5D|sort_mode)/, "name=" + entityTarget.dataset.sortingInputName);
            (this.sortingSelectFrameTarget as any).src = sortingFrameSrc;
        }
    }

    showSpinner() {
        this.spinner.start();
    }

    hideSpinner() {
        this.spinner.stop();
    }

    reload() {
        this.submitSearch();
    }

    submit() {
        if (!this.hasResultListTarget) {
            return;
        }
        debounce(this.submitSearch.bind(this));
    }

    open(event: Event) {
        const data = new FormData(this.formTarget);
        const entity = <HTMLElement>document.querySelectorAll('.advanced-global-search-subheader .syn_btn--secondary--active')[0];
        const url = new URL(this.formTarget.dataset.searchUrl, window.location.href);
        const searchParams = new URLSearchParams([...(data as any).entries()]);
        searchParams.delete('paginated');
        if (entity) {
            searchParams.append("entity", entity.dataset.entity);
        }
        url.search = searchParams.toString();
        window.open(url.href);
    }

    redirect(event: Event) {
        event.preventDefault();
        const data = new FormData(this.formTarget);
        const entity = <HTMLElement>document.querySelectorAll('.advanced-global-search-subheader .syn_btn--secondary--active')[0];
        const url = new URL(this.formTarget.dataset.searchUrl, window.location.href);
        const searchParams = new URLSearchParams([...(data as any).entries()]);
        searchParams.delete('paginated');
        if (entity) {
            searchParams.append("entity", entity.dataset.entity);
        }
        url.search = searchParams.toString();
        window.location.href = url.href;
    }

    switchLocale(event: CustomEvent) {
        this.preventDropdownFromClosing(null);
        const previousLocaleValue = this.localeInputTarget.value;
        if (event.detail.value === this.localeTarget.dataset.value) {
            this.localeInputTarget.value = null;
        } else {
            this.localeInputTarget.value = event.detail.value;
        }

        if (this.hasSortingSelectFrameTarget) {
            if (!previousLocaleValue && !!this.localeInputTarget.value) {
                this.resetSortingDropdown();
            } else if (!!previousLocaleValue && !this.localeInputTarget.value) {
                this.resetSortingDropdown();
            }
        }
        this.submitSearch();
    }

    switchSorting(event: CustomEvent) {
        this.preventDropdownFromClosing(null);
        this.sortingInputTarget.value = event.detail.value;
        this.submitSearch();
    }

    switchProject(event: CustomEvent) {
        this.preventDropdownFromClosing(null);
        if (event.detail.value === this.projectTarget.dataset.value) {
            this.projectInputTarget.value = null;
        } else {
            this.projectInputTarget.value = event.detail.value;
        }
        this.localeInputTarget.value = null;
        this.resetLocaleDropdown(this.projectInputTarget.value);
        this.submitSearch();
    }

    resetSortingDropdown() {
        const sortingFrameSrc = this.sortingSelectFrameTarget.dataset.src.replace(/locale_code=(?:\w+-?\w+)?\??/, "locale_code=" + this.localeInputTarget.value);
        (this.sortingSelectFrameTarget as any).src = sortingFrameSrc;
        this.sortingInputTarget.value = null;
    }

    resetLocaleDropdown(projectId) {
        let frameSrc = this.localeSelectFrameTarget.dataset.src;
        if (projectId !== null) {
            frameSrc = frameSrc + "&project_id=" + projectId;
        }
        (this.localeSelectFrameTarget as any).src = frameSrc;
    }

    showProjectSelect(e: MouseEvent) {
        this.projectSelectTarget.classList.remove("hidden");
    }

    hideProjectSelect(e: MouseEvent) {
        this.projectSelectTarget.classList.add("hidden");
    }

    private submitSearch() {
        if (this.searchInputTarget.value !== "") {
            this.resultListTarget.innerHTML = "";
            this.spinner.start();
            this.buttonTarget.click();
        }
    }

    private preventDropdownFromClosing(_) {
        (<any>window).$(this.element).one("hide.bs.dropdown", () => {
            return false;
        });
    }
}

