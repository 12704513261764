import { Controller } from "stimulus";

export default class extends Controller {
    private onMouseEnter;
    private onMouseLeave;

    private timeout;

    // Since these two event handlers are everything this controller needs
    // and since this is only a helper for the existing Bootstrap dropdown,
    // we do not use the Stimulus action API and reduce the amount of markup changes
    connect() {
        this.onMouseEnter = this.element.addEventListener("mouseenter", () => {
            this.element.classList.add("open");
            clearTimeout(this.timeout);
        });
        this.onMouseLeave = this.element.addEventListener("mouseleave", () => {
            this.timeout = setTimeout(() => {
                this.element.classList.remove("open");
            }, 250);
        });
    }

    disconnect() {
        this.element.removeEventListener("mouseenter", this.onMouseEnter);
        this.element.removeEventListener("mouseleave", this.onMouseLeave);
    }
}
