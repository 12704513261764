import { Controller } from "stimulus";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = [
        "item",
        "template",
        "wrapper",
    ];

    private templateTarget: HTMLTemplateElement;
    private wrapperTarget: HTMLDivElement;

    add(e: Event) {
        e.preventDefault();
        (e.currentTarget as HTMLLinkElement).blur();
        const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, `${new Date().getTime()}`);
        this.wrapperTarget.insertAdjacentHTML('beforeend', content);
    }

    remove(e: Event) {
        e.preventDefault();
        const link = event.currentTarget as HTMLAnchorElement;
        const item = link.closest('[data-target="form--nested-attributes.item"]') as HTMLDivElement;
        const destroyField = item.querySelector('input[name*="_destroy"]') as HTMLInputElement;
        destroyField.value = "true";
        DomUtils.hideElement(item);
    }
}
