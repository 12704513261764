import { Controller } from "stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {

    static targets = [
        "translated",
        "unreviewed",
    ];

    private translatedTarget: HTMLElement;
    private unreviewedTarget: HTMLElement;
    private translatedTargetValue: string;
    private unreviewedTargetValue: string;
    private filterKey: string;

    connect() {
        this.translatedTargetValue = this.translatedTarget.querySelector("input").value;
        this.unreviewedTargetValue = this.unreviewedTarget.querySelector("input").value;
        this.filterKey = this.translatedTarget.querySelector("input").dataset.filterKey;
        this.element.addEventListener("syntax-list-multiselect:update-value", this.handleStateUpdate.bind(this));
    }

    disconnect() {
        this.element.removeEventListener("syntax-list-multiselect:update-value", this.handleStateUpdate.bind(this));
    }

    handleStateUpdate(event: CustomEvent) {
        event.detail.items.forEach((object) => {
            const input = object.element.querySelector("input") as HTMLInputElement;
            const currentItem = input.value;

            if (currentItem === this.translatedTargetValue || currentItem === this.unreviewedTargetValue) {
                const relatedItem = currentItem === this.translatedTargetValue ? this.unreviewedTarget : this.translatedTarget;
                const shouldBeChecked = object.action === "added" ? true : false;

                if (relatedItem.querySelector("input").checked === shouldBeChecked) return;

                relatedItem.click();
                const newUrl = this.getNewUrl(object.action);
                Turbo.visit(newUrl.href, { action: "replace", frame: this.data.get("frame-id") });
            } else {
                this.filter(object);
            }
        });
    }

    filter(object) {
        const location = window.location.href;
        const url = new URL(location);
        const MAX_AMOUNT_PARAMS = 150;
        const usedFilterKeys = [];
        const itemWasAdded = object.action === "added";
        const input = object.element.querySelector("input") as HTMLInputElement;
        const filterKey = input.dataset.filterKey;
        usedFilterKeys.push(filterKey);

        if (itemWasAdded) {
            url.searchParams.append(filterKey, input.value);
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            url.searchParams.delete(filterKey, input.value);
        }


        usedFilterKeys.forEach((filterKey) => {
            // guard against too many filter parameters in GET query
            if (url.searchParams.getAll(filterKey).length > MAX_AMOUNT_PARAMS) {
                const limitedParams = url.searchParams.getAll(filterKey).slice(0, MAX_AMOUNT_PARAMS);
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                url.searchParams.delete(filterKey);

                for (const value of limitedParams) {
                    url.searchParams.append(filterKey, value);
                }
            }
        });

        Turbo.visit(url.href, { action: "replace", frame: this.data.get("frame-id") });
    }

    getNewUrl(action) {
        const location = window.location.href;
        const url = new URL(location);

        if (action === "added") {
            url.searchParams.append(this.filterKey, this.translatedTargetValue);
            url.searchParams.append(this.filterKey, this.unreviewedTargetValue);
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            url.searchParams.delete(this.filterKey, this.translatedTargetValue);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            url.searchParams.delete(this.filterKey, this.unreviewedTargetValue);
        }

        return url;
    }
}
