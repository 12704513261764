import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "item",
        "searchField",
    ];

    itemTargets: HTMLElement[];
    searchFieldTarget: HTMLInputElement;

    connect() {
        this.refresh();
    }

    refresh() {
        this.update();
        this.highlight();
    }

    update() {
        this.itemTargets.forEach((item) => {
            item.dataset.highlightTermsOriginalHtml = item.textContent;
        });
    }

    highlight(): void {
        this.highlightItems();
    }

    clear(): void {
        this.searchFieldTarget.value = "";
        this.itemTargets.forEach((item) => {
            item.innerHTML = item.dataset.highlightTermsOriginalHtml;
        });
    }

    preventSubmit(event: Event) {
        event.preventDefault();
    }

    private highlightItems() {
        const searchTerm = this.searchFieldTarget.value;
        if (searchTerm === "") return this.clear();

        this.itemTargets.forEach((item) => {
            item.innerHTML = item.dataset.highlightTermsOriginalHtml.replace(new RegExp(`(${this.escapeRegExp(searchTerm)})`, "ig"), this.highlightTerm);
        });
    }

    private get highlightTerm(): string {
        return `<span class="search-highlight">$1</span>`;
    }

    private escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }
}
