import { Controller } from "stimulus";

export default class extends Controller {
    updateTimer = null;

    connect() {
        this.updateSidebarHeight();
        this.startSidebarUpdateTimer();
    }

    disconnect() {
        if (this.updateTimer != null) {
            clearTimeout(this.updateTimer);
        }
    }

    startSidebarUpdateTimer() {
        this.updateTimer = setTimeout(() => {
            this.updateSidebarHeight();
            this.startSidebarUpdateTimer();
        }, 100);
    }

    updateSidebarHeight() {
        const topY = this.element.getBoundingClientRect().top;
        (this.element as HTMLDivElement).style.height = window.innerHeight - topY + 'px';
    }
}
