import { Controller } from "stimulus";

export default class extends Controller {
    readonly fontSizeMin = 8;

    readonly colors = [
        "#1abc9c",
        "#2ecc71",
        "#16a085",
        "#27ae60",
        "#2980b9",
        "#8e44ad",
        "#2c3e50",
        "#f1c40f",
        "#e67e22",
        "#3498db",
        "#f39c12",
        "#d35400",
        "#c0392b",
        "#9b59b6",
        "#34495e",
        "#e74c3c",
        "#95a5a6",
        "#bdc3c7",
        "#7f8c8d",
    ];

    connect() {
        if (!this.element.querySelector('canvas')) {
            this.render();
        }
    }

    private render() {
        const initials = this.getInitialsFromName(this.data.get("name"));
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        this.element.appendChild(canvas);
        const context = canvas.getContext("2d");

        if (window.devicePixelRatio) {
            canvas.width = this.getSize() * window.devicePixelRatio;
            canvas.height = this.getSize() * window.devicePixelRatio;
            canvas.style.width = `${this.getSize()}px`;
            canvas.style.height = `${this.getSize()}px`;
            context.scale(window.devicePixelRatio, window.devicePixelRatio);
        }

        context.beginPath();
        context.arc(Math.floor(this.getSize() / 2), Math.floor(this.getSize() / 2), Math.floor(this.getSize() / 2) - 1, 0, 2 * Math.PI);
        context.fillStyle = this.getColorForName(initials);
        context.fill();
        context.font = `${this.calculateFontSize(this.getSize())}px Arial`;
        context.textAlign = "center";
        context.fillStyle = "#ffffff";
        context.fillText(initials, this.getSize() / 2, ((this.getSize() + this.calculateFontSize(this.getSize())) / 2) - 1);
    }

    private getSize(): number {
        return Number(this.data.get("size"));
    }

    private getInitialsFromName(name: string): string {
        const nameParts = this.words(name);

        if (nameParts && nameParts.length === 1) {
            return `${nameParts[0].charAt(0).toUpperCase()}`;
        } else if (nameParts && nameParts.length > 1) {
            return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}`;
        } else {
            return "?";
        }
    }

    private words(name: string): string[] {
        if (!name || name === "") {
            return [];
        } else {
            return name.split(" ");
        }
    }

    private getColorForName(initials: string): string {
        const charIndex = initials.charCodeAt(0) - 65;
        const colourIndex = charIndex % this.colors.length;

        if (colourIndex >= 0) {
            return this.colors[colourIndex];
        } else {
            return this.colors[Math.floor(Math.random() * this.colors.length)];
        }
    }

    private calculateFontSize(size: number): number {
        return Math.max.apply(Math, [Math.floor(size / 4), this.fontSizeMin]);
    }
}
