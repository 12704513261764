import { Controller } from "stimulus";

export default class extends Controller {

    static targets = ["eventTarget"];

    eventTargetTargets: HTMLElement[];

    triggerEvent(event: Event) {
        const eventTarget = (event.target as HTMLElement);
        const currentEventTarget = (event.currentTarget as HTMLElement);
        const eventName = eventTarget.dataset.eventName || currentEventTarget.dataset.eventName;

        this.eventTargetTargets.forEach((eventTarget) => {
            eventTarget.dispatchEvent(new Event(eventName));
        });
    }
}
