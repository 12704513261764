import { Controller } from "stimulus";
import { debounce } from "@utils/debounce";

export default class extends Controller {
    static targets = [
        "searchForm",
        "item",
        "emptyMessage",
        "presentMessage",
    ];

    searchFormTarget: HTMLFormElement;
    itemTargets: HTMLElement[];
    emptyMessageTargets: HTMLElement[];
    presentMessageTargets: HTMLElement[];

    // TODO: Replace MutationObserver with callbacks (https://stimulus.hotwired.dev/reference/targets#connected-and-disconnected-callbacks)
    itemObserver: MutationObserver;

    connect() {
        this.itemObserver = new MutationObserver(this.updateListVisibility.bind(this));
        this.itemObserver.observe(this.element, {
            childList: true,
            attributes: false,
            subtree: true
        });
        this.updateListVisibility();
    }

    disconnect() {
        this.itemObserver.disconnect();
    }

    search() {
        debounce(this.submitSearch.bind(this));
    }

    private submitSearch() {
        this.searchFormTarget.requestSubmit();
    }

    private updateListVisibility() {
        this.emptyMessageTargets.forEach((emptyMessageTarget) => {
            emptyMessageTarget.classList.toggle("hidden", this.itemTargets.length !== 0);
        });
        this.presentMessageTargets.forEach((presentMessageTarget) => {
            presentMessageTarget.classList.toggle("hidden", this.itemTargets.length == 0);
        });
    }
}
