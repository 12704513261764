import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "formElement"
    ];

    private formElementTargets: [];

    connect() {
        this.enableElements();
    }

    enableElements() {
        this.formElementTargets.forEach((element: HTMLFormElement) => {
            element.disabled = false;
        });
    }

    disableElements() {
        this.formElementTargets.forEach((element: HTMLFormElement) => {
            element.disabled = true;
        });
    }
}
