import { Controller } from "stimulus";



const inputSelector = 'input[type="radio"]';


export default class extends Controller {
    static targets = [
        "card",
    ];

    element: HTMLFormElement;
    cardTargets: HTMLElement[];


    connect() {
        this.adjustCardClasses();
    }

    checkInput(event: Event) {
        const card = event.currentTarget as HTMLElement;
        const input = card.querySelector(inputSelector) as HTMLInputElement;

        const previousValue = this.radioNodeList.value;
        const newValue = input.value;

        this.radioNodeList.value = newValue;

        if (event.target !== input && previousValue !== newValue) {
            this.inputs
                .find((input) => input.value === previousValue)
                ?.dispatchEvent(new Event('change'));

            input.dispatchEvent(new Event('change'));
        }
    }

    adjustCardClasses() {
        this.cardTargets.forEach((card) => this.adjustCardClass(card.querySelector(inputSelector), card));
    }


    private adjustCardClass(input: HTMLInputElement, card: HTMLElement) {
        if (input.checked) {
            card.classList.add(this.data.get("checkedClass"));
        } else {
            card.classList.remove(this.data.get("checkedClass"));
        }
    }

    private get inputs(): HTMLInputElement[] {
        return this.cardTargets.map((card) => card.querySelector(inputSelector) as HTMLInputElement);
    }

    private get radioNodeList(): RadioNodeList {
        return this.element.elements[this.data.get("name")];
    }
}
