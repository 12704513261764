import { Controller } from "stimulus";
import { i18n } from "../i18n/config";

export default class extends Controller {
    static targets = [
        "submit"
    ];

    private submitTarget: HTMLInputElement;

    hasSubmitTarget: boolean;
    modelName: string;
    element: HTMLFormElement;

    connect() {
        this.modelName = this.data.get("modelName");
        this.preventDefaultOnInputs();
    }

    onSuccess(event: CustomEvent) {
        if (event.target == this.element) {
            this.clearErrors();
            this.clearFields();
            if(event.detail.xhr.responseJSON?.flash_message) {
                this.renderFlashMessage(event.detail.xhr.responseJSON.flash_message);
            }
            this.element.dispatchEvent(new CustomEvent('remote-form:success'));
        }
    }

    clearFields() {
        if (this.data.get("clearFields") !== "false") {
            this.element.reset();
        }
    }

    onError(event: CustomEvent) {
        if (event.detail.xhr.status >= 500) {
            const errorContainer = document.getElementById("flash-messages");
            if (errorContainer) {
                this.renderGeneralErrorMessage(errorContainer);
            }

            return;
        }

        this.renderErrors(event.detail.xhr.responseJSON.errors);
        this.displayTabErrors();
        this.selectFirstTabWithErrors();

        const firstFormError = document.querySelector('form span.error');
        if (firstFormError) {
            const element = firstFormError.parentElement.querySelector("input") ||
                firstFormError.parentElement.querySelector("textarea") ||
                firstFormError.parentElement.querySelector("select");

            if (element) {
                element.focus();
            }
        }
    }

    submitOnEnter(event: KeyboardEvent) {
        if (this.hasSubmitTarget && event.keyCode === 13) {
            this.submitTarget.click();
        }
    }

    private preventDefaultOnInputs() {
        this.element.querySelectorAll('input').forEach((input) => {
            input.addEventListener("keydown", event => {
                if (event.keyCode === 13) { // Enter
                    event.preventDefault();
                }
            });
        });
    }

    private renderErrors(errors: object) {
        this.clearErrors();
        Object.keys(errors).forEach((key) => {
            const messages = errors[key];
            if (Array.isArray(errors[key])) {
                this.renderError(this.modelName, key, messages);
            } else {
                // support for nested model forms
                const nestedModelName = `${this.modelName}[${key}]`;
                Object.keys(messages).forEach((key) => {
                    this.renderError(nestedModelName, key, messages);
                });
            }
        });
    }

    private clearErrors() {
        this.element.querySelectorAll(".form-group").forEach((elem) => {
            elem.classList.remove("has-error");
        });
        this.element.querySelectorAll("span.help-block.error").forEach((elem) => {
            elem.remove();
        });
    }

    private renderError(modelName: string, field: string, messages: string[]) {
        const nestedFields = field.split(".");
        // make it work with accept_nested_attributes_fields, e.g. {"glossary_term_translations.locale_code":["muss ausgefüllt werden"]}
        let elem: HTMLElement;
        if (nestedFields.length > 1) {
            const elems = this.element.querySelectorAll(`.form-group.${modelName}_${nestedFields[0]}_${nestedFields[1]}`);
            elem = <HTMLElement>elems[elems.length - 1];
        } else {
            const normalizedModelName = modelName.replace(/\[/, "_").replace(/\]/, ""); // make it work with nested fields, too
            elem = this.element.querySelector(`.form-group.${normalizedModelName}_${field}`);
        }

        if (elem) this.appendErrorMessages(elem, messages);
    }

    private appendErrorMessages(elem: HTMLElement, messages: string[]) {
        elem.classList.add('has-error');
        if (elem.querySelectorAll('.input-group').length > 0) {
            elem.insertAdjacentHTML('beforeend', `<span class="help-block error">${messages.join('<br />')}</span>`);
        } else {
            const elems = elem.querySelectorAll(".form-control");
            elem = <HTMLElement>elems[elems.length - 1];
            elem.insertAdjacentHTML('afterend', `<span class="help-block error">${messages.join('<br />')}</span>`);
        }
    }

    private displayTabErrors() {
        this.clearTabErrors();
        const tabs = this.element.querySelectorAll(".tab-pane");
        tabs.forEach((tab) => {
            if (tab.querySelectorAll(".has-error").length > 0) {
                const id = tab.getAttribute("id");
                const link = this.element.querySelector(`.nav-tabs a[data-toggle=tab][href="#${id}"]`);
                link.insertAdjacentHTML('beforeend', ' <i class="material-icons tab-list__validation-error-hint" aria-hidden="true">error_outline</i>');
                link.parentElement.classList.add("has-errors");
            }
        });
    }

    private selectFirstTabWithErrors() {
        const tabsWithErrors = this.element.querySelectorAll(".nav-tabs li.has-errors a");
        if (tabsWithErrors.length > 0) {
            (<any>window).$(tabsWithErrors[0]).tab('show');
        }
    }

    private clearTabErrors() {
        this.element.querySelectorAll('.nav-tabs a[data-toggle=tab] .tab-list__validation-error-hint').forEach((elem) => {
            elem.remove();
        });
    }

    private renderFlashMessage(message: string) {
        document.getElementById("flash-messages").innerHTML = message;
    }

    private renderGeneralErrorMessage(errorContainer: HTMLElement) {
        const container = document.createElement("div");
        container.className = "container text-center";
        container.id = "flash-messages";
        container.dataset.role = "interaction-messages";

        const alert = document.createElement("div");
        alert.className = "alert alert-error alert--inline alert--banner";
        container.dataset.controller = "alert";

        const message = document.createElement("span");
        message.className = "alert__content";
        message.innerHTML = i18n.t('general.error_message_support_generic');

        const alertIconContainer = document.createElement("span");
        alertIconContainer.className = "alert__status-icon";

        const alertIcon = document.createElement("i");
        alertIcon.className = "material-icons";
        alertIcon.innerHTML = "error";

        const exitIconContainer = document.createElement("span");
        exitIconContainer.className = "alert__close-btn alert__close-btn--light";
        exitIconContainer.dataset.action ="click->alert#close";

        const exitIcon = document.createElement("i");
        exitIcon.className = "material-icons";
        exitIcon.innerHTML = "close";

        alertIconContainer.appendChild(alertIcon);
        exitIconContainer.appendChild(exitIcon);

        alert.appendChild(alertIconContainer);
        alert.appendChild(message);
        alert.appendChild(exitIconContainer);

        errorContainer.appendChild(container).appendChild(alert);
    }
}
