import { selectizeOf } from "@utils/selectize";
import Sentry from "@utils/sentry";
import { i18n } from "app/javascript/i18n/config";
import { Controller } from "stimulus";


export default class extends Controller {
    static targets = [
        'sourceLocaleSelect',
        'targetLocaleSelect',
        'submitButton',
    ];

    private sourceLocaleSelectTarget: HTMLSelectElement;
    private hasSourceLocaleSelectTarget: boolean;
    private targetLocaleSelectTarget: HTMLSelectElement;
    private hasTargetLocaleSelectTarget: boolean;
    private submitButtonTarget: HTMLButtonElement;

    connect() {
        if (this.hasTargetLocaleSelectTarget) {
            this.updateTargetLocales();
        }
        if (this.hasSourceLocaleSelectTarget) {
            this.updateSourceLocales();
        }
    }


    updateTargetLocales() {
        selectizeOf(this.targetLocaleSelectTarget)
            .then((selectize) => {
                const selectedSourceLocale = this.sourceLocaleSelectTarget.value;
                const selectedTargetLocale = this.targetLocaleSelectTarget.value;
                selectize.clear(true);
                selectize.clearOptions();

                const locales = this.targetLocales;
                locales.forEach(locale => {
                    const localeCode = locale[0];
                    selectize.addOption(
                        {
                            value: localeCode,
                            text: locale[1],
                        }
                    );
                });

                if (locales.length > 0) {
                    selectize.enable();
                    if (selectize.items.length === 0) {
                        let newLocaleCode = "";
                        const possibleMappings = locales.filter(locale => this.isMappingPossible(selectedSourceLocale, locale[0])).map(locale => locale[0]);

                        if (possibleMappings.includes(selectedTargetLocale)) {
                            newLocaleCode = selectedTargetLocale;
                        } else if (possibleMappings.length > 0) {
                            newLocaleCode = possibleMappings[0];
                        }

                        if (newLocaleCode !== "") {
                            selectize.setValue(newLocaleCode, true);
                        }
                    }
                }

                if (selectize.items.length == 0) {
                    const emptyValue = i18n.t('machine_translations.locale_provider_mappings.target');
                    selectize.addOption(
                        {
                            value: emptyValue,
                            text: emptyValue,
                        }
                    );
                    selectize.setValue(emptyValue, true);
                    selectize.disable();
                }

                selectize.refreshOptions(false);
                const disabled = this.sourceLocaleSelectTarget.disabled || this.targetLocaleSelectTarget.disabled || this.targetLocaleSelectTarget.value === "" || this.sourceLocaleSelectTarget.value === "";
                this.submitButtonTarget.disabled = disabled;
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    updateSourceLocales() {
        selectizeOf(this.sourceLocaleSelectTarget)
            .then((selectize) => {
                const selectedSourceLocale = this.sourceLocaleSelectTarget.value;
                const selectedTargetLocale = this.targetLocaleSelectTarget.value;
                selectize.clear(true);
                selectize.clearOptions();

                const locales = this.sourceLocales;
                locales.forEach(locale => {
                    const localeCode = locale[0];
                    selectize.addOption(
                        {
                            value: localeCode,
                            text: locale[1],
                        }
                    );
                });

                if (locales.length > 0) {
                    selectize.enable();
                    if (selectize.items.length === 0) {
                        let newLocaleCode = "";
                        const possibleMappings = locales.filter(locale => this.isMappingPossible(locale[0], selectedTargetLocale)).map(locale => locale[0]);
                        if (possibleMappings.includes(selectedSourceLocale)) {
                            newLocaleCode = selectedSourceLocale;
                        } else if (possibleMappings.length > 0) {
                            newLocaleCode = possibleMappings[0];
                        }

                        if (newLocaleCode !== "") {
                            selectize.setValue(newLocaleCode, true);
                        }
                    }
                }

                if (selectize.items.length == 0) {
                    const emptyValue = i18n.t('machine_translations.locale_provider_mappings.source');
                    selectize.addOption(
                        {
                            value: emptyValue,
                            text: emptyValue,
                        }
                    );
                    selectize.setValue(emptyValue, true);
                    selectize.disable();
                }

                selectize.refreshOptions(false);
                const disabled = this.sourceLocaleSelectTarget.disabled || this.targetLocaleSelectTarget.disabled || this.targetLocaleSelectTarget.value === "" || this.sourceLocaleSelectTarget.value === "";
                this.submitButtonTarget.disabled = disabled;
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    private isMappingPossible(source: string, target: string) {
        if (source === target) {
            return false;
        }

        return !this.existingMappings.some(mapping => mapping['source_locale_code'] === source && mapping['target_locale_code'] === target);
    }

    private get targetLocales(): string[][] {
        const availableLocales = this.availableLocales;
        const selectedSourceLocale = this.sourceLocaleSelectTarget.value;

        const targetLocales = [];
        availableLocales.forEach(locale => {
            const localeCode = locale[0];
            if (localeCode == selectedSourceLocale) {
                return;
            }

            if (!this.isMappingPossible(selectedSourceLocale, localeCode)) {
                return;
            }

            targetLocales.push(locale);
        });

        return targetLocales;
    }

    private get sourceLocales(): string[][] {
        const availableLocales = this.availableLocales;
        const selectedTargetLocale = this.targetLocaleSelectTarget.value;

        const sourceLocales = [];
        availableLocales.forEach(locale => {
            const localeCode = locale[0];
            if (localeCode === selectedTargetLocale) {
                return;
            }

            if (!this.isMappingPossible(localeCode, selectedTargetLocale)) {
                return;
            }

            sourceLocales.push(locale);
        });

        return sourceLocales;
    }


    private get availableLocales(): string[][] {
        return JSON.parse(this.data.get("locales"));
    }

    private get existingMappings(): string[][] {
        return JSON.parse(this.data.get("all-mappings"));
    }
}
