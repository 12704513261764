import { Controller } from "stimulus";
import createElement from "@utils/createElement";
import DomUtils from "@utils/dom_utils";
import OnboardingService, { OnboardingStep } from "../classes/onboarding_service";


export default class extends Controller {
    private tourId: string;
    private stepId: string;
    private tooltipPosition: string;
    private tooltipElement: HTMLSpanElement;
    private tooltipText: string;
    private tooltipBtnText: string;
    private tooltipBtnHref: string;

    connect() {
        this.tourId = this.data.get('id');
        this.stepId = this.data.get('stepId');
        this.tooltipPosition = this.data.get('position') ?? 'top';
        this.tooltipText = this.data.get('text');
        this.tooltipBtnText = this.data.get('btn-text');
        this.tooltipBtnHref = this.data.get('btn-href');
        OnboardingService.registerStepComponent(
            this.tourId,
            this.stepId,
            this.handleVisibilityChange.bind(this)
        );
    }

    disconnect() {
        OnboardingService.unregisterStepComponent(
            this.tourId,
            this.stepId
        );
    }

    private createOnboardingTooltip(tourStep: OnboardingStep) {
        const textElement = createElement.createElement('div', {
            className: 'onboarding-tooltip__text'
        });

        textElement.innerHTML = this.tooltipText;

        this.tooltipElement = createElement.createElement('div', {
            className: `onboarding-tooltip--${this.tooltipPosition} onboarding-tooltip`
        });

        tourStep.maxWidth && (this.tooltipElement.style.maxWidth = `${tourStep.maxWidth}px`);
        this.tooltipElement.appendChild(
            this.createCloseButtonElement(this.tooltipElement)
        );
        this.tooltipElement.appendChild(textElement);

        const { nextStepId } = OnboardingService.getStep(this.tourId, this.stepId);
        if (nextStepId) {
            this.tooltipElement.appendChild(
                this.createNextStepFooterElement(this.tooltipElement)
            );
        }

        this.element.appendChild(this.tooltipElement);
    }

    private createNextStepFooterElement(element: HTMLElement) {
        const footer = createElement.createElement('div', {
            className: 'onboarding-tooltip-footer'
        });

        if (this.tooltipPosition !== 'relative') {
            footer.textContent = `${OnboardingService.getStepIndex(this.tourId, this.stepId) + 1} of ${Object.keys(OnboardingService.getTour(this.tourId).steps).length}`;
        }

        const buttonType = this.tooltipBtnHref ? 'a' : 'div';
        const button = createElement.createElement(buttonType, {
            className: 'btn btn-default ml-15px',
            textContent: this.tooltipBtnText,
            href: this.tooltipBtnHref
        });

        button.addEventListener('click', this.onNextButtonClick.bind(this, element));
        footer.appendChild(button);

        return footer;
    }

    private createCloseButtonElement(element: HTMLElement) {
        const icon = createElement.createElement('i', {
            textContent: 'close',
            className: 'material-icons'
        });

        const button = createElement.createElement('div', {
            className: 'onboarding-tooltip__close'
        });

        button.appendChild(icon);
        button.addEventListener('click', this.onCloseButtonClick.bind(this, element));
        return button;
    }

    private onCloseButtonClick(element: HTMLElement) {
        this.destroyTooltip(element);

        OnboardingService.changeTour(this.tourId, {
            active: false
        });
    }

    private onNextButtonClick(element: HTMLElement) {
        this.destroyTooltip(element);

        const { nextStepId } = OnboardingService.getStep(this.tourId, this.stepId);
        if (nextStepId) {
            OnboardingService.changeTour(this.tourId, {
                activeStep: nextStepId
            });
        } else {
            OnboardingService.changeTour(this.tourId, {
                active: false
            });
        }
    }

    private destroyTooltip(element: HTMLElement) {
        this.tooltipElement = undefined;
        DomUtils.remove(element);
    }

    handleVisibilityChange(visibility: boolean) {
        if (visibility) {
            if (!this.tooltipElement)
                this.createOnboardingTooltip(OnboardingService.getStep(this.tourId, this.stepId));
        } else {
            if (this.tooltipElement)
                this.onCloseButtonClick(this.tooltipElement);
        }
    }
}
