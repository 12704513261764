import Sentry from "@utils/sentry";
import { Application } from "stimulus";

const application = Application.start();

import { BillingPortalController, CheckoutController } from "phrase-payments";
import {
    SyntaxAlertController,
    SyntaxDropdownController,
    SyntaxTooltipController,
    SyntaxSidebarCollapsibleNavigationController,
    SyntaxListFilterController,
    SyntaxCheckboxController,
    SyntaxListMultiselectController,
    SyntaxSelectToggleTabbedListController,
    SyntaxSelectTagController,
    SyntaxSelectCountCheckedItemsController,
    SyntaxSelectAnimateIconController,
    SyntaxInputController,
    SyntaxTabsController,
    SyntaxListGroupSelectedController,
    SyntaxModalController,
    SyntaxTransitionController,
    SyntaxListSelectController,
    SyntaxSelectAllController,
    SyntaxSelectController,
    SyntaxListKeyboardController,
    SyntaxTurboFrameSearchController,
    SyntaxUrlController,
    SyntaxCheckboxToggleVisibilityController,
    SyntaxChipController,
    SyntaxChipInputController,
    SyntaxLoadingStateController,
} from "syntax-view-components";

application.register("payments--checkout", CheckoutController);
application.register("payments--billing-portal", BillingPortalController);
application.register("syntax-alert", SyntaxAlertController);
application.register("syntax-dropdown", SyntaxDropdownController);
application.register("syntax-tooltip", SyntaxTooltipController);
application.register("syntax-sidebar-collapsible-navigation", SyntaxSidebarCollapsibleNavigationController);
application.register("syntax-list-filter", SyntaxListFilterController);
application.register("syntax-checkbox", SyntaxCheckboxController);
application.register("syntax-list-multiselect", SyntaxListMultiselectController);
application.register("syntax-select-toggle-tabbed-list", SyntaxSelectToggleTabbedListController);
application.register("syntax-select-tag", SyntaxSelectTagController);
application.register("syntax-select-count-checked-items", SyntaxSelectCountCheckedItemsController);
application.register("syntax-select-animate-icon", SyntaxSelectAnimateIconController);
application.register("syntax-input", SyntaxInputController);
application.register("syntax-tabs", SyntaxTabsController);
application.register("syntax-list-group-selected", SyntaxListGroupSelectedController);
application.register("syntax-modal", SyntaxModalController);
application.register("syntax-transition", SyntaxTransitionController);
application.register("syntax-list-select", SyntaxListSelectController);
application.register("syntax-select-all", SyntaxSelectAllController);
application.register("syntax-select", SyntaxSelectController);
application.register("syntax-list-keyboard", SyntaxListKeyboardController);
application.register("syntax-turbo-frame-search", SyntaxTurboFrameSearchController);
application.register("syntax-url", SyntaxUrlController);
application.register("syntax-checkbox-toggle-visibility", SyntaxCheckboxToggleVisibilityController);
application.register("syntax-chip", SyntaxChipController);
application.register("syntax-chip-input", SyntaxChipInputController);
application.register("syntax-loading-state", SyntaxLoadingStateController);

(window as any).Stimulus = application;

application.handleError = (error, message, detail) => {
    console.error(`%s\n\n%o\n\n%o`, message, error, detail);
    Sentry.notify(error);
};

export { application };

