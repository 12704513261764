import Dropzone from "dropzone";
import { Controller } from "stimulus";
import Sentry from "@utils/sentry";
import Spinner from "@utils/spinner";
import DomUtils from "@utils/dom_utils";
import { i18n } from "../../i18n/config";

export default class extends Controller {
    static targets = [
        "batchAction",
        "batchForm",
        "errorDisplay",
        "modal",
        "unassignedCounter",
        "unassignedList",
        "upload",
        "uploadText",
        "uploadWrapper",
        "dropzoneElement",
        "dropzonePlaceholder",
        "dropzoneThumbnail",
    ];

    batchActionTarget: HTMLInputElement;
    batchFormTarget: HTMLFormElement;
    batchDownloadButtonTarget: HTMLButtonElement;
    errorDisplayTarget: HTMLElement;
    modalTarget: HTMLElement;
    unassignedCounterTarget: HTMLElement;
    unassignedListTarget: HTMLElement;
    uploadTarget: HTMLInputElement;
    uploadTextTarget: HTMLElement;
    uploadWrapperTarget: HTMLElement;
    dropzoneElementTarget: HTMLElement;
    dropzonePlaceholderTarget: HTMLDivElement;
    dropzoneThumbnailTargets: HTMLElement[];
    dropzone: Dropzone;

    hasBatchFormTarget: boolean;
    hasUploadTarget: boolean;
    hasDropzoneElementTarget: boolean;

    batchConfirmed: boolean;
    preventRebubbling: boolean;

    spinner: Spinner;

    connect() {
        this.batchConfirmed = false;
        this.preventRebubbling = false;
        this.bindEvents();

        if (this.hasDropzoneElementTarget) {
            this.spinner = new Spinner(this.dropzoneElementTarget);
        }
    }

    confirmDeletion() {
        (<any>window).$(this.modalTarget).modal("hide");
        this.batchActionTarget.value = "delete";
        this.batchConfirmed = true;
        this.batchFormTarget.submit();
    }

    cancelDeletion() {
        (<any>window).$(this.modalTarget).modal("hide");
        this.preventRebubbling = true;
    }

    downloadScreenshots() {
        this.batchActionTarget.value = "download";
        this.batchConfirmed = true;
        this.batchFormTarget.submit();
    }

    private bindEvents() {
        if (this.hasUploadTarget) {
            this.uploadTarget.addEventListener("change", (e) => {
                this.initializeUpload();
            });
        }

        if (this.hasBatchFormTarget) {
            this.batchFormTarget.addEventListener("submit", (e) => {
                if (!this.batchConfirmed || this.preventRebubbling) {
                    e.preventDefault();
                    e.stopPropagation();

                    /* Whoever questions that: Remove and see what happens. */
                    if (this.preventRebubbling) {
                        this.preventRebubbling = false;
                        return;
                    }

                    (<any>window).$(this.modalTarget).modal("show");
                }
            });
        }

        if (this.hasDropzoneElementTarget) {
            this.setupDropzone();
        }
    }

    private setupDropzone() {
        this.dropzone = new Dropzone(this.dropzoneElementTarget, {
            url: this.data.get("creationInlinePath"),
            uploadMultiple: true,
            withCredentials: true,
            maxFiles: 10,
            parallelUploads: 10,
            paramName: "screenshots[filename]",
            previewTemplate: "<div class=\"dropzone__preview-thumbnail\" data-role=\"thumbnail\" data-target=\"screenshots--upload.dropzoneThumbnail\"><img data-dz-thumbnail /></div>",
            headers: this.requestHeaders(),
        });

        this.dropzone.on("addedfile", () => {
            this.dropzonePlaceholderTarget.classList.add("hidden");
            this.spinner.start();
        });

        this.dropzone.on("error", () => {
            this.dropzonePlaceholderTarget.classList.remove("hidden");
            this.dropzoneThumbnailTargets.forEach((thumbnail) => DomUtils.remove(thumbnail));
            this.spinner.stop();
        });

        this.dropzone.on("success", (_, response) => {
            this.spinner.stop();
            window.location.reload();
        });
    }

    private initializeUpload() {
        this.lockUpload();
        this.uploadFile();
    }

    private lockUpload() {
        this.errorDisplayTarget.classList.add("hidden");
        this.uploadWrapperTarget.classList.add("disabled");
        this.uploadTextTarget.innerHTML = this.data.get("loadingHtml");
    }

    private unlockUpload() {
        this.uploadWrapperTarget.classList.remove("disabled");
        this.uploadTextTarget.innerHTML = this.data.get("readyHtml");
    }

    private uploadFile() {
        const formData = new FormData();
        let i = 0;

        Array.from(this.uploadTarget.files).forEach((file) => {
            formData.append(`screenshots[filename][${i++}]`, file);
        });

        // We need to include the window.location since the search doesn't
        // necessarily reload the editor sidebar. Otherwise the screenshot
        // editor would redirect back to a old location (with old search param)
        formData.append("screenshots[editor_path]", this.currentEditorPath());

        fetch(
            this.data.get("creationPath"),
            {
                method: "POST",
                headers: this.requestHeaders(),
                body: formData,
                credentials: "same-origin",
            }
        ).then((response) => {
            if (response.ok) {
                return response.text().then((html) => {
                    return Promise.resolve({ success: true, body: html });
                });
            } else {
                return response.text().then((html) => {
                    return Promise.resolve({ success: false, body: html });
                });
            }
        }).then((data) => {
            this.handleResponse(data, i);
        }).catch(err => {
            Sentry.notify(err);
        });
    }

    private currentEditorPath() {
        return `${(<any>window).location.pathname}${(<any>window).location.search}`;
    }

    private requestHeaders() {
        const headers = {};
        headers["X-CSRF-Token"] = this.csrfToken();
        headers["Accept"] = "application/json";
        return headers;
    }

    private handleResponse(data, uploadedItems) {
        this.unlockUpload();

        if (data.success) {
            if (uploadedItems > 1) {
                window.location.reload();
            } else {
                window.location = JSON.parse(data.body).redirect_path;
            }
        } else {
            this.errorDisplayTarget.classList.remove("hidden");

            if (this.data.get("editorUpload") === "true") {
                this.errorDisplayTarget.innerHTML = i18n.t("screenshots.update.error");
            } else {
                this.errorDisplayTarget.innerHTML = JSON.parse(data.body).flash_message;
            }
        }
    }

    private csrfToken() {
        const csrfTag = (<HTMLMetaElement>document.querySelector('meta[name="csrf-token"]'));
        return (csrfTag ? csrfTag.content : "");
    }
}
