import {Controller} from "stimulus";
import DomUtils from "@utils/dom_utils";

export default class extends Controller{
    static targets = [
        "currentElementIndex",
        "filterContainer",
        "filterValueField",
        "filterValueFrame",
        "newFilterUrl",
    ];

    private currentElementIndexTarget: HTMLInputElement;
    private filterContainerTarget: HTMLElement;
    private filterValueFieldTarget: HTMLElement;
    private filterValueFrameTarget: any;
    private newFilterUrlTarget: HTMLInputElement;

    renderFilterValueField(event: Event) {
        const metaDataLabelSelect = event.currentTarget as HTMLInputElement;
        const selectedLabel = metaDataLabelSelect.value;
        const index = this.currentElementIndexTarget.value;

        if(selectedLabel){
            const customMetadataFieldPath = this.newFilterUrlTarget.value;
            const customMetadataFieldFrame = this.filterValueFrameTarget;
            customMetadataFieldFrame.src = `${customMetadataFieldPath}?index=${index}&label_code=${selectedLabel}`;
        }
        else{
            DomUtils.remove(this.filterValueFieldTarget);
        }
    }

    removeCustomMetadataFilter(event: Event){
        event.preventDefault();

        DomUtils.remove(this.filterContainerTarget);
    }
}
