import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        'submitButton'
    ];

    submitButtonTarget: HTMLInputElement;

    submit(event: Event): void {
        event.preventDefault();
        const el = <HTMLElement>event.target;

        if (el.dataset.disableWith) {
            el.dataset.text = el.innerHTML;
            el.innerHTML = el.dataset.disableWith;
            el.setAttribute("disabled", "disabled");
            this.submitButtonTarget.closest("form").addEventListener(
                "jquery:ajax:complete",
                () => {
                    el.innerHTML = el.dataset.text;
                    el.removeAttribute("disabled");
                }
            );
        }

        this.submitButtonTarget.click();
    }
}
