import { Controller } from "stimulus";
import Tooltip from "../util/tooltip";
import CopyToClipboard from "copy-to-clipboard";

export default class extends Controller {
    static targets = [
        "button",
    ];

    private buttonTarget: HTMLElement;
    private hasButtonTarget: boolean;

    connect() {
        if (document.queryCommandSupported == undefined || !document.queryCommandSupported("copy")) {
            if (this.hasButtonTarget) {
                this.buttonTarget.classList.add("hidden");
            }
        }
    }

    copy(_e: Event) {
        CopyToClipboard(this.data.get("value"));

        if (this.hasButtonTarget) {
            const tooltip = new Tooltip(this.buttonTarget, this.data.get("success-message"));
            tooltip.show();
            setTimeout(() => {
                tooltip.destroy();
            }, 2000);
        }
    }
}
