import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "form",
        "disableButton",
        "disabledMessage",
        "settings",
        "modal",
        "enabled",
        "certificateField"
    ];

    formTarget: HTMLFormElement;
    settingsTarget: HTMLDivElement;
    disableButtonTarget: HTMLButtonElement;
    disabledMessageTarget: HTMLDivElement;
    modalTarget: HTMLDivElement;
    enabledTarget: HTMLInputElement;
    certificateFieldTarget: HTMLTextAreaElement;

    private enabled: boolean;

    connect() {
        this.enabled = this.enabledTarget.value === "true";
        this.toggleSettings();
        this.toggleButton();
        this.toggleDisabledMessage();

        this.handleCertificateUpload();
    }

    undo(e: Event) {
        this.enabled = !this.enabled;
        this.enabledTarget.value = String(this.enabled);
    }

    toggle(e: Event) {
        this.enabled = !this.enabled;
        this.enabledTarget.value = String(this.enabled);
        if (!this.enabled) {
            this.showWarning();
        } else {
            this.toggleSettings();
            this.toggleButton();
            this.toggleDisabledMessage();
        }
    }

    toggleButton() {
        this.disableButtonTarget.classList.toggle("hidden", !this.enabled);
    }

    toggleDisabledMessage() {
        this.disabledMessageTarget.classList.toggle("hidden", this.enabled);
    }

    toggleSettings() {
        if (!this.enabled) {
            this.settingsTarget.classList.add("hidden");
        } else {
            this.settingsTarget.classList.remove("hidden");
        }
    }

    showWarning() {
        (<any>window).$(this.modalTarget).modal("show");
    }

    private handleCertificateUpload() {
        this.element.addEventListener("certificate-upload", (event: CustomEvent) => {
            const fileList = event.detail.fileList;

            if(fileList) {
                const certificateFile = fileList[0];
                const fileReader = new FileReader();

                fileReader.readAsText(certificateFile);
                fileReader.addEventListener("loadend", (event) => {
                    this.certificateFieldTarget.value = String(fileReader.result);
                });
            }
        });
    }
}
