import { Controller } from "stimulus";

/*
  Reusable file-select controller

  Use it like this:

  <div class="file-select"
    data-controller="file-select"
    data-file-select-label="Some Label Name"
    data-file-select-event="some-event-name"
    data-file-select-multiple="false"
    data-file-select-accept="image/*"
  ></div>

  Create an event listener in your controller to work with the selected files:

  this.element.addEventListener("some-event-name", (event: CustomEvent) => {
    const fileList = event.detail.fileList;

    if(fileList) {
      const firstFile = fileList[0];
      const fileReader = new FileReader();

      fileReader.readAsText(firstFile);
      fileReader.addEventListener("loadend", (event) => {
        const fileContents = fileReader.result;
        doSomethingCool(fileContent);
      });
    }
  });
*/

export default class FileSelectController extends Controller {

    static targets = [
        "fileField"
    ];

    fileFieldTarget: HTMLFormElement;

    connect() {
        this.insertFileSelectHTML();
        this.handleFileSelectEvent();
    }

    buttonClicked(event) {
        event.stopPropagation();
        event.preventDefault();

        this.fileFieldTarget.click();
    }


    private insertFileSelectHTML() {
        const controller = this.context.element;

        controller.appendChild(this.fileFieldElement());
        controller.appendChild(this.buttonElement());
    }

    private buttonElement(): HTMLButtonElement {
        const buttonLabel = this.data.get("label");
        const button = <HTMLButtonElement>document.createElement("button");

        button.classList.add("file-select__upload-button");
        button.classList.add("btn");
        button.classList.add("btn-primary");
        button.setAttribute("data-action", "file-select#buttonClicked");
        button.innerHTML = buttonLabel;

        return button;
    }

    private fileFieldElement(): HTMLInputElement {
        const fileField = <HTMLInputElement>document.createElement("input");

        fileField.classList.add("file-select__file-field");
        fileField.setAttribute("style", "display: none;");
        fileField.setAttribute("id", "file-select");
        fileField.setAttribute("name", "file-select");
        fileField.setAttribute("type", "file");
        fileField.setAttribute("data-target", "file-select.fileField");

        if(this.data.get("multiple") === "true") {
            fileField.setAttribute("multiple", "true");
        }

        if(this.data.get("accept") !== "") {
            fileField.setAttribute("accept", this.data.get("accept"));
        }

        return fileField;
    }

    private handleFileSelectEvent() {
        this.fileFieldTarget.addEventListener("change", (event) => {
            this.emitFileSelectEvent(event);
        });
    }

    private emitFileSelectEvent(event) {
        const fileList = event.target.files;

        const eventName = this.data.get("event");
        const fileSelectEvent = new CustomEvent(eventName, {
            bubbles: true,
            detail: {
                fileList: fileList
            },
        });

        this.element.dispatchEvent(fileSelectEvent);
    }
}
