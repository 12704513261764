import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        'targetElement',
    ];

    private targetElementTarget: HTMLElement;

    private initialContent: string;

    toggle(event: MouseEvent): void {
        event.preventDefault();
        const currentTarget = event.currentTarget as HTMLElement;

        if (!this.initialContent) this.initialContent = currentTarget.textContent;

        const collapsed = this.targetElementTarget.classList.toggle('documents-preview--collapsed');
        currentTarget.textContent = collapsed
            ? this.initialContent
            : currentTarget.dataset.toggleContent;
    }
}
