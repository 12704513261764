import { Controller } from "stimulus";

export default class extends Controller {

    connect() {
        this.markItemAsCurrentIfNecessary();
    }

    switchToBranch(event: Event): void {
        event.preventDefault();

        if (this.data.get("branchName") !== this.data.get("currentBranch")) {
            this.updateUrlParamsAndChangeWindowLocation();
        }
    }

    private markItemAsCurrentIfNecessary(): void {
        if (this.data.get("branchName") === this.data.get("currentBranch")) {
            const checkMark = document.createElement("i");
            checkMark.classList.add("material-icons");
            checkMark.classList.add("mr-8px");
            checkMark.innerText = "check";

            this.element.classList.add("branch-switch__branches-list-item--current");
            this.element.prepend(checkMark);
        } else {
            const offset = document.createElement("span");
            offset.classList.add("branch-switch__branches-list-item__offset");
            offset.classList.add("mr-8px");

            this.element.prepend(offset);
        }
    }

    private updateUrlParamsAndChangeWindowLocation(): void {
        const currentUrl = new URL((<any>window).location);

        if (this.data.get("branchName") === this.data.get("mainBranchName")) {
            currentUrl.searchParams.delete("branch");
        } else {
            currentUrl.searchParams.set("branch", this.data.get("branchName"));
        }

        window.location.assign(currentUrl.toString());
    }
}
