import { Controller } from "stimulus";
import { i18n } from "../../i18n/config";
import Sentry from "@utils/sentry";

export default class extends Controller {

    static targets = [
        "commentsContainer",
        "error",
        "textArea",
        "submitButton"
    ];

    private commentsContainerTarget: HTMLDivElement;
    private errorTarget: HTMLDivElement;
    private textAreaTarget: HTMLTextAreaElement;
    private submitButtonTarget: HTMLButtonElement;

    createComment(event: Event) {
        event.preventDefault();

        if (this.textAreaTarget.value && this.textAreaTarget.value.length) {
            const formActionUrl = this.data.get("action-url");
            const formBody = new FormData();
            formBody.append("comment[message]", this.textAreaTarget.value);

            this.sendRequest(formActionUrl, formBody);
        }
    }

    private sendRequest(url, formBody) {
        this.submitButtonTarget.disabled = true;

        fetch(
            url,
            {
                method: "POST",
                headers: this.requestHeaders(),
                body: formBody,
                credentials: "same-origin",
            }
        ).then((response) =>
            response.text().then((html) =>
                Promise.resolve({ success: response.ok, body: html })
            )
        ).then((data) => {
            if (data.success) {
                this.updateCommentsContainer(data.body);
                this.resetFormFields();
            } else {
                this.insertErrorMessage(i18n.t("comments_controller.comment_could_not_saved"));
                this.submitButtonTarget.disabled = false;
            }
        }).
            catch(err => {
                Sentry.notify(err);
            });
    }

    private requestHeaders() {
        const csrfTag = (<HTMLMetaElement>document.querySelector('meta[name="csrf-token"]'));
        const headers = {};
        headers['X-Requested-With'] = 'XMLHttpRequest';

        if (csrfTag) { headers["X-CSRF-Token"] = csrfTag.content; }

        return headers;
    }

    private updateCommentsContainer(htmlBody) {
        this.commentsContainerTarget.innerHTML = htmlBody;

        this.updateCommentsCount();
    }

    private insertErrorMessage(text) {
        this.errorTarget.classList.remove("hidden");
        this.errorTarget.innerHTML = text;
    }

    private resetFormFields() {
        this.textAreaTarget.value = '';
        this.submitButtonTarget.disabled = false;
        this.errorTarget.classList.add("hidden");
    }

    private updateCommentsCount() {
        this.element.dispatchEvent(new CustomEvent("update-comments-counter", { bubbles: true }));
    }
}
