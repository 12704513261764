import { Controller } from 'stimulus';
import Tribute from "tributejs";
import DataUtils from "../../util/data_utils";
import Sentry from "@utils/sentry";
import escapeHTML from "escape-html";

export default class extends Controller {
    private tribute: Tribute<HTMLElement>;
    private cache: object;

    connect() {
        this.cache = {};
        const options = {
            values: async (text: string, callback) => {
                if (!this.cache[text]) {
                    const items = await DataUtils.request(
                        this.data.get("url"), {q: text}, { parse: false }
                    ).then((response: Response) => {
                        return response.json();
                    }).catch(error => {
                        Sentry.notify(error);
                    });
                    this.cache[text] = items;
                }
                callback(this.cache[text]);
            },
            selectTemplate: (item: any) => {
                const markerElement = document.getElementById(this.data.get("screenshotMarkerId"));
                const itemSelectedEvent = new CustomEvent('screenshots-markers-insert-suggestable', {
                    bubbles: true,
                    detail: {
                        markerElement: markerElement,
                        translationKeyId: item.original.id,
                        translationKeyName: item.original.name,
                    }
                });
                markerElement.dispatchEvent(itemSelectedEvent);
                return `${item.original.name}`;
            },
            lookup: (item: any, _mentionText: string) => {
                return `${item.name}`;
            },
            fillAttr: "name",
            noMatchTemplate: () => { return null; },
            menuItemTemplate: (item: any) => {
                return `${escapeHTML(item.original.name)}`;
            },
            autocompleteMode: true,
            allowSpaces: true,
        };
        this.tribute = new Tribute(options);
        this.tribute.attach(this.element);
    }

    disconnect() {
        this.tribute.detach(this.element);
    }
}
