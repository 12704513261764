import { Controller } from 'stimulus';
import Tribute from "tributejs";
import DataUtils from "./../util/data_utils";
import Sentry from "@utils/sentry";
import escapeHTML from "escape-html";

export default class extends Controller {
    private tribute: Tribute<HTMLElement>;
    private excludeTribute: Tribute<HTMLElement>;
    private cache: object;

    connect() {
        this.cache = {};
        const options = {
            trigger: "tag:",
            values: async (text: string, callback) => {
                if (!this.cache[text]) {
                    const items = await DataUtils.request(
                        this.data.get("url"), { q: text }, { parse: false }
                    ).then((response: Response) => {
                        return response.json();
                    }).catch(error => {
                        Sentry.notify(error);
                    });
                    this.cache[text] = items;
                }
                callback(this.cache[text]);
            },
            lookup: (item: any, _mentionText: string) => {
                return `${item.name}`;
            },
            fillAttr: "name",
            noMatchTemplate: () => { return null; },
            menuItemTemplate: (item: any) => {
                return `${escapeHTML(item.original.name)}`;
            },
            autocompleteMode: Boolean(this.data.get("autocompleteMode")) || false,
        };
        this.tribute = new Tribute(options);
        this.tribute.attach(this.element);

        if (this.data.get("excludeEnabled")) {
            this.excludeTribute = new Tribute({ ...options, ...{ trigger: "-tag:" } });
            this.excludeTribute.attach(this.element);
        }
    }

    disconnect() {
        this.tribute.detach(this.element);
        this.excludeTribute.detach(this.element);
    }
}
