import { Controller } from "stimulus";

export default class TurboModalController extends Controller {
    static targets = [
        "modal",
        "formButton"
    ];

    protected modalTarget: HTMLDivElement;
    protected formButtonTargets: HTMLInputElement[];
    protected hasModalTarget: boolean;

    connect() {
        if (this.autoloadEnabled) this.show();
    }

    disconnect() {
        if (this.hasModalTarget) {
            (<any>window).$(this.modalTarget).off('hide.bs.modal');
        }
    }

    hideIfSuccess(event?: CustomEvent) {
        if (event?.detail?.success) {
            this.hide(event);
        }
    }

    show(event?: CustomEvent) {
        event?.preventDefault();
        if (this.hasModalTarget) {
            (<any>window).$(this.modalTarget).modal("show");
        }
    }

    hide(event?: CustomEvent) {
        event?.preventDefault();
        if (this.hasModalTarget) {
            (<any>window).$(this.modalTarget).modal("hide");
        }
    }

    enableFormButtons(event?: CustomEvent) {
        this.formButtonTargets.forEach(formButton => {
            formButton.disabled = false;
        });
    }

    disableFormButtons(event?: CustomEvent) {
        this.formButtonTargets.forEach(formButton => {
            formButton.disabled = true;
        });
    }

    private get autoloadEnabled(): boolean {
        return !this.data.get("disableAutoload");
    }
}
