import { Controller } from "stimulus";
import { i18n } from "../../i18n/config";

export default class extends Controller {

    static targets = [
        "tabButtonLinkWrap",
        "comment",
    ];

    private tabButtonLinkWrapTarget: HTMLSpanElement;
    private commentTargets: HTMLDivElement[];

    hasTabButtonLinkWrapTarget: boolean;

    update(event: Event) {
        if (this.hasTabButtonLinkWrapTarget) {
            const commentsCount = this.commentTargets.length;
            const linkTag = <HTMLLinkElement>this.tabButtonLinkWrapTarget.querySelector("[data-role='comments-counter']");
            if (linkTag) {
                linkTag.innerText = i18n.t("editor.comments_label", { count: commentsCount });
            }
        }
    }
}
