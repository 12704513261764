import { Controller } from "stimulus";

export default class extends Controller {

    static targets = [
        "frame"
    ];

    private frameTarget!: HTMLElement;

    connect() {
        this.element.addEventListener("turbo:frame-load", this.frameLoaded.bind(this));
    }

    frameLoaded(event: CustomEvent) {
        const htmlContainer = this.frameTarget;

        if (htmlContainer) {
            htmlContainer.addEventListener("load", this.resizeIframe.bind(this, htmlContainer));
            window.addEventListener("resize", this.resizeIframe.bind(this, htmlContainer));
        }
    }

    resizeIframe(htmlContainer: HTMLElement) {
        const container = htmlContainer.parentElement;
        const containerRect = container.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const availableHeight = viewportHeight - containerRect.top;
        htmlContainer.style.height = `${availableHeight}px`;
    }
}
