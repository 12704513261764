import { Controller } from "stimulus";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = [
        "content",
        "gradient",
        "trigger",
    ];

    private contentTarget: HTMLDivElement;
    private gradientTarget: HTMLDivElement;
    private triggerTarget: HTMLLinkElement;


    connect() {
        const maxHeight = this.contentTarget.getBoundingClientRect().height;

        this.contentTarget.classList.remove("job-briefing__content--truncated");

        window.requestAnimationFrame(() => {
            if (this.contentTarget.getBoundingClientRect().height > maxHeight) {
                this.contentTarget.classList.add("job-briefing__content--truncated");
            } else {
                DomUtils.remove(this.triggerTarget);
                DomUtils.remove(this.gradientTarget);
            }
        });
    }

    showBriefingCompletely(event: Event) {
        event.stopPropagation();
        event.preventDefault();

        this.contentTarget.classList.remove("job-briefing__content--truncated");

        DomUtils.remove(this.triggerTarget);
        DomUtils.remove(this.gradientTarget);
    }
}
