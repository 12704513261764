import ModalController from "../modal_controller";

export default class extends ModalController {
    static targets = [
        'modal',
        'segmentInput',
    ];

    private segmentInputTargets: HTMLInputElement[];


    submit(event: Event): void {
        event.preventDefault();

        const keyNamesMissing = !this.segmentInputTargets.every(segmentInput => segmentInput.value !== "");
        if (keyNamesMissing) {
            this.show();
        } else {
            this.submitForm(event.currentTarget as HTMLInputElement);
        }
    }

    continue(event: Event) {
        this.submitForm(event.currentTarget as HTMLInputElement);
    }

    private submitForm(target: HTMLInputElement) {
        target.form.submit();
    }
}
