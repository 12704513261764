import { Controller } from 'stimulus';
import DataUtils from '@utils/data_utils';
import Sentry from "@utils/sentry";
import debounce from 'lodash/debounce';

export default class extends Controller {
    static targets = [
        'editButton',
        'keyNameInput'
    ];

    private editButtonTarget: HTMLAnchorElement;
    private keyNameInputTarget: HTMLInputElement;

    connect() {
        const translationKeyId = this.data.get('translation-key-id');
        this.regenerateURL(translationKeyId);
    }

    initialize() {
        this.onKeyDown = debounce(this.onKeyDown, 200).bind(this);
    }

    regenerateURLFromEvent(event: CustomEvent<{translationKeyId: string; translationKeyName: string}>): void {
        const currentTarget = event.currentTarget as HTMLInputElement;
        setTimeout(() => {
            this.regenerateURL(event.detail.translationKeyId);
            currentTarget.value = event.detail.translationKeyName;
        });
    }

    async onKeyDown (e: KeyboardEvent) {
        if (e.keyCode === 13) { // 13 === Enter
            e.preventDefault();
            return;
        }
        await this.updateKey();
    }

    onUpdateKey (e: CustomEvent<{translationKeyName: string}>) {
        this.keyNameInputTarget.value = e.detail.translationKeyName;
    }

    private async updateKey () {
        if (this.editButtonTarget.classList.contains('disabled')) return;

        this.editButtonTarget.classList.add('disabled');
        const name = this.keyNameInputTarget.value;
        const translationKeyId = this.data.get('translation-key-id');

        await DataUtils.request(
            this.data.get('edit-key-url').replace('KEY_CODE', translationKeyId),
            void 0,
            void 0, {
                method: 'PATCH',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name})
            }
        )
            .then(({id})=> this.regenerateURL(id))
            .catch((err: string) => {
                Sentry.notify(err);
            });

        this.editButtonTarget.classList.remove('disabled');
    }

    private regenerateURL(translationKeyId: string) {
        this.editButtonTarget.href = this.generateMarkerEditUrl(translationKeyId);
    }

    private generateMarkerEditUrl(keyId: string) {
        return this.data.get('modal-url').replace('CODE', keyId);
    }
}
