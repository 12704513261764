import SelectizeController from "./selectize_controller";

export default class extends SelectizeController {
    connect() {
        const options: any = {
            plugins: ["clear_on_type"],
            create: false,
            persist: false,
            render: {
                item: (item, _escape) => {
                    return `<div>${item.text}</div>`;
                },
                option: (item, _escape) => {
                    return `<div>${item.text}</div>`;
                }
            },
        };

        (<any>window).$(this.element).selectize(options);
        (<any>window).$(this.element).on("change", this.dispatchChangeEvent.bind(this));
    }
}
