export default class DataUtils {
    static encodeQueryData(data: any = false) {
        if (this.isObjectEmpty(data) || !data) {
            return false;
        }
        const ret = [];
        for (const d in data) {
            if (Array.isArray(data[d])) {
                data[d].forEach(value => {
                    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(value));
                });
            } else {
                ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
            }
        }
        return ret.join('&');
    }

    static isObjectEmpty(object) {
        return Object.keys(object).length === 0;
    }

    static async request(url: string, queryParams = {}, options = { parse: true }, fetchOptions: any = { method: "GET" }): Promise<any> {
        let fullUrl: string;
        const headers = {};
        const csrfTag = document.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;

        if (this.isObjectEmpty(queryParams)) {
            fullUrl = url;
        } else {
            if (fetchOptions.method === "GET") {
                if (url.includes("?")) {
                    fullUrl = `${url}&${this.encodeQueryData(queryParams)}`;
                } else {
                    fullUrl = `${url}?${this.encodeQueryData(queryParams)}`;
                }
            } else {
                fetchOptions['body'] = JSON.stringify(queryParams);
                headers['Content-Type'] = 'application/json';

                fullUrl = url;
            }
        }

        if (fetchOptions.method !== "GET" && csrfTag) {
            headers["X-CSRF-Token"] = csrfTag.content;
        }

        if (options.parse) {
            headers['Accept'] = 'application/json';
        }

        fetchOptions['headers'] = Object.assign(fetchOptions['headers'] || {}, headers);
        const response = await fetch(fullUrl, fetchOptions);

        if (!response.ok) throw response;
        return options.parse || !options ? await response.json() : response;
    }
}
