import { Controller } from "stimulus";
import Sentry from "@utils/sentry";
import DataUtils from "@utils/data_utils";
import Spinner from "@utils/spinner";

export default class extends Controller {

    static targets = [
        "toggleSystemTagsButton",
        "checkboxWrap",
    ];

    private toggleSystemTagsButtonTarget: HTMLInputElement;
    private checkboxWrapTarget: HTMLDivElement;
    private spinner: Spinner;

    async toggleSystemTags() {
        this.startSpinner();
        this.toggleSystemTagsButtonTarget.disabled = true;
        if (this.toggleSystemTagsButtonTarget.checked) {
            await this.loadEditorTags(this.data.get("allTagsPath"));
        } else {
            await this.loadEditorTags(this.data.get("userTagsPath"));
        }
    }

    async reload(event) {
        this.startSpinner();
        if (this.toggleSystemTagsButtonTarget.checked) {
            await this.loadEditorTags(this.data.get("allTagsPath"));
        } else {
            await this.loadEditorTags(this.data.get("userTagsPath"));
        }
    }

    private async loadEditorTags(url: string) {
        let data = await DataUtils.request(
            url,
            void 0,
            {
                parse: false
            }, {
                method: "GET",
                credentials: "same-origin"
            })
            .catch((err: string) => {
                Sentry.notify(err);
            });
        data = await data.text();
        this.element.innerHTML = data;

        this.spinner.stop();
        this.toggleSystemTagsButtonTarget.disabled = false;
    }

    private startSpinner() {
        this.spinner = new Spinner(this.checkboxWrapTarget, "small");
        this.spinner.start();
    }
}
