import Sentry from "@utils/sentry";
import Tracker from '@utils/tracker';
import Mousetrap from 'mousetrap';
import 'mousetrap-global-bind';
import { Controller } from "stimulus";


interface Shortcut {
    name: string;
    binding: string[];
    global: boolean;
    actionType: string;
    identifier: string;
    section: string;
    hidden: boolean;
    target: string;
}


export default class ShortcutsController extends Controller {
    shortcuts: Shortcut[];

    connect() {
        this.shortcuts = <Shortcut[]>JSON.parse(this.data.get("shortcuts"));
        this.shortcuts.forEach((shortcut) => { this.bindShortcut(shortcut); });
    }

    private bindShortcut(shortcut: Shortcut) {
        if (shortcut.global) {
            Mousetrap.bind(shortcut.binding, (event, combo) => {
                if (event && event.preventDefault) {
                    event.preventDefault();
                }

                this.handle(combo, event);
                this.emit(combo, event);
            });
        } else {
            Mousetrap.bind(shortcut.binding, (event, combo) => {
                if (event && event.preventDefault) {
                    event.preventDefault();
                }

                this.handle(combo, event);
                this.emit(combo, event);
            });
        }
    }

    private emit(combo: string, event) {
        const shortcut = this.shortcuts.find((shortcut) => shortcut.binding.includes(combo));
        if (shortcut) {
            const win = (window as any);

            const keyboardEvent = new CustomEvent("keyboard:event", {
                detail: shortcut
            });

            win.requestAnimationFrame(() => {
                win.dispatchEvent(keyboardEvent);
            });
        }
    }

    private handle(combo: string, event) {
        const shortcut = this.shortcuts.find((shortcut) => shortcut.binding.includes(combo));
        if (shortcut) {
            switch (shortcut.actionType) {
                case "submit":
                    this.handleSubmit(shortcut, event);
                    break;
                case "link":
                    this.handleLink(shortcut, event);
                    break;
                case "focus":
                    this.handleFocus(shortcut, event);
                    break;
                case "void":
                    break;
                default:
                    console.warn(`Unknown Action Type: '${shortcut.actionType}'`);
                    Sentry.notify(`Unknown Action Type: '${shortcut.actionType}'`);
            }

            Tracker.track("Triggered Keyboard Shortcut", {
                Name: shortcut.name,
                Binding: shortcut.binding,
            });
        } else {
            console.warn(`Unknown Combo: '${combo}'`);
            Sentry.notify(`Unknown Combo: '${combo}'`);
        }
    }

    private handleSubmit(shortcut: Shortcut, event) {
        const inputField = <HTMLInputElement>event.target;
        const targetForm = <HTMLFormElement>document.querySelector(shortcut.target);

        if (targetForm.contains(inputField)) {
            (<any>window).$(targetForm).submit();
        }
    }

    private handleLink(shortcut: Shortcut, event) {
        if (shortcut.identifier) {
            const selector = `[data-shortcut-role="${shortcut.identifier}"]`;
            const handler = <HTMLElement>document.querySelector(selector);
            if (handler) {
                handler.click();
            }
        }
    }

    private handleFocus(shortcut: Shortcut, event) {
        if (shortcut.identifier) {
            const handler = <HTMLElement>document.querySelector(`[data-shortcut-role="${shortcut.identifier}"]`);
            if (handler) {
                handler.focus();
            }
        }
    }
}
