import { Controller } from "stimulus";
import { ModalContainer } from "@utils/types";

export default class extends Controller {

    static targets = [
        "modal",
        "submit"
    ];

    element: HTMLDivElement;
    modalTarget: HTMLFormElement & ModalContainer;
    submitTarget: HTMLInputElement;

    saved() {
        this.submitTarget.classList.add("disabled");

        let event = new CustomEvent('user:reload');
        if (this.hasNextStep) {
            event = new CustomEvent('user:assign:locales');
        }

        this.modalTarget.dispatchEvent(event);
    }

    private get hasNextStep(): boolean {
        return this.submitTarget.dataset.nextStep === 'true';
    }
}
