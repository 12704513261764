import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        'hiddenInput',
        'checkbox'
    ];

    private hiddenInputTargets: HTMLInputElement[];
    private checkboxTargets: HTMLInputElement[];
    private populatedValues: Array<string>;
    populateValuesToInput(event: Event) {
        this.populatedValues = [];
        this.checkboxTargets.forEach((checkbox) => {
            if(checkbox.checked){
                this.populatedValues.push(checkbox.value);
            }
        });

        this.hiddenInputTargets.forEach((input) => {
            input.value = this.populatedValues.join(',');
        });
    }
}
