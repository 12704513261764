import { Controller } from "stimulus";
import Sentry from "@utils/sentry";
import { Turbo } from "@hotwired/turbo-rails";

/*
    Because loading table rows async is not possible with turbo frames, we need to use turbo streams. (See https://github.com/hotwired/turbo/issues/48)


    This controller is used to render a table asynchronously. It is used in the following way:

    <div data-controller="async-turbo-table" data-async-turbo-table-url="<%= url %>" data-async-turbo-table-total="<%= count %>">
        <table class="hidden" data-target="async-turbo-table.table">
            <thead>
              ...
            </thead>
            <tbody id="async-turbo-table-tbody" data-target="async-turbo-table.tbody">
            </tbody>
        </table>
        <div data-target="async-turbo-table.loadingSpinner">
            <%= render "shared/loading_spinner" %>
        </div>
    </div>

    The controller will then fetch the url and render the table in the tbody. The table will be rendered
    in pages of 10 rows. The total number of rows is specified in the data attribute "total". The url
    should be a url that returns a turbo stream with a table element. The controller will then render
    the table in the tbody of the table in the controller. The controller will also add a turbo_stream_table_selector
    parameter to the url. This parameter is used to identify the table in the turbo stream. If the tbody
    has an id, this id will be used. Otherwise, the id of the table will be used. If the table does not
    have an id, the controller will not work. The controller will also add a page and per_page parameter
    to the url. The per_page parameter will be set to 10 by default. The page parameter will be set to
    the page that is being loaded. The controller will load all pages until the total number of rows
    is reached. The controller will also hide the table until all rows have been loaded.

*/
export default class AsyncTurboTableController extends Controller {
    static targets = ["tbody", "table", "loadingSpinner"];

    tableTarget: HTMLTableElement;
    tbodyTarget: HTMLElement;
    loadingSpinnerTarget: HTMLElement;
    pagesContent: Map<number, string> = new Map(); // Map-Object to store pages content
    pagesLoaded: number = 0; // To track number of loaded pages

    connect() {
        this.pagesContent = new Map();
        this.load();
    }

    load() {
        const pages = Math.ceil(this.total / this.perPage);
        this.pagesLoaded = 0; 

        for (let page = 1; page <= pages; page++) {
            this.loadPage(page);
        }
    }

    private loadPage(page) {
        fetch(
            this.urlWithParams(page),
            {
                headers: {
                    Accept: "text/vnd.turbo-stream.html",
                },
            }
        )
            .then(r => r.text())
            .then((html) => {
                this.pagesContent.set(page, html);

                this.pagesLoaded++;
                if (this.pagesLoaded === Math.ceil(this.total / this.perPage)) {
                    this.renderAllPages(); // Render all pages once all are loaded
                }
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    private renderAllPages() {
        // Sort the pages by page number
        const sortedPages = [...this.pagesContent.entries()].sort(([a], [b]) => a - b);

        // Render each page in the correct order
        sortedPages.forEach(([page, html]) => {
            Turbo.renderStreamMessage(html);
        });

        this.tableTarget.classList.remove("hidden");
        this.loadingSpinnerTarget.remove();
    }

    private urlWithParams(page: number) {
        const url = new URL(this.url);
        url.searchParams.set("page", page.toString());
        url.searchParams.set("per_page", this.perPage.toString());
        url.searchParams.set("turbo_stream_table_selector", this.turboSelector);
        return url;
    }

    get turboSelector() {
        return this.tbodyTarget?.id || this.tableTarget?.id || null;
    }

    get perPage() {
        return parseInt(this.data.get("per_page")) || 10;
    }
    get url() {
        return this.data.get("url");
    }

    get total() {
        return parseInt(this.data.get("total"));
    }
}
