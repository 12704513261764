import { Controller } from "stimulus";
import DomUtils from "@utils/dom_utils";
import Tracker from '@utils/tracker';
import { ModalContainer, RemoteModalContainer, ListSearchContainer, FormInputsContainer } from '@utils/types';

export default class extends Controller {
    static targets = [
        "error",
        "spaceListItem",
        "newSpaceForm",
        "addExistingProjectForm",
        "addExistingProjectModal",
        "editMembersModal",
        "newProjectSpaceCode",
        "renameSpaceModal",
        "deleteSpaceModal",
        "editSpaceOwnerModal",
        "projectsList",
        "toolbarMoreButton",
        "toolbarSpaceName",
        "toolbarSpaceValue",
        "hideableToolbarElement",
        "toolbarForm",
        "toolbarSearchField",
        "sidebarWrap",
        "toggleSidebarElement",
    ];

    element: HTMLDivElement & ListSearchContainer;
    private sidebarWrapTarget: HTMLDivElement;
    private toggleSidebarElementTargets: HTMLElement[];
    private errorTarget: HTMLDivElement;
    private spaceListItemTargets: HTMLElement[];
    private newSpaceFormTarget: HTMLFormElement & FormInputsContainer;
    private addExistingProjectFormTarget: HTMLFormElement;
    private addExistingProjectModalTarget: HTMLDivElement & ModalContainer;
    private editMembersModalTarget: HTMLDivElement & RemoteModalContainer;
    private renameSpaceModalTarget: HTMLDivElement & ModalContainer & FormInputsContainer;
    private deleteSpaceModalTarget: HTMLDivElement & ModalContainer;
    private editSpaceOwnerModalTarget:  HTMLDivElement;
    private newProjectSpaceCodeTarget: HTMLInputElement;
    private projectsListTarget: HTMLDivElement;
    private toolbarMoreButtonTarget: HTMLDivElement;
    private toolbarSpaceNameTarget: HTMLDivElement;
    private toolbarSpaceValueTarget: HTMLElement;
    private toolbarSearchFieldTarget: HTMLInputElement;
    private hideableToolbarElementTargets: HTMLDivElement[];
    private toolbarFormTarget: HTMLFormElement;
    private hasSidebarWrapTarget: boolean;
    private hasProjectsListTarget: boolean;
    private hasToolbarMoreButtonTarget: boolean;
    private hasNewProjectSpaceCodeTarget: boolean;
    private hasAddExistingProjectFormTarget: boolean;

    connect() {
        if (this.isMobileDevice() && this.hasSidebarWrapTarget && this.sidebarWrapTarget.dataset.sidebarHidden == 'false') {
            this.toggleSidebar();
        }
    }

    closeSidebar(): void {
        this.toggleSidebar();
    }

    sidebarCollapse(): void {
        if (this.isMobileDevice()) {
            this.toggleSidebar();
        }
    }

    loadSpace(event: Event): void {
        event.preventDefault();

        if (!this.canSwitchSpace()) {
            return;
        }

        let eventTarget = <HTMLElement>event.target;
        if (eventTarget.tagName.toLowerCase() != 'a') {
            eventTarget = <HTMLElement>eventTarget.parentNode;
        }

        this.disableSpaceLinks();
        this.updateToolbarForm(eventTarget.dataset.newSpaceUrl);
        this.markSpaceAsActive(eventTarget);
        this.updateMoreButton(eventTarget);
        this.updateListUrl(eventTarget.dataset.newHistoryUrl);
        this.updateAddExistingProjectUrl(eventTarget);
        this.updateAddNewProjectSpaceCode(eventTarget);
        this.updateToolbarSpaceName(eventTarget);

        this.element.listSearch.reload();
    }

    focusNewSpaceForm(event: Event): void {
        event.preventDefault();
        this.newSpaceFormTarget.formInputs.focus();
    }

    showAddExistingProject(event: Event): void {
        event.preventDefault();
        this.addExistingProjectModalTarget.modal.show();
        Tracker.track("User clicks 'Add existing Project' button", { "Source": "Translation Center" });
    }

    showEditMembers(event: Event): void {
        event.preventDefault();
        this.editMembersModalTarget.remoteModal.show();
        Tracker.track("User clicked 'Edit members' button", { "Source": "Translation Center" });
    }

    showRenameSpace(event: Event): void {
        event.preventDefault();
        this.renameSpaceModalTarget.modal.show();
        this.renameSpaceModalTarget.formInputs.focus();
        Tracker.track("User clicks 'Rename Space' button", { "Source": "Translation Center" });
    }

    showDeleteSpace(event: Event): void {
        event.preventDefault();
        this.deleteSpaceModalTarget.modal.show();
        Tracker.track("User clicks 'Delete Space' button", { "Source": "Translation Center" });
    }

    updateProjectsCount(): void {
        if (this.hasProjectsListTarget) {
            const count = Number(this.projectsListTarget.dataset.projectsCount);
            this.updateCounterOfActiveSpace(count);
            this.showHideableToolbarElements();
            DomUtils.hideElement(this.toolbarSpaceNameTarget);
        } else {
            if (this.toolbarSearchFieldTarget.value) return;

            this.hideHideableToolbarElements();
            DomUtils.showElement(this.toolbarSpaceNameTarget);
        }
    }

    enableSpaceLinks() {
        this.spaceListItemTargets.forEach((item) => {
            item.classList.remove("spaces-list__list_item--disabled");
        });
    }

    openEditSpaceOwnerModal(event: Event): void {
        event.preventDefault();
        (<any>window).$(this.editSpaceOwnerModalTarget).modal("show");
    }


    private isMobileDevice() {
        return window.innerWidth <= 768;
    }

    private toggleSidebar(){
        this.toggleSidebarElementTargets.forEach((element) => {
            element.classList.toggle("sidebar-hidden");
        });
    }

    private disableSpaceLinks() {
        this.spaceListItemTargets.forEach((item) => {
            item.classList.add("spaces-list__list_item--disabled");
        });
    }

    private canSwitchSpace(): boolean {
        return !this.spaceListItemTargets[0].classList.contains("spaces-list__list_item--disabled");
    }

    private updateListUrl(url: string): void {
        this.element.dataset.listSearchUrl = url;
    }

    private updateToolbarForm(url: string): void {
        this.toolbarSearchFieldTarget.value = "";
        this.toolbarFormTarget.action = url;
    }

    private updateCounterOfActiveSpace(count: number): void {
        const activeSpace = this.element.querySelector('.spaces-list__list_item--active');
        (<HTMLElement>activeSpace.querySelector('.spaces-list__list_counter')).innerText = count.toString();
    }

    private showHideableToolbarElements() {
        this.hideableToolbarElementTargets.forEach((toolbarElement) => {
            DomUtils.showElement(toolbarElement);
        });
    }

    private hideHideableToolbarElements() {
        this.hideableToolbarElementTargets.forEach((toolbarElement) => {
            DomUtils.hideElement(toolbarElement);
        });
    }

    showError(): void {
        DomUtils.showElement(this.errorTarget);
    }

    hideError(): void {
        DomUtils.hideElement(this.errorTarget);
    }

    private markSpaceAsActive(eventTarget: HTMLElement): void {
        const parentListItem = eventTarget.closest(".spaces-list__list_item");
        this.removeActiveClassFromSpaceListItems();
        parentListItem.classList.add("spaces-list__list_item--active");
    }

    private removeActiveClassFromSpaceListItems(): void {
        this.spaceListItemTargets.forEach((listItem) => {
            listItem.classList.remove("spaces-list__list_item--active");
        });
    }

    private updateAddExistingProjectUrl(eventTarget: HTMLElement): void {
        if (this.hasAddExistingProjectFormTarget && eventTarget.dataset.newItemsUrl) {
            this.addExistingProjectFormTarget.setAttribute("action", eventTarget.dataset.newItemsUrl);
            const button = this.addExistingProjectFormTarget.querySelector("input[type=submit]") as HTMLInputElement;
            button.click();
        }
    }

    private updateAddNewProjectSpaceCode(eventTarget: HTMLElement): void {
        if (this.hasNewProjectSpaceCodeTarget) {
            this.newProjectSpaceCodeTarget.value = eventTarget.dataset.spaceCode;
        }
    }

    private updateMoreButton(eventTarget: HTMLElement): void {
        if (this.hasToolbarMoreButtonTarget) {
            if (eventTarget.dataset.toolbarMoreButtonVisible) {
                DomUtils.showElement(this.toolbarMoreButtonTarget);
            }
            else {
                DomUtils.hideElement(this.toolbarMoreButtonTarget);
            }
        }
    }

    private updateToolbarSpaceName(eventTarget: HTMLElement): void {
        this.toolbarSpaceValueTarget.innerText = (eventTarget.dataset.spaceName || "");
    }
}
