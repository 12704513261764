import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        "table",
        "scrollButtons",
        "scrollLeftButton",
        "scrollRightButton"
    ];

    private tableTarget: HTMLElement;
    private scrollButtonsTarget: HTMLElement;
    private scrollLeftButtonTarget: HTMLElement;
    private scrollRightButtonTarget: HTMLElement;
    private scrollActionId: number;

    connect(): void {
        this.scrollLeftButtonTarget.addEventListener('click', this.scrollLeft.bind(this));
        this.scrollRightButtonTarget.addEventListener('click', this.scrollRight.bind(this));
        this.onResize();
    }

    disconnect(): void {
        this.scrollLeftButtonTarget.removeEventListener('click', this.scrollLeft.bind(this));
        this.scrollRightButtonTarget.removeEventListener('click', this.scrollRight.bind(this));
    }

    onResize(): void {
        const areButtonsNotRequired = this.tableTarget.offsetWidth <= this.tableTarget.parentElement.offsetWidth;
        this.scrollButtonsTarget.classList.toggle('hidden', areButtonsNotRequired);
        this.initializeButtonsState();
    }

    scrollRight(): void {
        const elementsScrolledRight = this.tableColumns.filter(column => this.isScrolledRight(column));
        const elementToScroll = elementsScrolledRight.shift();

        if (elementToScroll) this.scrollElementRight(elementToScroll);
    }

    scrollLeft(): void {
        const elementsScrolledLeft = this.tableColumnsWithoutFirst.filter(column => this.isScrolledLeft(column));
        const elementToScroll = elementsScrolledLeft.pop();

        if (elementToScroll) this.scrollElementLeft(elementToScroll);
    }

    onScroll(): void {
        if (this.scrollActionId) window.clearTimeout(this.scrollActionId);
        this.scrollActionId = window.setTimeout(this.initializeButtonsState.bind(this), 100);
    }

    private isScrolledRight(element): boolean {
        const rect = element.getBoundingClientRect();
        const tableParentRect = this.tableTarget.parentElement.getBoundingClientRect();
        return rect.right > tableParentRect.right;
    }

    private isScrolledLeft(element): boolean {
        const rect = element.getBoundingClientRect();
        const tableParentRect = this.tableTarget.parentElement.getBoundingClientRect();
        return rect.left < tableParentRect.left + this.firstColumnWidth;
    }

    private scrollElementRight(elementToScroll: HTMLElement) {
        const leftOffset = elementToScroll.offsetWidth - this.tableTarget.parentElement.offsetWidth + elementToScroll.offsetLeft;
        this.tableTarget.parentElement.scrollLeft = leftOffset;
    }

    private scrollElementLeft(elementToScroll: HTMLTableDataCellElement) {
        const leftOffset = elementToScroll.offsetLeft - this.tableTarget.parentElement.offsetLeft - this.firstColumnWidth;
        this.tableTarget.parentElement.scrollLeft = leftOffset;
    }

    private get firstColumnWidth() {
        return this.tableColumns[0].offsetWidth;
    }

    private get tableColumnsWithoutFirst() {
        return this.tableColumns.slice(1);
    }

    private get tableColumns() {
        return Array.from(this.tableTarget.querySelector('thead tr').querySelectorAll('td'));
    }

    private initializeButtonsState() {
        const elementsScrolledLeft = this.tableColumnsWithoutFirst.filter(column => this.isScrolledLeft(column));
        const elementsScrolledRight = this.tableColumns.filter(column => this.isScrolledRight(column));
        this.scrollRightButtonTarget.classList.toggle('disabled', elementsScrolledRight.length === 0);
        this.scrollLeftButtonTarget.classList.toggle('disabled', elementsScrolledLeft.length === 0);
    }
}
