import { Controller } from "stimulus";
import { i18n } from "../../i18n/config";


export default class extends Controller {
    static targets = [
        'providerSelect',
        'providerText',
    ];

    private providerSelectTarget: HTMLSelectElement;
    private providerTextTarget: HTMLDivElement;

    private defaultProviderText: string;

    connect() {
        this.defaultProviderText = this.providerTextTarget.innerHTML;
    }

    updateProvider() {
        if (this.providerSelectTarget.value === "") {
            this.providerTextTarget.innerHTML = this.defaultProviderText;
        } else {
            this.providerTextTarget.innerHTML = i18n.t('account.machine_translation_service_hint', { service_name: this.providerSelectTarget.options[this.providerSelectTarget.selectedIndex].text });
        }
    }
}
