import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "selectAllCheckbox",
        "individualCheckbox",
        "countCheckbox",
        "counter",
    ];

    private selectAllCheckboxTarget: HTMLInputElement;
    private hasSelectAllCheckboxTarget: boolean;
    private individualCheckboxTargets: HTMLInputElement[];
    private countCheckboxTargets: HTMLInputElement[];
    private counterTarget: HTMLElement;

    private hasCounterTarget: boolean;

    connect() {
        this.update();
    }

    selectAll() {
        this.individualCheckboxTargets.forEach((individualCheckbox) => {
            const parent = individualCheckbox.closest("[data-parent]") as HTMLElement;

            let isVisible = individualCheckbox.offsetParent !== null;
            if (parent) isVisible = parent.offsetParent !== null;
            // ensure foward compatibility with syntaxified checkboxes
            if (individualCheckbox.classList.contains("syn_checkbox-input")) isVisible = true;

            if (this.hasSelectAllCheckboxTarget && isVisible) {
                this.setCheckbox(individualCheckbox, this.selectAllCheckboxTarget.checked);
            }

            if (parent) { this.updateParentCounter(parent); }
        });

        this.updateCounter();
    }

    selectAllWithHidden() {
        this.individualCheckboxTargets.forEach((individualCheckbox) => {
            const parent = individualCheckbox.closest("[data-parent]") as HTMLElement;

            if (this.hasSelectAllCheckboxTarget) {
                this.setCheckbox(individualCheckbox, this.selectAllCheckboxTarget.checked);
            }

            if (parent) { this.updateParentCounter(parent); }
        });

        this.updateCounter();
    }

    selectSubset(event: Event) {
        const eventTarget = event.target as HTMLInputElement;
        const parent = eventTarget.closest("[data-parent]") as HTMLElement;

        this.individualCheckboxTargets.forEach((individualCheckbox) => {
            if (parent.contains(individualCheckbox)) {
                this.setCheckbox(individualCheckbox, eventTarget.checked);
            }
        });

        this.update();
        this.updateParentCounter(parent);
    }

    selectIndividual(event: Event) {
        const eventTarget = event.target as HTMLInputElement;
        const parent = eventTarget.closest("[data-parent]") as HTMLElement;

        this.update();
        if (parent) { this.updateParentCounter(parent); }
    }

    private setCheckbox(checkbox, checked) {
        checkbox.checked = checked;
        if (checkbox.hasAttribute("indeterminate")) {
            checkbox.removeAttribute("indeterminate");
        }
        checkbox.dispatchEvent(new CustomEvent(checked ? 'checked' : 'unchecked'));
    }

    private update() {
        this.updateSelectAllCheckbox();
        this.updateCounter();
    }


    private updateSelectAllCheckbox() {
        const isChecked = (individualCheckbox) => individualCheckbox.checked;

        if (this.hasSelectAllCheckboxTarget) {
            this.selectAllCheckboxTarget.checked = this.individualCheckboxTargets.every(isChecked);
        }
    }

    private updateCounter() {
        const isChecked = (countCheckbox) => countCheckbox.checked;
        if (this.hasCounterTarget) {
            this.counterTarget.innerHTML = this.countCheckboxTargets.filter(isChecked).length.toString();
        }
    }

    private updateParentCounter(parent: HTMLElement) {
        const isChild = (countCheckbox) => parent.contains(countCheckbox);
        const isChecked = (countCheckbox) => countCheckbox.checked;

        parent.dataset.count =
            this.countCheckboxTargets.filter(isChild).filter(isChecked).length.toString();

        parent.dispatchEvent(new Event("change"));
    }
}
