import {Controller} from 'stimulus';

export default class DistributionFormController extends Controller {
    static targets = [
        'schedulingToggle',
        'schedulingContainer',
        'triggerInterval',
        'weekDaySelect',
        'weekDayButton',
        'selectedWeekdays',
        'projectId',
        'localesSelectFrame',
    ];

    private schedulingToggleTarget: HTMLInputElement;
    private schedulingContainerTarget: HTMLElement;
    private triggerIntervalTarget: HTMLElement;
    private weekDaySelectTarget: HTMLElement;
    private selectedWeekdaysTarget: HTMLInputElement;
    private weekDayButtonTargets: HTMLButtonElement[];
    private projectIdTargets: HTMLButtonElement[];
    private localesSelectFrameTarget: any;

    connect() {
        const selectedInterval = this.triggerIntervalTarget.getAttribute('data-syntax-select-value');
        const schedulingEnabled = this.schedulingToggleTarget.checked;
        if(schedulingEnabled){
            this.toggleScheduling();
        }
        if (selectedInterval === 'week') {
            this.setExistingWeekdaysAsSelected();
        }
    }

    refresh(event: CustomEvent) {
        // Wait for the form changes to be applied
        window.requestAnimationFrame(() => {
            const formData = new FormData(this.element as HTMLFormElement);
            const queryString = new URLSearchParams(formData as any);
            const modalFrame = document.querySelector('#modal') as any;
            const src = new URL(this.data.get('url'));
            src.search = queryString.toString();
            modalFrame.src = src.toString();
            modalFrame.reload();
        });
    }

    updateLocales(event: CustomEvent) {
        const target = event.target as HTMLDivElement;
        const accountId = this.data.get('account-id');
        const projectId = this.selectedProjectsId();
        const selectedBranch = target.getAttribute('data-syntax-list-select-selected-item-value');
        const localesSelectFrame = this.localesSelectFrameTarget;

        localesSelectFrame.src = this.localesFetchPath(accountId, projectId, selectedBranch);
    }

    toggleDaySelect(event: CustomEvent) {
        if(event.detail.value === 'week'){
            const firstWeekDay = this.weekDayButtonTargets[0];
            this.toggleWeekDayButton(firstWeekDay);
            this.weekDaySelectTarget.classList.remove('hidden');
        }
        else{
            this.weekDaySelectTarget.classList.add('hidden');
        }
    }

    toggleScheduling(){
        this.schedulingContainerTarget.classList.toggle('hidden');
    }

    selectDay(event: CustomEvent){
        const target = event.target as HTMLButtonElement;
        const clickedButtonAlreadyToggled = target.classList.contains('selected-day');

        if(clickedButtonAlreadyToggled && this.selectedDaysCount() === 1){
            // Do not untoggle the last remaining day
            return;
        }
        this.toggleWeekDayButton(target);
    }

    private selectedProjectsId(){
        const selectedProjectListItem = this.projectIdTargets.find((target) =>
            target.getAttribute("is_selected") === "true"
        );

        return selectedProjectListItem.getAttribute('data-project');
    }

    private selectedDaysCount(){
        return this.weekDayButtonTargets.filter(button => button.classList.contains('selected-day')).length;
    }

    private toggleWeekDayButton(button){
        button.classList.toggle('selected-day');
        this.updateSelectedWeekdays(button.value);
    }


    private localesFetchPath(accountId: string, projectId: string, branch: string){
        const localesPath = this.data.get('locale-index-path').replace(":account_id", accountId).replace(":project_id", projectId);
        const queryString = new URLSearchParams();
        queryString.append('branch_name', branch);

        return `${localesPath}?${queryString.toString()}`;
    }

    private setExistingWeekdaysAsSelected(){
        this.weekDaySelectTarget.classList.remove('hidden');
        const selectedWeekdays = this.selectedWeekdaysTarget.value.split(',');

        this.weekDayButtonTargets.forEach((button) => {
            const dayNumber = button.getAttribute('data-week-day-number');

            if(selectedWeekdays.includes(dayNumber)){
                button.classList.add('selected-day');
            }
        });
    }

    private updateSelectedWeekdays(day: string){
        let selectedWeekDays = (this.selectedWeekdaysTarget.value && this.selectedWeekdaysTarget.value.split(',')) || [];

        if(selectedWeekDays.includes(day)){
            selectedWeekDays = selectedWeekDays.filter((d) => d !== day);
        }
        else{
            selectedWeekDays = [...selectedWeekDays, day];
        }

        this.selectedWeekdaysTarget.value = selectedWeekDays.join(',');
    }
}
