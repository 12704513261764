import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['frame', 'checkbox'];

    private frameTarget: HTMLInputElement;
    private checkboxTargets: HTMLInputElement[];
    private hasFrameTarget: boolean;
    private param: string;

    connect() {
        this.param = this.data.get("param");
        this.element.addEventListener("syntax-list-multiselect:update-value", this.updateSrcParam.bind(this));
    }

    disconnect() {
        this.element.removeEventListener("syntax-list-multiselect:update-value", this.updateSrcParam.bind(this));
    }

    updateSrcParam(event: Event) {
        if (!this.hasFrameTarget) return;

        const frameLocation = this.frameTarget.dataset.syntaxTurboFrameSearchSrc;
        const url= new URL(frameLocation, location.origin);

        url.searchParams.delete(this.param);

        this.checkboxTargets.forEach((checkbox) => {
            if (checkbox.checked) {
                url.searchParams.append(this.param, checkbox.value);
                this.frameTarget.dataset.syntaxTurboFrameSearchSrc = url.toString();
            }
        });
    }
}
