import { Controller } from "stimulus";

export default class extends Controller {
    element: HTMLLinkElement;

    scrollTo(event: Event) {
        event.preventDefault();
        const targetHash = this.element.getAttribute("href");
        const targetElement = document.querySelector(targetHash);
        window.scrollTo({
            'behavior': 'smooth',
            'left': 0,
            'top': (<HTMLElement>targetElement).offsetTop,
        });
    }
}
