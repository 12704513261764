import { Controller } from "stimulus";

export default class extends Controller {

    static targets = [
        "filterForm",
        "modal",
    ];

    filterFormTarget: HTMLFormElement;
    modalTarget: HTMLDivElement;

    showModal(event: Event): void {
        event.preventDefault();
        (<any>window).$(this.modalTarget).modal("show");
    }

    dismissModal(event: Event): void {
        event.preventDefault();
        (<any>window).$(this.modalTarget).modal("hide");
    }

    dismissAll(event: Event): void {
        this.filterFormTarget.setAttribute('action', this.data.get('dismiss-action'));
        this.filterFormTarget.setAttribute('method', 'post');
        this.filterFormTarget.setAttribute('data-remote', 'false');
        this.filterFormTarget.submit();
        this.dismissModal(event);
    }
}
