import { Controller } from "stimulus";

export default class extends Controller {

    static targets = [
        "dropzone",
    ];

    private dropzoneTargets: HTMLElement[];

    connect() {
        if ((window as any).dragAndDropProperties === undefined) {
            (window as any).dragAndDropProperties = null;
        }
    }

    dragstart(event: DragEvent): void {
        const eventTarget = this.originElement(event);

        if (eventTarget.dataset.dragAndDropAllowed === "false") {
            event.preventDefault();
            return;
        }

        // Chrome only allows `dataTransfer.getData` access in drop
        // events. To make the namespace validation work we need to
        // use a window object instead.
        (window as any).dragAndDropProperties = {
            id: eventTarget.dataset.dragAndDropId,
            label: eventTarget.dataset.dragAndDropLabel,
            namespace: eventTarget.dataset.dragAndDropNamespace,
            dropzoneId: eventTarget.dataset.dragAndDropDropzoneId,
            originElement: eventTarget
        };

        const label = eventTarget.dataset.dragAndDropLabel;
        event.dataTransfer.setDragImage(this.dragElement(event, label), 0, 0);
        event.dataTransfer.effectAllowed = (this.data.get("effectAllowed") as any || "copy");
    }

    drag(event: DragEvent): void {
        this.originElement(event).classList.add('drag-and-drop__active-origin');
    }

    dragover(event: DragEvent): void {
        event.preventDefault();
        if (this.isNotDraggableElement(event)) return;
        if (this.namespaceMismatch(event)) return;
        if (this.originElementAlreadyMemberOfDropzone(event)) return;

        event.dataTransfer.dropEffect = (this.data.get("dropEffect") as any || "copy");

        this.focusedDropzoneElement(event).classList.add("drag-and-drop__active-dropzone");
    }

    dragleave(event: DragEvent): void {
        this.dropzoneTargets.forEach((element) => {
            (element as HTMLElement).classList.remove("drag-and-drop__active-dropzone");
        });
    }

    drop(event: DragEvent): void {
        event.preventDefault();
        if (this.isNotDraggableElement(event)) return;
        if (this.namespaceMismatch(event)) return;
        if (this.originElementAlreadyMemberOfDropzone(event)) return;

        const dropzone = this.focusedDropzoneElement(event);
        dropzone.classList.remove("drag-and-drop__active-dropzone");

        const dragAndDropProperties = (window as any).dragAndDropProperties;
        dropzone.dispatchEvent(new CustomEvent('drag-and-drop-dropped', {
            bubbles: true,
            detail: {
                id: dragAndDropProperties.id,
                label: dragAndDropProperties.label,
                namespace: dragAndDropProperties.namespace,
                dropzoneId: dragAndDropProperties.dropzoneId,
                originElement: dragAndDropProperties.originElement,
                dropzoneElement: dropzone
            }
        }));
    }

    dragend(event: DragEvent): void {
        (window as any).dragAndDropProperties = null;
        this.originElement(event).classList.remove('drag-and-drop__active-origin');
        document.body.removeChild(document.getElementById("drag-and-drop-element"));
    }

    private originElement(event: DragEvent): HTMLElement {
        return (event.target as HTMLElement).closest("[draggable='true']");
    }

    private focusedDropzoneElement(event: DragEvent): HTMLElement {
        return (event.target as HTMLElement).closest("[data-target*='drag-and-drop.dropzone']");
    }

    private dragElement(event, label): HTMLDivElement {
        const element = document.createElement("div");
        element.id = "drag-and-drop-element";
        element.classList.add("draggable-element");
        element.innerHTML = label;
        element.style.top = "-9999px"; // hide from viewport
        document.body.appendChild(element);

        return element;
    }

    private isNotDraggableElement(event) {
        return (window as any).dragAndDropProperties === null ? true : false;
    }

    private namespaceMismatch(event) {
        const dropzoneNamespace = this.focusedDropzoneElement(event).dataset.dragAndDropNamespace;
        const originElementNamespace = (window as any).dragAndDropProperties.namespace;
        return dropzoneNamespace !== originElementNamespace;
    }

    private originElementAlreadyMemberOfDropzone(event) {
        const dropzoneId = this.focusedDropzoneElement(event).dataset.dragAndDropDropzoneId;
        const originElementDropzoneId = (window as any).dragAndDropProperties.dropzoneId;
        return originElementDropzoneId === dropzoneId;
    }
}
