import { Controller } from 'stimulus';
import Tracker from "../util/tracker";

export default class extends Controller {
    static targets = ["toggle"];

    toggleTargets: HTMLSelectElement[];

    connect() {
        this.initTheme();
        this.watchForSystemChanges();

        document.documentElement.addEventListener("turbo:load", this.initTheme.bind(this));
    }

    updateTheme(e) {
        const newTheme = e.target.value;
        const newDataTheme = this.getDataTheme(newTheme);

        this.setDataTheme(newDataTheme);
        localStorage.setItem("theme", newTheme);
        Tracker.track("Selected Theme", {
            "Source": "Translation Center",
            "Theme": newTheme,
        });
    }

    private initTheme() {
        let theme = localStorage.getItem("theme");

        if (theme === null) {
            theme = "auto";
            localStorage.setItem("theme", theme);
        }
        const dataTheme = this.getDataTheme(theme);
        this.initializeToggleValues();
        this.setDataTheme(dataTheme);

        return true;
    }

    private initializeToggleValues() {
        const theme = localStorage.getItem("theme");

        this.toggleTargets.forEach(toggle => {
            toggle.value = theme;
        });
    }

    private getDataTheme(theme) {
        let dataTheme;

        if (theme === "auto") {
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme:dark)").matches) {
                dataTheme = "dark";
            } else {
                dataTheme = "light";
            }
        } else {
            dataTheme = theme;
        }

        return dataTheme;
    }

    private setDataTheme(dataTheme) {
        document.documentElement.dataset.theme = dataTheme;
    }

    /* istanbul ignore next */
    private watchForSystemChanges() {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
            if (this.useSystemSettings()) {
                if (event.matches) {
                    this.setDataTheme("dark");
                } else {
                    this.setDataTheme("light");
                }
            }
        });
    }

    private useSystemSettings() {
        return localStorage.getItem("theme") === "auto";
    }
}
