export default class StringUtils {

    static idFromString(str: string) {
        let h;

        for(let i = 0; i < str.length; i++) {
            h = Math.abs(Math.imul(31, h) + str.charCodeAt(i)) | 0;
        }

        return h;
    }
}

