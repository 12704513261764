import { Controller } from "stimulus";

export default class extends Controller {
    private mixpanelDistinctIdFieldTarget: HTMLInputElement;

    static targets = ["mixpanelDistinctIdField"];

    populateMixpanelDistinctId() {
        if ((<any>window).mixpanel) {
            if (typeof (<any>window).mixpanel.get_distinct_id === "function") {
                this.mixpanelDistinctIdFieldTarget.value = this.mixpanelDistinctId;
            } else {
                console.warn("Could not get Mixpanel Distinct ID");
            }
        }
    }

    private get mixpanelDistinctId(): string {
        return (<any>window).mixpanel.get_distinct_id();
    }
}
