import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        (<any>window).$(this.element).datetimepicker({
            icons: {
                "time": "material-icons material-icons--schedule",
                "date": "material-icons material-icons--date_range",
                "previous": "material-icons material-icons--arrow_back",
                "next": "material-icons material-icons--arrow_forward",
                "up": "material-icons material-icons--keyboard_arrow_up",
                "down": "material-icons material-icons--keyboard_arrow_down"
            },
            format: this.data.get("format"),
            locale: this.data.get("locale") || "en",
            timeZone: this.data.get("timezone"),
            sideBySide: true,
        });
    }

    disconnect() {
        this.dateTimePicker.destroy();
    }

    show() {
        this.dateTimePicker.show();
    }

    private get dateTimePicker() {
        return (<any>window).$(this.element).data("DateTimePicker");
    }
}
