import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        "field",
        "label"
    ];

    private fieldTarget: HTMLInputElement;
    private labelTarget: HTMLElement;

    private hasLabelTarget: boolean;

    set(event: Event): void {
        event.preventDefault();
        const targetDataset = (event.currentTarget as HTMLElement).dataset;

        this.fieldTarget.value = targetDataset.fieldValue;
        this.fieldTarget.dispatchEvent(new CustomEvent("change", { bubbles: true }));

        if (this.hasLabelTarget) {
            this.labelTarget.innerHTML = targetDataset.label;
        }
    }
}
