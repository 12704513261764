import { Chart, ChartData } from 'chart.js';
import { Controller } from "stimulus";
import Sentry from "@utils/sentry";
import Spinner from "../util/spinner";

export default class extends Controller {
    static targets = ["chartCanvas", "spinnerContainer"];

    element: HTMLDivElement;
    spinner: Spinner;
    autopilotEnabled: boolean;

    chartCanvasTarget: HTMLCanvasElement;
    spinnerContainerTarget: HTMLDivElement;

    private readonly COLORS = {
        blue: '#0091EB',
        lightGray: '#DADADA',
        darkGray: '#7C7C7C',
    };

    connect() {
        this.spinner = new Spinner(this.spinnerContainerTarget);
        this.spinner.start();
        this.autopilotEnabled = this.data.get("autopilot-enabled") === "true";
        fetch(this.data.get("url"), { credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                this.spinner.stop();
                this.showChart(data);
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    showChart(data) {
        const chartData: ChartData = {
            labels: data.x_labels,
            datasets: this.datasets(data),
        };
        const config = {
            type: 'line',
            data: chartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                // Can't just just `stacked: true` like the docs say
                scales: {
                    yAxes: [{
                        stacked: true,
                    }]
                },
                animation: {
                    duration: 750,
                },
            }
        };
        const chart: Chart = new Chart(this.chartCanvasTarget.getContext("2d"), config);
        chart.update();
    }

    private datasets(data): any {
        let chartData: any;
        if (this.autopilotEnabled) {
            chartData = [
                {
                    label: this.data.get("user-label"),
                    fill: true,
                    backgroundColor: this.COLORS.blue,
                    pointBackgroundColor: this.COLORS.blue,
                    borderColor: this.COLORS.blue,
                    pointHighlightStroke: this.COLORS.blue,
                    borderCapStyle: 'butt',
                    data: data.user_stats,
                },
                {
                    label: this.data.get("translation-memory-label"),
                    fill: true,
                    backgroundColor: this.COLORS.darkGray,
                    pointBackgroundColor: this.COLORS.darkGray,
                    borderColor: this.COLORS.darkGray,
                    pointHighlightStroke: this.COLORS.darkGray,
                    borderCapStyle: 'butt',
                    data: data.translation_memory_stats,
                },
                {
                    label: this.data.get("machine-translation-label"),
                    fill: true,
                    backgroundColor: this.COLORS.lightGray,
                    pointBackgroundColor: this.COLORS.lightGray,
                    borderColor: this.COLORS.lightGray,
                    pointHighlightStroke: this.COLORS.lightGray,
                    borderCapStyle: 'butt',
                    data: data.machine_translation_stats,
                },
            ];
        } else {
            chartData = [
                {
                    label: this.data.get("user-label"),
                    fill: true,
                    backgroundColor: this.COLORS.blue,
                    pointBackgroundColor: this.COLORS.blue,
                    borderColor: this.COLORS.blue,
                    pointHighlightStroke: this.COLORS.blue,
                    borderCapStyle: 'butt',
                    data: data.user_stats,
                },
            ];
        }

        return chartData;
    }
}
