import { Controller } from "stimulus";
import { i18n } from "../../i18n/config";
import Sentry from "@utils/sentry";
import DomUtils from "@utils/dom_utils";

interface Marker {
    markerId?: string;
    markerX: number;
    markerY: number;
    markerWidth: number;
    markerHeight: number;
    markerTranslationKeyId: string;
    markerTranslationKeyName: string;
    markerRemove: string;
    markerContent: string;
    markerEditorLink?: string;
}

export default class extends Controller {

    static targets = [
        "name",
        "description",
        "submitButton",
        "revertButton",
        "screenshotCanvas",
        "screenshotContainer",
    ];

    private markers: Array<Marker>;
    private initialMarkers: Array<Marker>;

    private nameTarget: HTMLInputElement;
    private descriptionTarget: HTMLInputElement;
    private submitButtonTarget: HTMLInputElement;
    private revertButtonTarget: HTMLInputElement;
    private screenshotCanvasTarget: HTMLElement;
    private screenshotContainerTarget: HTMLElement;

    connect() {
        this.markers = [];
        this.initialMarkers = [];

        this.setInitialButtonState();
        this.handleNameAndDescriptionEvents();
        this.updateMarkers(false, true);
    }

    initializeMarkers() {
        this.updateMarkers();
        this.setInitialMarkersState();
    }

    updateMarkers(noFocus = false, initial = false) {
        window.requestAnimationFrame(() => {
            this.generateMarkersObjectFromDom(initial);
            this.refreshMarkersInDom(noFocus);
        });
    }

    updateMarkerFromEvent(e: CustomEvent) {
        this.updateMarkers(e.detail.noFocus, false);
    }

    removeMarker(event: Event) {
        event.preventDefault();
        const target = event.target as HTMLElement;
        const marker = target.parentNode as HTMLElement;

        if (marker.dataset.markerId) {
            marker.classList.add("screenshot-marker--hidden");
            marker.dataset.markerRemove = "true";
        } else {
            this.screenshotCanvasTarget.removeChild(target.parentNode);
        }

        this.updateMarkers(true, false);
        this.enableButtons();
    }

    revertToInitialMarkersState(event) {
        if (!this.buttonIsLocked(this.revertButtonTarget)) {
            this.markers = this.initialMarkers;
            this.refreshMarkersInDom();
            this.updateMarkers();
            this.disableButtons();
        }
    }

    saveScreenshot(event) {
        if (!this.buttonIsLocked(this.submitButtonTarget)) {
            this.disableButtons();

            const formData = new FormData();
            formData.append("screenshot[name]", this.nameTarget.value);
            formData.append("screenshot[description]", this.descriptionTarget.value);
            const validMarkers = this.validMarkers();
            if (validMarkers.length > 0) {
                validMarkers.forEach(marker => {
                    delete marker.markerModalUrl;
                    delete marker.markerContent;
                });
                formData.append("screenshot[markers]", JSON.stringify(validMarkers));
            }
            formData.append("_method", "patch");

            fetch(
                this.data.get("update-path"),
                {
                    method: "POST",
                    headers: this.requestHeaders(),
                    body: formData,
                    credentials: "same-origin",
                }
            ).then((response) => {
                if (response.ok) {
                    return response.text().then((html) => {
                        return Promise.resolve({ success: true, body: html });
                    });
                } else {
                    return response.text().then((html) => {
                        return Promise.resolve({ success: false, body: html });
                    });
                }
            }).then((data) => {
                this.handleResponse(data);
            }).catch(error => {
                this.showFlashMessage(i18n.t("screenshots.update.error"), "error");
                Sentry.notify(error);
            });
        }
    }

    private markerElements(): any {
        return Array.from(document.getElementsByClassName("screenshot-marker"));
    }

    private generateMarkersObjectFromDom(initial = false) {
        this.markers = [];

        this.markerElements().forEach((marker) => {
            const markerObject = {
                markerX: Number(marker.dataset.markerX),
                markerY: Number(marker.dataset.markerY),
                markerWidth: Number(marker.dataset.markerWidth),
                markerHeight: Number(marker.dataset.markerHeight),
                markerTranslationKeyId: marker.dataset.markerTranslationKeyId,
                markerTranslationKeyName: marker.dataset.markerTranslationKeyName,
                markerRemove: marker.dataset.markerRemove,
                markerModalUrl: marker.dataset.markerModalUrl,
                markerContent: marker.dataset.markerContent,
                markerEditorLink: marker.dataset.markerEditorLink
            };

            if (marker.dataset.markerId) {
                const markerIdObject = { markerId: marker.dataset.markerId };
                const markerEditorLinkObject = { markerEditorLink: marker.dataset.markerEditorLink };

                this.markers.push(Object.assign(markerObject, markerIdObject, markerEditorLinkObject));
            } else {
                this.markers.push(markerObject);
            }

            if (initial) {
                this.setInitialMarkersState();
            }
        });
    }

    private removeOldMarkers() {
        this.markerElements().forEach((marker) => {
            this.screenshotCanvasTarget.removeChild(marker);
        });
    }

    private refreshMarkersInDom(noFocus = false) {
        this.removeOldMarkers();

        this.markers.forEach((marker) => {
            this.dispatchCreateMarkerDomElement(marker, noFocus);
        });
    }

    private dispatchCreateMarkerDomElement(marker, noFocus: boolean) {
        const customEvent = new CustomEvent('create-marker-dom-element', {
            bubbles: true,
            detail: { marker: marker, noFocus }
        });
        this.screenshotCanvasTarget.dispatchEvent(customEvent);
    }

    private setInitialMarkersState() {
        this.initialMarkers = this.markers;
    }

    private setInitialButtonState() {
        this.disableButton(this.submitButtonTarget);
        this.disableButton(this.revertButtonTarget);
    }

    private disableButton(target) {
        target.classList.add("disabled");
    }

    private enableButton(target) {
        target.classList.remove("disabled");
    }

    private disableButtons() {
        this.disableButton(this.submitButtonTarget);
        this.disableButton(this.revertButtonTarget);
    }

    enableButtons() {
        this.enableButton(this.submitButtonTarget);
        this.enableButton(this.revertButtonTarget);
    }

    private buttonIsLocked(target) {
        return ~target.className.indexOf("disabled");
    }

    private handleNameAndDescriptionEvents() {
        [this.nameTarget, this.descriptionTarget].forEach((target) => {
            target.addEventListener("keyup", () => {
                this.enableButton(this.submitButtonTarget);
            });
        });
    }

    private validMarkers() {
        const validMarkers = [];
        for (const marker of this.markers) {
            if (marker.markerTranslationKeyId) { validMarkers.push(marker); }
        }
        return validMarkers;
    }

    private requestHeaders() {
        const csrfTag = (<HTMLMetaElement>document.querySelector('meta[name="csrf-token"]'));
        const headers = {};
        headers['X-Requested-With'] = 'XMLHttpRequest';

        if (csrfTag) { headers["X-CSRF-Token"] = csrfTag.content; }

        return headers;
    }

    private handleResponse(data) {
        this.disableButtons();

        if (data.success) {
            this.updateMarkersStateFromResponseData(data);
            this.showFlashMessage(i18n.t("screenshots.update.successful"), "success");
        } else {
            this.showFlashMessage(i18n.t("screenshots.update.error"), "error");
        }
    }

    private updateMarkersStateFromResponseData(data) {
        this.markers = JSON.parse(data.body);
        this.setInitialMarkersState();
        this.refreshMarkersInDom();
    }

    private showFlashMessage(message, name) {
        this.removeOldFlashMessages();

        const flashMessage = document.createElement("div");
        flashMessage.setAttribute("id", "flash-messages");
        flashMessage.setAttribute("class", "screenshots-edit__flash-message");
        flashMessage.setAttribute("data-role", "interaction-messages");
        const screenshotContainerWidth = this.screenshotContainerTarget.offsetWidth;
        const borderAndPadding = 28;
        flashMessage.setAttribute("style", `width: ${screenshotContainerWidth - borderAndPadding}px;`);

        const alertDiv = document.createElement("div");
        alertDiv.setAttribute("id", `flash_${name}`);
        alertDiv.setAttribute("class", `alert alert--banner alert--inline fade in alert-${name}`);

        const alertStatusIcon = document.createElement("span");
        alertStatusIcon.setAttribute("class", "alert__status-icon");

        const materialIcons = document.createElement("i");
        materialIcons.setAttribute("class", "material-icons");

        if (name === "success") { materialIcons.innerText = "check"; }
        if (name === "error") { materialIcons.innerText = "error"; }

        alertStatusIcon.appendChild(materialIcons);
        alertDiv.appendChild(alertStatusIcon);
        flashMessage.appendChild(alertDiv);

        const messageText = document.createTextNode(message);
        alertDiv.appendChild(messageText);

        const alertCloseBtn = document.createElement("a");
        alertCloseBtn.setAttribute("class", "alert__close-btn");
        alertCloseBtn.setAttribute("data-dismiss", "alert");
        const materialIconClose = document.createElement("i");
        materialIconClose.setAttribute("class", "material-icons");
        materialIconClose.innerText = "close";
        alertCloseBtn.appendChild(materialIconClose);
        alertDiv.appendChild(alertCloseBtn);

        this.screenshotContainerTarget.insertBefore(flashMessage, this.screenshotCanvasTarget);
    }

    private removeOldFlashMessages() {
        document.querySelectorAll("[data-role='interaction-messages']").forEach((el) => {
            DomUtils.remove(el);
        });
    }
}
