import { Controller } from "stimulus";
import { i18n } from "../i18n/config";

export default class extends Controller {
    static targets = [
        "tag",
        "moreLinkContainer",
        "moreLink",
    ];

    private tagTargets: HTMLElement[];
    private moreLinkContainerTarget: HTMLElement;
    private moreLinkTarget: HTMLLinkElement;

    private MAX_TAGS = 10;

    connect() {
        const tagCount = this.tagTargets.length;

        if (tagCount > this.MAX_TAGS) {
            this.moreLinkTarget.innerText = i18n.t(
                `editor.tags.and_count_more`, {
                    count: tagCount - this.MAX_TAGS,
                }
            );

            this.moreLinkContainerTarget.classList.remove("hidden");

            this.tagTargets.forEach((tag, index) => {
                if (index >= this.MAX_TAGS) {
                    tag.classList.add("hidden");
                }
            });
        }
    }

    showRemainingTags(event: Event) {
        event.preventDefault();

        this.tagTargets.forEach((tag) => {
            tag.classList.remove("hidden");
        });

        this.moreLinkContainerTarget.classList.add("hidden");
    }
}
