import DomUtils from '@utils/dom_utils';
import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        "checkbox",
        "container",
    ];

    private checkboxTarget: HTMLInputElement;
    private containerTarget: HTMLDivElement;
    private hasCheckboxTarget: boolean;

    connect() {
        this.toggleDueDate();
    }

    toggleDueDate() {
        if(!this.hasCheckboxTarget) {
            return;
        }

        if (this.checkboxTarget.checked) {
            DomUtils.showElement(this.containerTarget);
        } else {
            DomUtils.hideElement(this.containerTarget);
        }
    }
}
