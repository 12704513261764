import { Controller } from 'stimulus';
import { i18n } from "../../i18n/config";

export default class extends Controller {
    static targets = [
        'whatBringsRadio',
        'positionRadio',
        'howManageRadio',
        'whatBringsFormGroup',
        'positionFormGroup',
        'howManageFormGroup',
        'form',
        'submit',
    ];

    whatBringsRadioTargets: HTMLInputElement[];
    positionRadioTargets: HTMLInputElement[];
    howManageRadioTargets: HTMLInputElement[];
    whatBringsFormGroupTarget: HTMLElement;
    positionFormGroupTarget: HTMLElement;
    howManageFormGroupTarget: HTMLElement;
    formTarget: HTMLFormElement;
    submitTarget: HTMLButtonElement;

    submitted: boolean;

    submit(e: Event) {
        this.submitted = true;
        e.preventDefault();
        if (this.validate()) this.formTarget.submit();
    }

    validate(): boolean {
        if (!this.submitted) return true;

        this.removeValidationBoxes();
        if (!this.allRequiredSelected()) {
            this.showValidationBoxes();
            return false;
        } else {
            return true;
        }
    }

    private allRequiredSelected(): boolean {
        return this.whatBringsSelected() && this.positionSelected() && this.howManageSelected();
    }

    private whatBringsSelected(): boolean {
        return this.whatBringsRadioTargets.some(el => el.checked);
    }

    private positionSelected(): boolean {
        return this.positionRadioTargets.some(el => el.checked);
    }

    private howManageSelected(): boolean {
        return this.howManageRadioTargets.some(el => el.checked);
    }

    private removeValidationBoxes() {
        this.removeValidationBox(this.whatBringsFormGroupTarget);
        this.removeValidationBox(this.positionFormGroupTarget);
        this.removeValidationBox(this.howManageFormGroupTarget);
    }

    private showValidationBoxes() {
        if (!this.whatBringsSelected()) this.showValidationBox(this.whatBringsFormGroupTarget);
        if (!this.positionSelected()) this.showValidationBox(this.positionFormGroupTarget);
        if (!this.howManageSelected()) this.showValidationBox(this.howManageFormGroupTarget);
    }

    private removeValidationBox(formGroup: HTMLElement) {
        formGroup.classList.remove('has-error');
        const existingHelpBlock = formGroup.querySelector('.help-block');
        if (existingHelpBlock) existingHelpBlock.remove();
    }

    private showValidationBox(formGroup: HTMLElement) {
        formGroup.append(this.validationBoxElement);
        formGroup.classList.add('has-error');
    }

    private get validationBoxElement(): HTMLElement {
        const validationBoxElement = document.createElement('span');
        validationBoxElement.classList.add('help-block', 'error', 'w-100p');
        validationBoxElement.innerHTML = i18n.t("signups.form.select_one_answer");
        return validationBoxElement;
    }
}
