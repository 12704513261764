import { Controller } from 'stimulus';
import { i18n } from "../i18n/config";

export default class extends Controller {
    static targets = [
        "translation",
    ];

    element: HTMLElement;
    private translationTarget: HTMLElement;
    private hasTranslationTarget: boolean;

    connect() {
        this.update();
    }

    update() {
        const count = Number(this.element.dataset.count);
        const total = Number(this.data.get("total"));
        const key = (this.data.has("all-key") && count === total) ? this.data.get("all-key") : this.data.get("key");

        if (this.hasTranslationTarget) {
            this.translationTarget.classList.toggle("hidden", count === 0);
            this.translationTarget.innerHTML = i18n.t(key, { count: count, total: total });
        }
    }
}
