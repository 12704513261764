import { Controller } from "stimulus";

export default class extends Controller {

    static targets = [ 'hint' ];

    private hintTarget: HTMLLIElement;
    private hidden: boolean;
    private classList: string;

    connect() {
        this.classList = this.hintTarget.classList.value;
        this.hidden = (this.data.get("staticValue") === this.data.get("startingValue"));
        this.toggleVisibility();
    }

    determineVisibility(event: Event): void {
        const targetDataset = (event.currentTarget as HTMLElement).dataset;
        this.hidden = (targetDataset.fieldValue === this.data.get("staticValue"));
        this.toggleVisibility();
    }

    private toggleVisibility(): void {
        if (this.hidden) {
            this.hintTarget.classList.value = ("hidden");
        } else {
            this.hintTarget.classList.value = this.classList;
        }
    }

}
