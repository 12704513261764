import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        const localeIdInput = this.element.querySelector(`[name="${this.jobOrJobTemplateFormQuerySelector}[id][${this.localeIdentifier}]"]`) as HTMLInputElement;
        localeIdInput.name = localeIdInput.name.replace("[id]", `[${this.counter}]`);

        const localeCodeInput = this.element.querySelector(`[name="${this.jobOrJobTemplateFormQuerySelector}[id][locale_code]"]`) as HTMLInputElement;

        if (localeCodeInput) {
            localeCodeInput.name = localeCodeInput.name.replace("[id]", `[${this.counter}]`);
        }

        const jobLocaleIdInput = this.element.querySelector(`[name="${this.jobOrJobTemplateFormQuerySelector}[id][id]"]`) as HTMLInputElement;
        if (jobLocaleIdInput) {
            jobLocaleIdInput.name = jobLocaleIdInput.name.replace("[id]", `[${this.counter}]`);
        }
        localeIdInput.value = this.localeId;
    }

    remove(e: Event) {
        e.preventDefault();
        const parent = this.element.parentElement;
        this.element.remove();

        const event = new Event("updateLocales", { bubbles: true });
        parent.dispatchEvent(event);
    }

    private get localeId(): string {
        return this.data.get("localeId");
    }

    private get counter(): number {
        return parseInt(this.data.get("counter"));
    }

    private get jobOrJobTemplateFormQuerySelector(): string {
        switch (this.data.get('recordType')) {
            case 'Job':
                return 'job[job_locales]';
                break;
            case 'JobTemplate':
                return 'job_template[job_template_locales]';
                break;
            case 'GlobalJobTemplate':
                return 'job_template[job_template_locales]';
                break;
            default:
                console.error('Cant set form query selector - record type not handled');
                return '';
                break;
        }
    }

    private get localeIdentifier(): string {
        switch (this.data.get('recordType')) {
            case 'Job':
                return 'locale_id';
                break;
            case 'JobTemplate':
                return 'locale_name';
                break;
            case 'GlobalJobTemplate':
                return 'locale_name_and_code';
                break;
            default:
                console.error('Cant set locale identifier selector - record type not handled');
                return '';
                break;
        }
    }
}
