/*

    Use cases for this spinner component:

    Add a spinner to some element (will add a spinner without doing much else)

        div data-controller="util--spinner"

    Add a spinner component, but start spinning based on some action:

        div data-controller="util--spinner" data-util--spinner-start-spinning="false"
            button data-action="util--spinner#startSpinning"

    When using an event you usually want to have a different target for the actual spinner, this can be accomplished by adding a target element:

        div(
            data-controller=" \
                util--spinner \
                some-controller \
            "
            data-action=" \
                some-loading-event->util--spinner#startSpinning \
                some-finished-loading-event->util--spinner#stopSpinning \
            "
        )
            button data-action="some-controller#someAsyncAction"
            div data-target="util--spinner.targetElement"

*/

import { Controller } from "stimulus";
import Spinner from "../../util/spinner";

export default class extends Controller {

    static targets = [
        'targetElement'
    ];

    private spinner: Spinner;
    private targetElementTarget: HTMLElement;
    private hasTargetElementTarget: boolean;

    connect() {
        (<HTMLElement>this.spinnerTargetElement()).style.position = "relative";
        this.spinner = new Spinner(
            this.spinnerTargetElement(),
            (this.data.get("flavor") || "default")
        );

        if (this.data.get("startSpinning") !== "false") {
            this.spinner.start();
        }
    }

    disconnect() {
        this.spinner.destroy();
    }

    startSpinning(): void {
        this.spinner.start();
    }

    stopSpinning(): void {
        this.spinner.stop();
    }

    private spinnerTargetElement(): any {
        return this.hasTargetElementTarget ? this.targetElementTarget : this.element;
    }
}
