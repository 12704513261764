import { Controller } from 'stimulus';
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = [
        "tab",
        "invite",
        "projectsDropdown",
        "teamsDropdown",
        "submitButton"
    ];

    private tabTargets: HTMLElement[];
    private inviteTargets: HTMLElement[];
    private projectsDropdownTargets: HTMLElement[];
    private teamsDropdownTargets: HTMLElement[];
    private submitButtonTarget: HTMLButtonElement;

    update(e: Event) {
        this.updateDropdowns(e);
        this.updateInvites();
        this.updateSubmitButton();
    }

    updateDropdowns(e: Event) {
        if (this.tabTargets.length === 0) return;

        const tab = this.tabTargets.find((tab) => tab.classList.contains("active")).dataset.tab;

        this.projectsDropdownTargets.forEach((dropdown) => dropdown.classList.toggle("hidden", tab === "teams"));
        this.teamsDropdownTargets.forEach((dropdown) => dropdown.classList.toggle("hidden", tab === "projects"));
    }

    updateSubmitButton() {
        const hasInvalidInvitations = this.inviteTargets.find((invite) => invite.dataset.invitationValid === "false");

        if (hasInvalidInvitations) {
            DomUtils.disableButton(this.submitButtonTarget);
        } else if (this.inviteListIsEmpty()) {
            DomUtils.disableButton(this.submitButtonTarget);
        } else {
            DomUtils.enableButton(this.submitButtonTarget);
        }
    }

    private updateInvites() {
        requestAnimationFrame(
            () => this.inviteTargets.forEach((invite) => invite.dispatchEvent(new CustomEvent('form-update')))
        );
    }

    private inviteListIsEmpty() {
        return this.inviteTargets.length === 0;
    }
}
