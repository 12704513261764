export default class Tooltip {
    private element: HTMLElement;
    private title: string;

    constructor(element: HTMLElement, title: string) {
        this.element = element;
        this.title = title;

        (<any>window).$(this.element).tooltip({
            title: this.title,
            trigger: "manual",
        });
    }

    show() {
        (<any>window).$(this.element).tooltip("show");
    }

    hide() {
        (<any>window).$(this.element).tooltip("hide");
    }

    destroy() {
        (<any>window).$(this.element).tooltip("destroy");
    }
}
