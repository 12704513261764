import Redirect from "@utils/redirect";
import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        this.element.addEventListener("turbo:submit-end", this.next);
    }

    disconnect() {
        this.element.removeEventListener("turbo:submit-end", this.next);
    }

    private next(event: CustomEvent) {
        if (event.detail.success) {
            const fetchResponse = event.detail.fetchResponse;
            const url = fetchResponse.response.url;

            if (fetchResponse.response.redirected) {
                window.history.pushState(
                    { turbo_frame_history: true },
                    "",
                    url
                );

                Redirect.go(url);
            }
        }
    }
}
