import { Controller } from "stimulus";

import { ModalContainer, RemoteModalContainer, FormInputsContainer } from '@utils/types';

export default class extends Controller {

    static targets = [
        "renameTeamModal",
        "editTeamModal",
        "deleteTeamModal"
    ];

    private renameTeamModalTarget: HTMLFormElement & ModalContainer & FormInputsContainer;
    private editTeamModalTarget: HTMLFormElement & RemoteModalContainer;
    private deleteTeamModalTarget: HTMLFormElement & ModalContainer;

    showRenameTeam(event: Event): void {
        event.preventDefault();
        this.renameTeamModalTarget.modal.show();
        this.renameTeamModalTarget.formInputs.focus();
    }

    showEditTeam(event: Event): void {
        event.preventDefault();
        this.editTeamModalTarget.remoteModal.show();
    }

    showDeleteTeam(event: Event): void {
        event.preventDefault();
        this.deleteTeamModalTarget.modal.show();
    }
}
