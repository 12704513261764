import { Controller } from "stimulus";
import StringUtils from "@utils/string_utils";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = [
        "template",
        "list",
        "item",
    ];

    private templateTarget: HTMLTemplateElement;
    private listTarget: HTMLElement;
    private itemTargets: HTMLElement[];

    add(event: CustomEvent) {
        const value = event.detail.value;
        this.listTarget.append(this.newItem(value));

        this.element.dispatchEvent(new CustomEvent('dynamic-list:added'));
    }

    remove(event: CustomEvent) {
        const value = event.detail.value;
        const item = this.itemTargets.find((itemTarget) => itemTarget.dataset.identifier === this.id(value));
        DomUtils.remove(item);

        this.element.dispatchEvent(new CustomEvent('dynamic-list:removed'));
    }

    private newItem(value): any {
        const template = this.copyTemplate();

        template.querySelectorAll("[data-template-content]").forEach((el) => {
            if (el.tagName === "INPUT") {
                (el as HTMLInputElement).value = value;
            } else {
                (el as HTMLElement).innerText = value;
            }
        });

        template.setAttribute("data-identifier", this.id(value));

        return template;
    }

    private copyTemplate(): HTMLElement {
        return this.templateTarget.content.cloneNode(true).firstChild as HTMLElement;
    }

    private id(value): string {
        return StringUtils.idFromString(value).toString();
    }
}
