import { Controller } from "stimulus";
import interact from "interactjs";

export default class extends Controller {

    static targets = [
        "screenshotCanvas",
        "screenshotImage",
    ];

    private screenshotCanvasTarget: HTMLElement;
    private screenshotImageTarget: HTMLElement;

    setScreenshotImageDimensions() {
        const image = this.screenshotImageTarget;
        const imageWidth = Math.floor(image.clientWidth);
        const imageHeight = Math.floor(image.clientHeight);
        (this.element as HTMLElement).style.height = imageHeight + "px";

        this.screenshotCanvasTarget.style.width = `${imageWidth}px`;
        this.screenshotCanvasTarget.style.height = `${imageHeight}px`;

        requestAnimationFrame(() => {
            this.handleScreenshotEvents();
        });
    }

    private handleScreenshotEvents() {
        interact(this.screenshotCanvasTarget).draggable({
            onmove: this.handleScreenshotDrag
        })
            .on('tap', (event) => {
                if(event.target.nodeName === "IMG") {
                    this.dispatchNewMarkerEvent(event);
                }
            });
    }

    private handleScreenshotDrag(event) {
        const target = event.target;
        const x = (Number(target.getAttribute('data-screenshot-x')) || 0) + event.dx;
        const y = (Number(target.getAttribute('data-screenshot-y')) || 0) + event.dy;

        target.style.webkitTransform =
            target.style.transform = `translate(${x}px, ${y}px)`;

        target.setAttribute('data-screenshot-x', Math.floor(x));
        target.setAttribute('data-screenshot-y', Math.floor(y));
    }

    private dispatchNewMarkerEvent(event) {
        const rectangle = event.target.getBoundingClientRect();
        const imageX = event.clientX - rectangle.left;
        const imageY = event.clientY - rectangle.top;

        const createMarkerDomEvent = new CustomEvent('create-marker-dom-element', {
            bubbles: true,
            detail: {
                marker: {
                    markerX: Math.floor(imageX),
                    markerY: Math.floor(imageY),
                    markerWidth: 50,
                    markerHeight: 50,
                    markerTranslationKeyId: "",
                    markerTranslationKeyName: "",
                    markerRemove: ""
                }
            },
        });

        this.element.dispatchEvent(createMarkerDomEvent);
        this.dispatchEnableButtons();
    }

    private dispatchEnableButtons() {
        const customEvent = new CustomEvent('enable-buttons', { bubbles: true });
        this.element.dispatchEvent(customEvent);
    }
}
