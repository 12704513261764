import { ListResource } from "./list_view_controller";
import RemoteFormController from "./remote_form_controller";

export default class RemoteListFormController extends RemoteFormController {
    onSuccess(event?: CustomEvent) {
        super.onSuccess(event);

        const listResource: ListResource = event.detail.xhr.responseJSON;
        const eventName = `list-view-${listResource.action}-${listResource.resource_name}`;
        const newEvent = new CustomEvent(eventName, { detail: listResource });

        const listViewIdentifier = this.data.get("list-view-identifier") || "list-view";
        document.querySelectorAll(`[data-${listViewIdentifier}-model-name="${listResource.resource_name}"]`).forEach((element) => {
            element.dispatchEvent(newEvent);
        });
    }
}
