import { Controller } from "stimulus";

export default class ProjectSettingsController extends Controller {
    static targets = [
        "machineTranslationToggle",
        "autopilotToggle",
        "autopilotMachineTranslationToggle",
        "autopilotContainer",
        "smartSuggestContainer",
        "smartSuggestToggle",
        "smartSuggestMachineTranslationToggle",
        "branchingContainer",
        "branchingToggle",
    ];

    machineTranslationToggleTarget: HTMLInputElement;
    machineTranslationToggleTargets: HTMLInputElement[];
    hasMachineTranslationToggleTarget: boolean;

    autopilotToggleTarget: HTMLInputElement;
    autopilotToggleTargets: HTMLInputElement[];
    hasAutopilotToggleTarget: boolean;

    autopilotContainerTarget: HTMLElement;
    autopilotContainerTargets: HTMLElement[];
    hasAutopilotContainerTarget: boolean;

    autopilotMachineTranslationToggleTarget: HTMLInputElement;
    autopilotMachineTranslationToggleTargets: HTMLInputElement[];
    hasAutopilotMachineTranslationToggleTarget: boolean;

    smartSuggestMachineTranslationToggleTarget: HTMLInputElement;
    smartSuggestMachineTranslationToggleTargets: HTMLInputElement[];
    hasSmartSuggestMachineTranslationToggleTarget: boolean;

    smartSuggestContainerTarget: HTMLElement;
    smartSuggestContainerTargets: HTMLElement[];
    hasSmartSuggestContainerTarget: boolean;

    smartSuggestToggleTarget: HTMLInputElement;
    smartSuggestToggleTargets: HTMLInputElement[];
    hasSmartSuggestToggleTarget: boolean;

    branchingContainerTarget: HTMLElement;
    hasBranchingToggleTarget: boolean;

    branchingToggleTarget: HTMLInputElement;

    connect() {
        this.toggleAutopilotVisibility();
        this.toggleAutopilotMachineTranslationAvailability();
        this.toggleSmartSuggestVisibility();
        this.toggleSmartSuggestMachineTranslationAvailability();
        this.toggleBranchingVisibility();
    }

    toggleAutopilotVisibility() {
        if (this.hasAutopilotToggleTarget) {
            if (this.autopilotToggleTarget.checked) {
                this.autopilotContainerTarget.classList.remove("hidden");
            } else {
                this.autopilotContainerTarget.classList.add("hidden");
            }
        }
    }

    toggleAutopilotMachineTranslationAvailability() {
        if (this.hasAutopilotMachineTranslationToggleTarget) {
            if (this.machineTranslationEnabled) {
                this.autopilotMachineTranslationToggleTarget.disabled = false;
            } else {
                this.autopilotMachineTranslationToggleTarget.disabled = true;
            }
        }
    }

    toggleSmartSuggestVisibility() {
        if (this.hasSmartSuggestToggleTarget) {
            if (this.smartSuggestToggleTarget.checked) {
                this.smartSuggestContainerTarget.classList.remove("hidden");
            } else {
                this.smartSuggestContainerTarget.classList.add("hidden");
            }
        }
    }

    toggleSmartSuggestMachineTranslationAvailability() {
        if (this.hasSmartSuggestMachineTranslationToggleTarget) {
            if (this.machineTranslationEnabled) {
                this.smartSuggestMachineTranslationToggleTarget.disabled = false;
            } else {
                this.smartSuggestMachineTranslationToggleTarget.disabled = true;
            }
        }
    }

    toggleBranchingVisibility() {
        if (this.hasBranchingToggleTarget) {
            if (this.branchingToggleTarget.checked) {
                this.branchingContainerTarget.classList.remove("hidden");
            } else {
                this.branchingContainerTarget.classList.add("hidden");
            }
        }
    }

    get machineTranslationEnabled() {
        return this.machineTranslationToggleTarget.checked;
    }
}
