import { Controller } from "stimulus";
import { i18n } from "../../i18n/config";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {

    static targets = [
        "suggestableField",
    ];

    private suggestableFieldTarget: HTMLInputElement;
    private modalUrl: string;

    connect() {
        this.modalUrl = this.data.get("modal-url");
        this.handleSuggestFieldEvent();
    }

    private handleSuggestFieldEvent() {
        this.insertAddTranslationKeyButton();

        this.suggestableFieldTarget.addEventListener("keyup", () => {
            this.updateMarkerAttributes();
            this.removeOldButtons();
            this.insertAddTranslationKeyButton();
        });
    }

    private updateMarkerAttributes() {
        const screenshotMarker = this.screenshotMarker();
        const newValue = this.suggestableFieldValue();
        screenshotMarker.setAttribute("data-marker-translation-key-name", newValue);
    }

    private insertAddTranslationKeyButton() {
        const button = <HTMLAnchorElement>document.createElement("a");
        button.classList.add("screenshots-edit__add-translation-key");
        button.classList.add("btn");
        button.classList.add("btn-primary");
        button.classList.add("btn-xs");
        button.classList.add("modalized");
        button.setAttribute("title", i18n.t("screenshots.update.add_key"));
        button.setAttribute("href", this.modalURLWithParams());
        button.setAttribute("data-modal-flavor", "large-form");
        button.setAttribute("data-modal-submit-button-label", i18n.t("general.save"));
        button.setAttribute("data-role", "screenshots-edit-marker-addkey-button");
        this.addButtonIcon(button);
        this.suggestableFieldParent().appendChild(button);
    }

    private addButtonIcon(button) {
        const icon = document.createElement("i");
        icon.classList.add("material-icons");
        icon.innerHTML = "add";
        button.appendChild(icon);
    }

    private modalURLWithParams() {
        const modalUrl = this.modalUrl;
        const translationKeyName = this.suggestableFieldValue();
        const screenshotMarkerId = this.screenshotMarkerId();
        const translationContent = this.suggestableContentValue();

        let url = `${modalUrl}&name=${translationKeyName}&screenshot_marker_id=${screenshotMarkerId}`;
        // Avoid to long query parameter in the URL which is limited by the browser. The default translation for keys will be empty for very long translations
        if(translationContent && translationContent.length < 1000) {
            url += `&default_content=${encodeURIComponent(translationContent)}`;
        }

        return url;
    }

    private removeOldButtons() {
        this.suggestableFieldParent().querySelectorAll(".screenshots-edit__add-translation-key").forEach((el) => {
            DomUtils.remove(el);
        });
    }

    private screenshotMarker() {
        return <HTMLDivElement>document.getElementById(this.screenshotMarkerId());
    }

    private screenshotMarkerId() {
        return this.suggestableFieldTarget.getAttribute("data-screenshots--marker-suggestable-screenshot-marker-id");
    }

    private suggestableFieldValue() {
        return this.suggestableFieldTarget.value;
    }

    private suggestableContentValue() {
        return this.data.get("content");
    }

    private suggestableFieldParent() {
        return this.suggestableFieldTarget.parentElement;
    }
}
