import DataUtils from "@utils/data_utils";
import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['useCustomApi', 'customApi', 'token', 'repoId', 'repoIdLoader', 'repoName', 'gitSourceBranch', 'sourceBranchLoader', 'validateButton', 'modal', 'gitProvider', 'submitButton', 'branchInputRow'];

    private modalTarget: HTMLElement;
    private useCustomApiTarget: HTMLInputElement;
    private customApiTarget: HTMLInputElement;
    private tokenTarget: HTMLInputElement;
    private repoIdTarget: HTMLElement;
    private repoNameTarget: HTMLInputElement;
    private gitSourceBranchTarget: HTMLElement;
    private validateButtonTarget: HTMLInputElement;
    private repoIdLoaderTarget: HTMLElement;
    private sourceBranchLoaderTarget: HTMLElement;
    private gitProviderTarget: HTMLInputElement;
    private submitButtonTarget: HTMLButtonElement;
    private branchInputRowTarget: HTMLElement;

    private abort = new AbortController();
    private fetchInProgress = false;

    private hasModalTarget: boolean;
    private hasCustomApiTarget: boolean;
    private hasTokenTarget: boolean;

    connect() {
        if (this.hasModalTarget) {
            this.modalTarget.addEventListener('syntax-transition:transition-out', this.modalHideHandler.bind(this));
        }
        this.enableDisableCustomApi();
        const refreshRepos = this.data.get('refresh-repos') === 'true';
        if(refreshRepos){
            this.fetchRepositories();
        }
    }

    disconnect() {
        if (this.hasModalTarget) {
            this.modalTarget.removeEventListener('syntax-transition:transition-out', this.modalHideHandler.bind(this));
        }
    }

    modalHideHandler() {
        if (this.fetchInProgress) {
            this.abort.abort();
        }
    }

    setRequestStatus(running) {
        this.fetchInProgress = running;
        // enable/disable all inputs
        if (this.hasCustomApiTarget) {
            this.useCustomApiTarget.disabled = running;
            this.customApiTarget.disabled = running;
        }
        if (this.hasTokenTarget) {
            this.tokenTarget.disabled = running;
        }
        this.validateButtonTarget.disabled = running;
    }

    enableDisableCustomApi() {
        if (this.hasCustomApiTarget) {
            this.customApiTarget.disabled = !this.useCustomApiTarget.checked;
        }
    }

    fetchRepositories() {
        const provider = this.gitProviderTarget.value;
        const token = this.tokenValue();
        const useCustomApi = this.hasCustomApiTarget ? this.useCustomApiTarget.checked : false;
        const customApi = this.hasCustomApiTarget ? this.customApiTarget.value : '';
        if (useCustomApi && customApi === '') {
            return;
        }

        this.setRequestStatus(true);
        this.toggleSubmit();
        const url = this.data.get('get-repos-url');

        this.repoIdTarget.parentElement.classList.add('hidden');
        this.repoIdLoaderTarget.classList.remove('hidden');
        this.commonFetch(url, {
            sync: {
                id: this.data.get('id'),
                access_token: token,
                custom_api_endpoint: useCustomApi ? customApi : null,
                git_provider: provider
            }
        }).catch(e => {
            if (e.name == 'AbortError') {
                // do nothing
            } else {
                console.error("fetching repositories failed: ", e);
            }
        }).finally(() => {
            this.setRequestStatus(false);
            this.toggleSubmit();
        });
    }

    fetchBranches(event: CustomEvent) {
        const token = this.tokenValue();
        const repoId = event.detail.value;
        const repoName = event.detail.label;
        const provider = this.gitProviderTarget.value;
        this.repoNameTarget.value = repoName;
        if (repoId === '') {
            return;
        }
        const useCustomApi = this.hasCustomApiTarget ? this.useCustomApiTarget.checked : false;
        const customApi = this.hasCustomApiTarget ? this.customApiTarget.value : '';
        if (useCustomApi && customApi === '') {
            return;
        }

        this.setRequestStatus(true);
        this.toggleSubmit();
        const url = this.repoIdTarget.getAttribute('data-url');

        this.gitSourceBranchTarget.parentElement.classList.add('hidden');
        this.sourceBranchLoaderTarget.classList.remove('hidden');
        this.commonFetch(url, {
            sync: {
                id: this.data.get('id'),
                access_token: token,
                repo_id: repoId,
                repo_name: repoName,
                custom_api_endpoint: useCustomApi ? customApi : null,
                git_provider: provider
            }
        }).catch(e => {
            if (e.name == 'AbortError') {
                // do nothing
            } else {
                console.error("fetching branches failed: ", e);
            }
        }).finally(() => {
            this.setRequestStatus(false);
            this.toggleSubmit();
        });
    }

    validateConfiguration() {
        const useCustomApi = this.hasCustomApiTarget ? this.useCustomApiTarget.checked : false;
        const customApi = this.hasCustomApiTarget ? this.customApiTarget.value : '';
        const token = this.tokenValue();
        const repoId = this.repoIdFromDOM();
        const repoName = this.repoNameTarget.value;
        const gitSourceBranch = this.sourceBranchFromDOM();
        const url = this.validateButtonTarget.getAttribute('data-url');
        const provider = this.gitProviderTarget.value;

        this.commonFetch(url, {
            sync: {
                id: this.data.get('id'),
                access_token: token,
                repo_id: repoId,
                repo_name: repoName,
                git_source_branch: gitSourceBranch,
                custom_api_endpoint: useCustomApi ? customApi : null,
                git_provider: provider
            }
        }).catch(response => {
            console.error("validating configuration failed: ", response);
        });
    }

    toggleSubmit() {
        const disableClass = 'syn_btn--disabled';
        this.submitButtonTarget.classList.toggle(disableClass);
    }

    commonFetch(url, body) {
        return DataUtils.request(url, body, { parse: false }, { method: 'POST', signal: this.abort.signal })
            .then(response => response.text())
            .then(html => {
                (window as any).Turbo.renderStreamMessage(html);
            });
    }

    validateReposExistence() {
        this.disableSubmit();
        this.toggleLoadingState();
        const token = this.tokenValue();
        const repoName = this.repoNameTarget.value;
        const validationRoute = this.repoNameTarget.dataset.url;
        const useCustomApi = this.hasCustomApiTarget ? this.useCustomApiTarget.checked : false;
        const customApi = this.hasCustomApiTarget ? this.customApiTarget.value : '';
        const provider = this.gitProviderTarget.value;

        this.commonFetch(validationRoute, {
            sync: {
                id: this.data.get('id'),
                repo_name: repoName,
                access_token: token,
                git_provider: provider,
                custom_api_endpoint: useCustomApi ? customApi : null
            }
        }).catch(response => {
            console.error("validating repository failed: ", response);
        }).finally(() => {
            this.toggleLoadingState();
        });
    }

    validateBranchExistence() {
        this.disableSubmit();
        this.toggleLoadingState();
        const token = this.tokenValue();
        const repoName = this.repoNameTarget.value;
        const repoIdInput: HTMLInputElement = this.repoIdTarget as HTMLInputElement;
        const repoId = repoIdInput.value;
        const branchNameInput: HTMLInputElement = this.gitSourceBranchTarget as HTMLInputElement;
        const branchName = branchNameInput.value;
        const validationRoute = branchNameInput.dataset.url;
        const useCustomApi = this.hasCustomApiTarget ? this.useCustomApiTarget.checked : false;
        const customApi = this.hasCustomApiTarget ? this.customApiTarget.value : '';
        const provider = this.gitProviderTarget.value;

        this.commonFetch(validationRoute, {
            sync: {
                id: this.data.get('id'),
                repo_name: repoName,
                git_source_branch: branchName,
                repo_id: repoId,
                access_token: token,
                git_provider: provider,
                custom_api_endpoint: useCustomApi ? customApi : null
            }
        }).catch(response => {
            console.error("validating branch failed: ", response);
        }).finally(() => {
            this.toggleLoadingState();
        });

    }

    private repoIdFromDOM(){
        return this.repoIdTarget instanceof HTMLInputElement ? this.repoIdTarget.value : this.repoIdTarget.getAttribute('data-syntax-select-value');
    }

    private sourceBranchFromDOM(){
        return this.gitSourceBranchTarget instanceof HTMLInputElement ? this.gitSourceBranchTarget.value : this.gitSourceBranchTarget.getAttribute('data-syntax-select-value');
    }

    private tokenValue(){
        return this.hasTokenTarget ? this.tokenTarget.value : '';
    }

    private disableSubmit(){
        this.submitButtonTarget.classList.add('syn_btn--disabled');
    }

    private toggleLoadingState(){
        this.repoIdTarget.parentElement.classList.toggle('hidden');
        this.repoIdLoaderTarget.classList.toggle('hidden');
        this.branchInputRowTarget.classList.toggle('hidden');
        this.sourceBranchLoaderTarget.classList.toggle('hidden');
    }
}
