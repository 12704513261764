import { Controller } from "stimulus";
import DomUtils from "../util/dom_utils";

export default class extends Controller {
    private tooltip: any;
    private tooltipId: string;

    connect() {
        // @TODO: Get rid of jQuery
        this.tooltip = (<any>window).jQuery(this.element).tooltip({
            container: "body",
            title: this.title,
            html: this.hasHtmlBody,
            placement: this.placement,
            trigger: this.trigger,
            delay: { show: 250, hide: this.hideDelay }
        });
        this.tooltip.on("inserted.bs.tooltip", (_event: Event) => {
            this.tooltipId = this.element.getAttribute("aria-describedby");
        });
    }

    disconnect() {
        // Remove tooltips when removing the element to not have them stick around forever
        this.tooltip.tooltip("destroy");
        // @HACK: Fix tooltip removal
        // Removing tooltips bound to body container does not work reliably so we need to remove the element manually
        // This can hopefully be removed when moving away from Bootstrap 3
        if (this.tooltipId) {
            const orphanedTooltip = document.getElementById(this.tooltipId);
            if (orphanedTooltip) {
                DomUtils.remove(orphanedTooltip);
            }
        }
    }

    private get title(): string {
        if (this.hasHtmlBody) return this.tooltipHtmlBody;
        // We're relying on the regular 'title' attribute for its great semantics
        // and because it provides a native fallback in browsers already.
        return this.element.getAttribute("title");
    }

    private get placement(): string {
        return this.data.get("placement") || "top";
    }

    private get trigger(): string {
        return this.data.get("trigger") || undefined;
    }


    private get hasHtmlBody(): boolean {
        return !!this.tooltipHtmlBody;
    }

    private get tooltipHtmlBody(): string | null {
        return this.data.get('html');
    }

    private get hideDelay(): string {
        return this.data.get('hide-delay') || '100';
    }
}
