export type OnboardingStepVisibilityChangeCallback = (visibility: boolean)=> void;
export interface OnboardingCrossStepDependency {
    tourId: string;
    stepId: string;
    type: 'viewed';
}
export interface OnboardingStep {
    visibilityChangeCallbacks?: OnboardingStepVisibilityChangeCallback[];
    element?: HTMLElement;
    maxWidth?: number;
    done?: boolean;
    nextStepId?: string;
    dependencies?: (OnboardingCrossStepDependency)[];
}
export interface OnboardingSteps {
    [id: string]: OnboardingStep;
}
export interface OnboardingTour {
    steps: OnboardingSteps;
    activeStep: string;
    active: boolean;
}

class OnboardingService {
    private static ONBOARDING_CONFIG_LS_KEY = 'Phrase::onboardingConfig';
    private static INITIAL_TOURS_CONFIG: Record<string, OnboardingTour> = {
        1: {
            active: true,
            activeStep: '1',
            steps: {
                1: {
                    nextStepId: '2'
                },
                2: {
                    maxWidth: 200
                }
            }
        },
        2: {
            active: true,
            activeStep: '1',
            steps: {
                1: {
                    maxWidth: 340,
                    dependencies: [{ tourId: '3', stepId: '1', type: 'viewed' }]
                }
            }
        },
        3: {
            active: true,
            activeStep: '1',
            steps: {
                1: {
                    maxWidth: 360
                }
            }
        },
        4: {
            active: true,
            activeStep: '1',
            steps: {
                1: {
                    maxWidth: 200
                }
            }
        }
    };
    private static onboardingTours: Record<string, OnboardingTour> = JSON.parse(JSON.stringify(Object.assign(
        {},
        OnboardingService.INITIAL_TOURS_CONFIG,
        JSON.parse(localStorage.getItem(OnboardingService.ONBOARDING_CONFIG_LS_KEY) ?? '{}')
    )));

    private static changeStepVisibility(tourId: string, stepId: string, visibility: boolean) {
        this.getStep(tourId, stepId)?.visibilityChangeCallbacks.forEach(
            visibilityChangeCallback => visibilityChangeCallback(visibility)
        );
    }

    private static safeStepVisibilityHandler(tourId: string, stepId: string, visibilityChangeCallback: OnboardingStepVisibilityChangeCallback, visibility: boolean) {
        if (visibility) {
            const dependenciesResults = this.evaluateStepDependencies(tourId, stepId, visibilityChangeCallback);
            if (dependenciesResults.includes(false)) return;
        }
        return visibilityChangeCallback(visibility);
    }

    private static viewedDependencyHandler(tourId: string, stepId: string, visibilityChangeCallback: OnboardingStepVisibilityChangeCallback, dependencyVisibility: boolean) {
        if (!dependencyVisibility) {
            const dependenciesResults = this.evaluateStepDependencies(tourId, stepId, visibilityChangeCallback);
            if (dependenciesResults.includes(false)) return;
            visibilityChangeCallback(!dependencyVisibility);
        }
    }

    static _initialize() {
        Object.keys(this.onboardingTours).forEach(tourId =>
            Object.values(OnboardingService.getTour(tourId).steps)
                .forEach(step => step.visibilityChangeCallbacks = [])
        );
    }

    static registerStepComponent(
        tourId: string,
        stepId: string,
        visibilityChangeCallback: OnboardingStepVisibilityChangeCallback
    ) {
        const step = OnboardingService.getStep(tourId, stepId);
        step.visibilityChangeCallbacks.push(
            this.safeStepVisibilityHandler.bind(this, tourId, stepId, visibilityChangeCallback)
        );

        const tour = OnboardingService.getTour(tourId);

        if (tour.active === false || tour.activeStep !== stepId) return;

        const dependenciesResults = this.evaluateStepDependencies(tourId, stepId, visibilityChangeCallback);
        if (dependenciesResults.includes(false)) return;

        visibilityChangeCallback(true);
    }

    static unregisterStepComponent(
        tourId: string,
        stepId: string
    ) {
        const step = OnboardingService.getStep(tourId, stepId);
        step.visibilityChangeCallbacks = [];
    }

    static changeTour(tourId: string, prop: Partial<OnboardingTour>) {
        const tour = this.getTour(tourId);
        const oldActiveStep = tour.activeStep;

        Object.assign(tour, prop);

        if ('activeStep' in prop) {
            this.changeStepVisibility(tourId, oldActiveStep, false);
            if (prop.activeStep === void 0) {
                tour.active = false;
            } else if (prop.activeStep !== oldActiveStep) {
                this.changeStepVisibility(tourId, tour.activeStep, true);
            }
        }

        localStorage.setItem(OnboardingService.ONBOARDING_CONFIG_LS_KEY, JSON.stringify(this.onboardingTours));
    }

    static getTour(tourId: string) {
        return this.onboardingTours[tourId];
    }

    static getTourStepsOrdered(tourId: string) {
        const steps: { id: string; step: OnboardingStep }[] = [];
        let stepId = this.getFirstStepId(tourId);
        let step = this.getStep(tourId, stepId);
        do {
            steps.push({ id: stepId, step });
        } while (
            (stepId = step.nextStepId) &&
            (step = this.getStep(tourId, stepId))
        );
        return steps;
    }

    static getStep(tourId: string, stepId: string) {
        return this.onboardingTours[tourId].steps[stepId];
    }

    static getStepIndex(tourId: string, stepId: string) {
        let currentStepId = this.getFirstStepId(tourId);
        let i = 0;
        while (
            stepId !== currentStepId &&
            (currentStepId = this.getStep(tourId, currentStepId).nextStepId)
        ) ++i;

        return i;
    }

    static evaluateStepDependencies(tourId: string, stepId: string, visibilityChangeCallback: OnboardingStepVisibilityChangeCallback) {
        const step = this.getStep(tourId, stepId);
        if (!step.dependencies) return [];
        return step.dependencies.map(dependency => {
            switch (dependency.type) {
                case 'viewed': {
                    const tourActiveStepId = this.getTour(dependency.tourId).activeStep;
                    let wasDependencyViewed = false;
                    this.getTourStepsOrdered(dependency.tourId)
                        .some(({ id, step }) => {
                            if (id === tourActiveStepId) return true;
                            if (id === dependency.stepId) {
                                wasDependencyViewed = true;
                                return true;
                            }
                            return false;
                        });
                    if (!wasDependencyViewed) {
                        this.getStep(dependency.tourId, dependency.stepId).visibilityChangeCallbacks.push(
                            this.viewedDependencyHandler.bind(this, tourId, stepId, visibilityChangeCallback)
                        );
                    }
                    return wasDependencyViewed;
                }
            }
        });
    }

    static getFirstStepId(tourId: string) {
        return this.INITIAL_TOURS_CONFIG[tourId].activeStep;
    }

    static getFirstStep(tourId: string) {
        return this.onboardingTours[tourId].steps[this.getFirstStepId(tourId)];
    }
}

OnboardingService._initialize();
Object.freeze(OnboardingService);

export default OnboardingService;
