import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "tabRow",
        "dropdownMenu",
        "dropdownMenuContainer",
        "tabInRow",
        "tabInDropdownMenu",
    ];

    private tabRowTarget: HTMLUListElement;
    private dropdownMenuTarget: HTMLUListElement;
    private dropdownMenuContainerTarget: HTMLLIElement;
    private tabInRowTargets: HTMLLIElement[];
    private tabInDropdownMenuTargets: HTMLLIElement[];


    connect() {
        window.addEventListener("resize", () => {
            this.moveTabToRow();
            window.requestAnimationFrame(this.updateActiveStates.bind(this));
        });

        this.moveTabToDropdownMenu();
        window.requestAnimationFrame(this.updateActiveStates.bind(this));
    }

    private moveTabToDropdownMenu() {
        if (!this.tabInRowTargets[0]) { return; }

        const tabRowHeight = this.tabRowTarget.getBoundingClientRect().height;
        const firstTabInRowHeight = this.tabInRowTargets[0].getBoundingClientRect().height;

        if (tabRowHeight > firstTabInRowHeight) {
            const lastIndex = this.tabInRowTargets.length - 1;

            if (lastIndex < 0 || !this.tabInRowTargets[lastIndex]) { return; }

            const lastTabInRow = this.tabInRowTargets[lastIndex];

            lastTabInRow.dataset.target = "collapsible-tabs.tabInDropdownMenu";
            this.dropdownMenuTarget.insertBefore(lastTabInRow, this.dropdownMenuTarget.childNodes[0]);

            window.requestAnimationFrame(this.moveTabToDropdownMenu.bind(this));
        }
    }

    private moveTabToRow() {
        if (!this.tabInRowTargets[0]) { return; }

        const tabRowRect = this.tabRowTarget.getBoundingClientRect();
        const firstTabInRowRect = this.tabInRowTargets[0].getBoundingClientRect();

        if (tabRowRect.height <= firstTabInRowRect.height) {
            const totalTabsWidth =
                this.tabInRowTargets
                    .map(target => target.getBoundingClientRect().width)
                    .reduce((sum, width) => sum + width);

            const remainingSpace =
                tabRowRect.width -
                    totalTabsWidth -
                    this.dropdownMenuContainerTarget.getBoundingClientRect().width;

            if (remainingSpace > 2 * firstTabInRowRect.width) {
                if (!this.tabInDropdownMenuTargets[0]) { return; }

                const firstInDropdownMenu = this.tabInDropdownMenuTargets[0];

                firstInDropdownMenu.dataset.target = "collapsible-tabs.tabInRow";
                this.tabRowTarget.insertBefore(firstInDropdownMenu, this.dropdownMenuContainerTarget);

                window.requestAnimationFrame(this.moveTabToRow.bind(this));
            }
        } else {
            window.requestAnimationFrame(this.moveTabToDropdownMenu.bind(this));
        }
    }

    private updateActiveStates() {
        const isActive = target => target.classList.contains("active");

        if (this.tabInRowTargets.some(isActive)) {
            this.dropdownMenuContainerTarget.classList.remove("active");
        } else if (this.tabInDropdownMenuTargets.some(isActive)) {
            this.dropdownMenuContainerTarget.classList.add("active");
        }
    }
}
