import { Controller } from "stimulus";
import { i18n } from "../../i18n/config";
import Sentry from "@utils/sentry";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {

    static targets = [
        "error",
        "comment"
    ];

    private errorTarget: HTMLDivElement;
    private commentTarget: HTMLDivElement;

    deleteComment(event: Event) {
        event.preventDefault();

        fetch(
            this.data.get("action-url"),
            {
                method: "DELETE",
                headers: this.requestHeaders(),
                credentials: "same-origin",
            }
        ).then((response) => {
            if (response.ok) {
                DomUtils.remove(this.commentTarget);
                this.updateCommentsCount();
            } else {
                this.insertErrorMessage(i18n.t("comments_controller.comment_could_not_deleted"));
            }
        })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    private requestHeaders() {
        const csrfTag = (<HTMLMetaElement>document.querySelector('meta[name="csrf-token"]'));
        const headers = {};
        headers['X-Requested-With'] = 'XMLHttpRequest';

        if (csrfTag) { headers["X-CSRF-Token"] = csrfTag.content; }

        return headers;
    }

    private insertErrorMessage(text: string) {
        this.errorTarget.classList.remove("hidden");
        this.errorTarget.innerHTML = text;
    }

    private updateCommentsCount() {
        this.element.dispatchEvent(new CustomEvent("update-comments-counter", { bubbles: true }));
    }
}
