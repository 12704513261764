import Sentry from '@utils/sentry';
import DataUtils from '@utils/data_utils';
import { i18n } from '../../i18n/config';
import DomUtils from '@utils/dom_utils';
import { ModalContainer, RowContainer } from '@utils/types';
import { Controller } from 'stimulus';
import Spinner from '../../util/spinner';

export interface TargetLocale {
    name: string;
    code: string;
    mapped_code: string;
    mapped_code_info: string;
    mapped_code_hint: string;
}

export default class extends Controller {
    static targets = [
        "sourceLocale",
        "apiTemplates",
        "targetCheckbox",
        "selectAllLocales",
        "tagName",
        "upload",
        "translationType",
        "styleguide",
        "priority",
        "quality",
        "pricingBaseRow",
        "serviceFeeWarning",
        "summaryContainer",
        "pricingRow",
        "submitButton",
        "includeUntranslated",
        "includeUnverified",
        "totalPrice",
        "localeHint",
        "totalPriceValue",
        "styleguideModal",
        "errorMessage",
        "customIntegration",
        "orderOptions",
        "customIntegrationOptionHint",
        "targetLocalesErrorMessage",
    ];

    private sourceLocaleTarget: HTMLSelectElement;
    private customIntegrationTarget: HTMLSelectElement;
    private hasCustomIntegrationTarget: boolean;
    private targetCheckboxTargets: HTMLInputElement[];
    private apiTemplatesTargets: HTMLInputElement[];
    private selectAllLocalesTarget: HTMLInputElement;
    private tagNameTarget: HTMLInputElement;
    private uploadTarget: HTMLInputElement;
    private hasUploadTarget: boolean;
    private translationTypeTargets: HTMLInputElement[];
    private styleguideTarget: HTMLInputElement;
    private priorityTarget: HTMLInputElement;
    private qualityTarget: HTMLInputElement;
    private hasQualityTarget: boolean;
    private hasPriorityTarget: boolean;
    private pricingBaseRowTarget: HTMLElement;
    private summaryContainerTarget: HTMLElement;
    private pricingRowTargets: (HTMLElement & RowContainer)[];
    private submitButtonTarget: HTMLButtonElement;
    private includeUntranslatedTarget: HTMLInputElement;
    private includeUnverifiedTarget: HTMLInputElement;
    private totalPriceTarget: HTMLElement;
    private localeHintTargets: HTMLElement[];
    private styleguideModalTarget: ModalContainer;
    private errorMessageTarget: HTMLElement;
    private targetLocalesErrorMessageTarget: HTMLElement;
    private hasTargetLocalesErrorMessageTarget: boolean;
    private templates: Map<number, Map<number, Array<Array<string>>>>;
    private showingTemplates: boolean;
    private customIntegrationOptionHintTarget: HTMLElement;
    private orderOptionsTarget: HTMLElement;

    async connect() {
        if (this.targetLocaleIds.length == 0) {
            this.submitButtonTarget.disabled = true;
        }
        this.showingTemplates = false;
        this.templates = JSON.parse(this.data.get("templates"));
        if (this.hasCustomIntegrationTarget) {
            window.requestAnimationFrame(async () => {
                await this.toggleCustomIntegration();
            });
        }
        await this.sourceLocaleChanged();
    }

    optionsUpdate() {
        this.resetSubmitButton();
        this.resetPrices();
        this.toggleTrackableSubmitButton();
    }

    private resetSubmitButton() {
        (<any>window).$(this.submitButtonTarget).button("reset");
        this.submitButtonTarget.classList.remove("btn-success");
        this.submitButtonTarget.disabled = this.targetLocaleIds.length == 0;
    }

    private resetPrices() {
        this.summaryContainerTarget.innerHTML = "";
        this.totalPriceTarget.textContent = "";
    }

    async toggleCustomIntegration() {
        this.toggleOrderOptions();
        this.toggleApiTemplates();
        await this.sourceLocaleChanged();
    }

    private toggleOrderOptions() {
        if (this.customIntegration) {
            this.orderOptionsTarget.classList.add("hidden");
            this.customIntegrationOptionHintTarget.classList.remove("hidden");
        } else {
            this.customIntegrationOptionHintTarget.classList.add("hidden");
            this.orderOptionsTarget.classList.remove("hidden");
        }
    }

    private toggleApiTemplates() {
        if (this.customIntegration && this.apiTemplatesTargets?.length) {
            this.apiTemplatesTargets.forEach(templateWrapper => {
                templateWrapper.classList.remove("hidden");
                templateWrapper.disabled = false;
                this.showingTemplates = true;
            });
        } else {
            this.apiTemplatesTargets.forEach(templateWrapper => {
                templateWrapper.classList.add("hidden");
                templateWrapper.disabled = true;
                this.showingTemplates = false;
            });
        }
    }

    async sourceLocaleChanged() {
        this.disableSourceLocaleInTargets();
        await this.updateTargetLanguages();
        this.resetSubmitButton();
        this.toggleTrackableSubmitButton();
    }

    private disableSourceLocaleInTargets() {
        const sourceLocaleId = this.sourceLocaleTarget.value;
        let targetLocaleId: string;
        if (sourceLocaleId !== "") {
            this.targetCheckboxTargets.forEach(checkbox => {
                targetLocaleId = this.getTargetLocaleId(checkbox);
                if (targetLocaleId === sourceLocaleId) {
                    checkbox.disabled = true;
                    checkbox.checked = false;
                    if (this.hasCustomIntegrationTarget && this.showingTemplates) {
                        this.clearTemplates(targetLocaleId);
                    }
                }
            });
        }
    }

    private async updateTargetLanguages() {
        if (this.hasTargetLocalesErrorMessageTarget) {
            DomUtils.hideElement(this.targetLocalesErrorMessageTarget);
        }
        const sourceLocaleId = this.sourceLocaleTarget.value;
        if (sourceLocaleId === "") {
            return;
        }

        const params = {
            source_locale_id: sourceLocaleId,
            translation_type: this.translationType,
        };

        await DataUtils
            .request(this.targetLanguagesURL, params)
            .then(this.handleTargetLanguages.bind(this))
            .catch((error) => {
                if (this.hasTargetLocalesErrorMessageTarget) {
                    DomUtils.showElement(this.targetLocalesErrorMessageTarget);
                }
                Sentry.notify(error);
            });
    }

    private handleTargetLanguages(response: Record<string, TargetLocale>) {
        this.targetCheckboxTargets.forEach(checkbox => {
            const localeId = checkbox.dataset.targetLocale;
            const targetLocale = response[localeId];
            checkbox.dataset.lspTargetLocale = targetLocale.mapped_code_info;
            this.updateTargetLocaleHint(localeId, targetLocale.mapped_code_hint);

            if (targetLocale.mapped_code) {
                checkbox.disabled = false;
                if (this.showingTemplates) {
                    this.loadNewTemplates(localeId);
                }
            } else {
                if (this.showingTemplates) {
                    this.clearTemplates(localeId);
                }
                checkbox.disabled = true;
                checkbox.checked = false;
            }
        });

        this.disableSourceLocaleInTargets();
    }

    private updateTargetLocaleHint(localeId: string, hint: string) {
        const sourceLocaleId = this.sourceLocaleTarget.value;
        this.localeHintTargets.forEach(hintTarget => {
            if (hintTarget.dataset.languageHint === localeId) {
                hintTarget.dataset.info = hint;
                if (hint && sourceLocaleId != localeId) {
                    DomUtils.showElement(hintTarget);

                } else {
                    DomUtils.hideElement(hintTarget);
                }
            }
        });
    }

    private loadNewTemplates(targetLocaleId: string) {
        const sourceLocaleId = this.sourceLocaleTarget.value;
        const templates = this.templates[sourceLocaleId]?.[targetLocaleId];
        const select = this.getSelectWithLocaleId(targetLocaleId).selectize;
        select.clearOptions();

        if (templates?.length) {
            select.enable();
            select.addOption(this.placeholderOption);
            select.setValue(this.placeholderOption.value);
            templates.forEach(([templateId, name]) => select.addOption({ text: name, value: templateId }));
        } else {
            select.disable();
            select.addOption(this.emptyOption);
            select.setValue(this.emptyOption.value);
        }
    }

    private clearTemplates(targetLocaleId: string) {
        const select = this.getSelectWithLocaleId(targetLocaleId);
        if (select == null) return;

        const selectize = select.selectize;

        selectize.clearOptions();
        selectize.disable();
        selectize.addOption(this.notOfferedOption);
        selectize.setValue(this.notOfferedOption.value);
    }

    async submit(event) {
        const continueState = this.submitButtonTarget.value == this.submitButtonTarget.getAttribute("data-continue-text");
        if (this.styleguideTarget.value === "" && continueState) {
            event.preventDefault();
            this.styleguideModalTarget.modal.show();
        } else if (!continueState) {
            this.submitButtonTarget.setAttribute("data-action", this.submitButtonTarget.getAttribute("data-action").replace("click->trackable#track", ""));
            event.preventDefault();
            await this.calculate();
        }
    }

    private async calculate() {
        const targetLocaleIds = this.targetLocaleIds;
        const sourceLocaleId = this.sourceLocaleTarget.value;
        DomUtils.hideElement(this.errorMessageTarget);
        this.resetSubmitButton();
        this.totalPriceTarget.textContent = "";

        if (sourceLocaleId !== "" && targetLocaleIds.length > 0) {
            const spinner = new Spinner(this.totalPriceTarget);
            spinner.start();

            const params = {
                source_locale_id: this.sourceLocaleTarget.value,
                'target_locale_ids[]': targetLocaleIds,
                tag_name: this.tagNameTarget.value,
                translation_type: this.translationType,
                quality: this.quality,
                priority: this.priority,
                include_unverified_translations: this.includeUnverifiedTarget.checked,
                include_untranslated_keys: this.includeUntranslatedTarget.checked,
            };

            if (this.hasUploadTarget)
                params["upload_id"] = this.uploadTarget.value;

            await DataUtils.request(
                this.calculateURL,
                params
            ).then(response => {
                DomUtils.showElement(this.summaryContainerTarget);
                this.summaryContainerTarget.innerHTML = response.pricing_table;
                DomUtils.hideElement(this.pricingBaseRowTarget);
                this.totalPriceTarget.textContent = response.total_price_in_currency;
                this.initRows();
            }).catch((error) => {
                DomUtils.showElement(this.errorMessageTarget);
                Sentry.notify(error);
            }).finally(() => {
                this.enableContinueButton();
                spinner.stop();
            });
        }
    }

    selectAllTargetLocales(event) {
        const sourceLocaleId = this.sourceLocaleTarget.value;
        this.targetCheckboxTargets.filter(checkbox => !checkbox.disabled).forEach(checkbox => {
            if (this.getTargetLocaleId(checkbox) !== sourceLocaleId) {
                checkbox.checked = event.target.checked;
            }
        });

        this.resetSubmitButton();
    }

    targetLocaleChanged(event: Event) {
        if (this.showingTemplates) {
            const checkbox = event.target as HTMLInputElement;
            const targetLocaleId = this.getTargetLocaleId(checkbox);
            const select = this.getSelectWithLocaleId(targetLocaleId).selectize;
            if (!checkbox.checked) select.setValue('');
        }
        this.selectAllLocalesTarget.checked = this.areAllTargetLocalesSelected;
        this.resetSubmitButton();
        this.toggleTrackableSubmitButton();
    }

    selectTemplate(event: Event) {
        const target = event.target as HTMLElement;
        if (target.selectize.getValue()) {
            const targetLocaleId = this.getTargetLocaleId(target);
            this.getCheckboxWithLocaleId(targetLocaleId).checked = !!target.selectize.getValue();
        }
    }

    private toggleTrackableSubmitButton() {
        if (!this.submitButtonTarget.getAttribute("data-action").includes("click->trackable#track")) {
            this.submitButtonTarget.setAttribute("data-action", this.submitButtonTarget.getAttribute("data-action").concat(" click->trackable#track"));
        }
    }

    private initRows() {
        requestAnimationFrame(() => {
            this.targetCheckboxTargets.forEach(checkbox => {
                const row = this.pricingRowTargets.find(row => this.getTargetLocaleId(row) === this.getTargetLocaleId(checkbox));
                if (row) {
                    row.ordersRow.updateLocales(checkbox.dataset.localeLink, checkbox.dataset.lspTargetLocale);
                }
            });
        });
    }

    private enableContinueButton() {
        this.submitButtonTarget.disabled = false;
        (<any>window).$(this.submitButtonTarget).button("continue");
        this.submitButtonTarget.classList.add("btn-success");
    }

    private getCheckboxWithLocaleId = (localeId: string): HTMLInputElement =>
        document.querySelector(`#translation_order_target_locale_ids_${localeId}`);

    private getSelectWithLocaleId = (localeId: string): HTMLElement =>
        document.querySelector(`#template_for_target_locale_${localeId}`);

    private getTargetLocaleId(element: HTMLElement) {
        return element.dataset.targetLocale;
    }

    private get targetLocaleIds(): string[] {
        return this.checkedTargetCheckboxTargets.map(target => target.value);
    }

    private get checkedTargetCheckboxTargets(): HTMLInputElement[] {
        return this.targetCheckboxTargets.filter(target => target.checked);
    }

    private get areAllTargetLocalesSelected(): boolean {
        return this.targetCheckboxTargets.every(checkbox => checkbox.checked || checkbox.disabled);
    }

    private get emptyOption() {
        return { text: i18n.t('translation_orders.textmaster_form.templates.empty'), value: '' };
    }

    private get notOfferedOption() {
        return { text: i18n.t('translation_orders.textmaster_form.templates.not_offered'), value: '' };
    }

    private get placeholderOption() {
        return { text: i18n.t('translation_orders.textmaster_form.templates.none_selected'), value: '' };
    }

    private get targetLanguagesURL(): string {
        return this.data.get("target-languages-url");
    }

    private get translationType(): string {
        return this.translationTypeTargets.find(checkbox => checkbox.checked).value;
    }

    private get quality(): boolean {
        return this.hasQualityTarget && this.qualityTarget.checked;
    }

    private get priority(): boolean {
        return this.hasPriorityTarget && this.priorityTarget.checked;
    }

    private get customIntegration() {
        return this.hasCustomIntegrationTarget ? JSON.parse(this.customIntegrationTarget.value) : false;
    }

    private get calculateURL(): string {
        return this.data.get("calculateUrl");
    }
}
