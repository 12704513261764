import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["iframe"];

    private iframeTarget!: HTMLIFrameElement;

    connect() {
        this.element.addEventListener("turbo:frame-load", this.iFrameLoaded.bind(this));
    }

    iFrameLoaded(event: CustomEvent) {
        const htmlContainer = this.iframeTarget;
        if (htmlContainer) {
            const theme = this.getThemeFromLocalStorage();
            htmlContainer.addEventListener("load", this.setIFrameTheme.bind(this, theme));
        }
    }

    private getThemeFromLocalStorage(): "light" | "dark" {
        let dataTheme: "light" | "dark";
        const theme = localStorage.getItem("theme");

        if (theme === "auto") {
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme:dark)").matches) {
                dataTheme = "dark";
            } else {
                dataTheme = "light";
            }
        } else {
            dataTheme = theme as "light" | "dark";
        }

        return dataTheme;
    }

    private setIFrameTheme(theme: "light" | "dark"): void {
        const src = new URL(this.iframeTarget.src);
        const hashParams = new URLSearchParams(src.hash.slice(1));
        const themeParam = hashParams.get("theme");

        if (themeParam === "day" || themeParam === "night") {
            return;
        }

        // Need to map "dark" to "night" and "light" to "day"
        if (theme === "dark") {
            hashParams.set("theme", "night");
        } else if (theme === "light") {
            hashParams.set("theme", "day");
        }

        src.hash = `#${hashParams.toString()}`;
        this.iframeTarget.src = src.toString();
    }
}

