import { Controller } from 'stimulus';

interface ChildProjects {
    [id: string]: string[];
}

interface MultiSelectEventItem {
    element: HTMLElement;
    action: "added" | "removed";
}

export default class extends Controller {
    static targets = ["alert", "parentRow", "childrenRow", "createLocaleLink"];

    private alertTarget: HTMLElement;
    private parentRowTarget: HTMLElement;
    private childrenRowTarget: HTMLElement;
    private createLocaleLinkTarget: HTMLAnchorElement;
    private hasAlertTarget: boolean;
    private parentProjectId: string;
    private childProjectIds: ChildProjects;

    connect() {
        this.parentRowTarget.addEventListener("syntax-list-select:update-value", this.setParentProject.bind(this));
        this.childrenRowTarget.addEventListener("syntax-list-multiselect:update-value", this.addChildProject.bind(this));

        this.childProjectIds = {} as ChildProjects;
    }

    setParentProject(event: CustomEvent) {
        const parentKeyElement = event.detail.item as HTMLElement; 
        this.parentProjectId = parentKeyElement.dataset.projectId;
        this.createLocaleLinkTarget.href = parentKeyElement.dataset.createLocalePath;
        this.toggleMissingParentLocaleAlert();
    }

    addChildProject(event: CustomEvent) {
        event.detail.items.forEach((eventItem: MultiSelectEventItem) => {
            const childElement = eventItem.element as HTMLElement;
            const childProjectId = childElement.dataset.projectId;
            const childValue = childElement.dataset.value;

            if (eventItem.action === "added") {
                this.childProjectIds[childProjectId] ||= [];
                this.childProjectIds[childProjectId].push(childValue);
            } else if (eventItem.action === "removed" && this.childProjectIds[childProjectId].constructor === Array) {
                const index = this.childProjectIds[childProjectId].indexOf(childValue);
                this.childProjectIds[childProjectId].splice(index, 1);

                if (this.childProjectIds[childProjectId].length === 0) {
                    delete this.childProjectIds[childProjectId];
                }
            }
        });

        this.toggleMissingParentLocaleAlert();
    }

    toggleMissingParentLocaleAlert() {
        if (!this.hasAlertTarget) return;
        const childProjectIds = Object.keys(this.childProjectIds);
        if (!this.parentProjectId || childProjectIds.length === 0) {
            this.hideAlert();
            return;
        }

        const showAlert = childProjectIds.find((id) => id !== this.parentProjectId);

        if (showAlert) {
            this.showAlert();
        } else {
            this.hideAlert();
        }
    }

    showAlert() {
        this.alertTarget.classList.remove("hidden");
    }

    hideAlert() {
        this.alertTarget.classList.add("hidden");
    }
}
