import Sentry from "@utils/sentry";
import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        if (this.data.get("src") === "" || this.data.get("targetFrameId") === "") {
            Sentry.notify("Missing src");
        }
    }

    visit(event: Event) {
        const target = event.target as HTMLElement;
        const prevent = target.dataset.clickablePrevent === 'true';
        const disabled = target.ariaDisabled === 'true';
        if (prevent || disabled) {
            return;
        }
        if (["A", "INPUT", "SELECT", "TEXTAREA"].includes(target.tagName)) {
            return;
        }

        let parent: HTMLElement = target.parentElement;
        while (parent) {
            if (parent.tagName == "A" || 
                parent.dataset.clickablePrevent === 'true' ||
                parent.ariaDisabled === 'true'
            ) {
                return;
            }
            parent = parent.parentElement;
        }

        const src = this.data.get("src");
        const targetFrameId = this.data.get("targetFrameId");
        const targetFrame = document.getElementById(targetFrameId) as HTMLElement;

        if (src !== "" && targetFrame !== null) {
            (targetFrame as any).src = src;
        }
    }
}
