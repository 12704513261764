import { Controller } from "stimulus";

export default class extends Controller {

    static targets = [
        "formElement",
        "permissionCheckbox"
    ];

    formElementTarget: HTMLFormElement;
    permissionCheckboxTargets: HTMLDivElement[];

    toggle(event: Event){
        const roleName = (event.target as HTMLInputElement).value;
        const shouldToggle = this.formElementTarget.dataset.editableRoles.includes(roleName);

        this.togglePermissions(roleName);

        this.formElementTarget.classList.toggle('hidden', !shouldToggle);
    }

    reset(){
        this.formElementTarget.classList.add('hidden');
    }

    private togglePermissions(roleName: string): void {
        this.permissionCheckboxTargets.forEach((checkbox) => {
            checkbox.querySelector("input").checked = false;
            checkbox.classList.add("hidden");
        });

        this.element.querySelectorAll(`[data-belongs-to-role="${roleName}"]`).forEach((checkbox) => {
            checkbox.classList.remove("hidden");
        });
    }
}
