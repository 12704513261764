import { camelize } from "@utils/camelize";
import { Controller } from "stimulus";


export default class RowController extends Controller {
    static targets = [
        "targetLocale",
        "lspTargetLocale"
    ];

    targetLocaleTarget: HTMLElement;
    lspTargetLocaleTarget: HTMLElement;

    connect() {
        this.element[camelize(this.identifier)] = this;
    }

    updateLocales(targetLocale: string, lspTargetLocale: string) {
        this.targetLocaleTarget.innerHTML = targetLocale;
        this.lspTargetLocaleTarget.textContent = lspTargetLocale;
    }
}
