interface Selectize {
    enable: ()=> void;
    disable: ()=> void;

    focus: ()=> void;

    clear(silent: boolean): void;
    setValue(value: string): void;
    setValue(value: string, _: boolean): void;
    getValue(id): string;

    clearOptions: ()=> void;
    clearOptionGroups: ()=> void;
    addOption(option: SelectizeOption): void;
    addOptionGroup(id: string, data: object): void;
    removeOption(option: string, silent: boolean): void;
    refreshOptions(update: boolean): void;
    refreshItems: ()=> void;
    options: ()=> any;
    items: ()=> any;
}

interface SelectizeOption {
    value: string;
    text: string;
    optgroup?: string;
    $order?: number;
}

export function selectizeOf(target: HTMLElement): Promise<Selectize> {
    return new Promise((resolve, reject) => {
        window.requestAnimationFrame(() => {
            const selectize = (window as any).$(target).data("selectize");

            if (selectize) {
                resolve(selectize);
            } else {
                reject(new Error("selectize controller is not connected on target"));
            }
        });
    });
}
