import "@hotwired/turbo-rails";
import { Turbo } from "@hotwired/turbo-rails";

import "@stimulus/polyfills";
import "custom-event-polyfill";
import "datejs";
import "weakmap-polyfill";
import "whatwg-fetch";

// URL polyfill
import "core-js/es/instance/ends-with";
import "core-js/es/instance/includes";
import "core-js/es/instance/starts-with";
import "url-polyfill";

import "mdn-polyfills/Array.prototype.fill";
import "mdn-polyfills/CustomEvent";
import "mdn-polyfills/Node.prototype.prepend";
import "mdn-polyfills/NodeList.prototype.forEach";

import "./controllers";

// Make Tracker accessible to outside world
import Tracker from "./util/tracker";
(<any>window).Tracker = Tracker;

import Redirect from './util/redirect';
(<any>window).Redirect = Redirect;

// Additional turbo action for simplify redirects
Turbo.StreamActions.redirect = function () {
    Turbo.visit(this.target);
};
