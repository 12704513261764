import { Controller } from "stimulus";
import { camelize } from "@utils/camelize";
import Sentry from "@utils/sentry";
import DataUtils from "@utils/data_utils";

export default class extends Controller {
    static targets = ['body'];
    bodyTarget: HTMLElement;
    private hasBodyTarget: boolean;
    private loaded = false;

    async connect() {
        this.element[camelize(this.identifier)] = this;
        if (this.autoloadEnabled) await this.loadAsync();
    }

    async load() {
        if (!this.loaded) await this.loadAsync();
    }

    async reload() {
        await this.loadAsync();
    }

    private async loadAsync() {
        this.element.dispatchEvent(new CustomEvent("async-render:before"));

        await DataUtils.request(
            this.data.get('path'),
            void 0,
            {
                parse: false
            }, {
                method: "GET",
                credentials: "same-origin",
                headers: { "X-Requested-With": "XMLHttpRequest" }
            })
            .then(async (data) => {
                this.loaded = true;
                this.body.innerHTML = await data.text();
                this.element.dispatchEvent(new CustomEvent("async-render:success"));
            })
            .catch((err: string) => {
                this.element.dispatchEvent(new CustomEvent("async-render:error"));
                Sentry.notify(err);
            });
    }

    private get body(): Element {
        return this.hasBodyTarget ? this.bodyTarget : this.element;
    }

    private get autoloadEnabled(): boolean {
        return !this.data.get("disableAutoload");
    }
}
