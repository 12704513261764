import { Controller } from "stimulus";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = [
        "term",
        "translationFieldsContainer",
        "translatableTrue",
        "translatableFalse",

        "termTranslationRows",
        "termTranslation",
        "termTranslationLocaleCode",
        "termTranslationContent",
        "termTranslationTemplate",
    ];

    private translationFieldsContainerTarget: HTMLInputElement;
    private termTarget: HTMLTextAreaElement;
    private translatableTrueTarget: HTMLInputElement;
    private translatableFalseTarget: HTMLInputElement;

    private termTranslationRowsTarget: HTMLElement;
    private termTranslationTargets: HTMLDivElement[];
    private termTranslationLocaleCodeTargets: HTMLTextAreaElement[];
    private termTranslationContentTargets: HTMLTextAreaElement[];
    private termTranslationTemplateTarget: HTMLTemplateElement;

    private nextIndex: number;

    connect() {
        this.nextIndex = this.termTranslationTargets.length;
    }

    onTranslatableOptionChange (e: Event) {
        this.toggleTermTranslationRowVisibility(e.currentTarget as HTMLInputElement);
        e.preventDefault();
    }

    addRow (event: MouseEvent) {
        event.preventDefault();

        const newRow = document.importNode(this.termTranslationTemplateTarget.content, true);
        this.termTranslationRowsTarget.appendChild(newRow);

        // make sure stimulus recognized the new targets
        window.requestAnimationFrame(() => {
            const select = this.termTranslationLocaleCodeTargets[this.termTranslationLocaleCodeTargets.length - 1];
            const textarea = this.termTranslationContentTargets[this.termTranslationContentTargets.length - 1];

            select.id = `glossary_term_glossary_term_translations_attributes_${this.nextIndex}_locale_code`;
            select.setAttribute(
                'name',
                `glossary_term[glossary_term_translations_attributes][${this.nextIndex}][locale_code]`,
            );

            textarea.id = `glossary_term_glossary_term_translations_attributes_${this.nextIndex}_content`;
            textarea.setAttribute(
                'name',
                `glossary_term[glossary_term_translations_attributes][${this.nextIndex}][content]`,
            );

            this.nextIndex = this.nextIndex + 1;
        });
    }

    private resetRow (row: HTMLDivElement) {
        row.querySelector('textarea').value = '';
    }

    private toggleTermTranslationRowVisibility (translatableOption: HTMLInputElement) {
        const checked = translatableOption.checked;
        const value = translatableOption.value;

        if (checked && value === "true") {
            this.translationFieldsContainerTarget.classList.remove('hidden');
            this.termTranslationContentTargets[0].value = this.termTarget.value;
        } else {
            this.translationFieldsContainerTarget.classList.add('hidden');
        }
    }

    removeRow(event: MouseEvent) {
        event.preventDefault();

        const removeLink = event.currentTarget as HTMLAnchorElement;
        const row = removeLink.closest('[data-target="glossaries--glossary-term-form.termTranslation"]') as HTMLDivElement;
        const destroyField = row.querySelector('.delete-glossary-translaiton') as HTMLInputElement;
        if (this.termTranslationTargets.length > 1) {
            destroyField.value = "true";
            DomUtils.hideElement(row);
        } else {
            this.resetRow(row);

            this.translatableTrueTarget.checked = false;
            this.translatableFalseTarget.checked = true;

            DomUtils.hideElement(this.translationFieldsContainerTarget);
        }
    }
}
