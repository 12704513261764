import { Controller } from 'stimulus';
import Tracker from '@utils/tracker';

export default class extends Controller {
    submitForm(event) {
        if (event.target.value) {
            Tracker.track(
                'Selected Job Template from Drop-Down',
                { 'Source': 'Translation Center' }
            );
            (this.element as HTMLFormElement).submit();
        }
    }
}
