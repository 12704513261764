import {Controller} from "stimulus";
import Sentry from "@utils/sentry";
import {selectizeOf} from "@utils/selectize";

// @TODO: Get rid of jQuery stuff
export default class extends Controller {
    static targets = [
        "includeEmptyCheckbox",
        "fallbackLocaleSelect",
        "includeTranslatedKeysCheckbox",
        "formatSelect",
        "formatOption",
        "encodingSelect",
        "exportTagsCheckbox",
        "exportSystemTagsCheckbox",
        "excludeEmptyZeroFormsCheckbox",
        "keepPluralSkeletonsCheckbox",
        "replaceTargetTranslationsWithEmptyStringCheckbox",
        "documentOptions",
        "filteringTab",
        "metadataFieldsHolder",
    ];

    includeEmptyCheckboxTarget: HTMLInputElement;
    includeTranslatedKeysCheckboxTarget: HTMLInputElement;
    excludeEmptyZeroFormsCheckboxTarget: HTMLInputElement;
    keepPluralSkeletonsCheckboxTarget: HTMLInputElement;
    replaceTargetTranslationsWithEmptyStringCheckboxTarget: HTMLInputElement;
    hasReplaceTargetTranslationsWithEmptyStringCheckboxTarget: boolean;
    exportTagsCheckboxTarget: HTMLInputElement;
    exportSystemTagsCheckboxTarget: HTMLInputElement;
    fallbackLocaleSelectTarget: HTMLSelectElement;
    formatSelectTarget: HTMLSelectElement;
    formatOptionTargets: HTMLDivElement[];
    encodingSelectTarget: HTMLSelectElement;
    documentOptionsTarget: HTMLDivElement;
    filteringTabTarget: HTMLLIElement;
    metadataFieldsHolderTarget: HTMLElement;
    hasFilteringTabTarget: boolean;

    hasFallbackLocaleSelectTarget: boolean;
    hasExportSystemTagsCheckboxTarget: boolean;
    hasExcludeEmptyZeroFormsCheckboxTarget: boolean;
    hasDocumentOptionsTarget: boolean;

    element: HTMLFormElement;
    private formats: any;
    private documentList: any[];

    connect() {
        this.formats = JSON.parse(this.data.get("formats"));
        this.toggleIncludeEmptyTranslationsSubmenu();
        this.toggleFormatOptions();
        this.toggleExportSystemTagsSubmenu();
        this.toggleFilterTab();
        this.updateEncodingList();
        this.filterDocumentList();
        this.preservePluralSkeletonsToggle();
        this.documentList = [];
    }

    private filterDocumentList() {
        if (this.hasDocumentOptionsTarget) {
            const currentFormat = this.currentFormat();
            selectizeOf(this.documentOptionsTarget)
                .then((selectize) => {
                    if(this.documentList.length === 0) {
                        Object.entries(selectize.options).forEach((option) => {
                            this.documentList.push(option);
                        });
                    }
                    const newEntries = this.documentList.filter((option) => {
                        return option[1].name.includes(currentFormat);
                    });
                    selectize.clearOptions();
                    newEntries.forEach((entry) => {
                        selectize.addOption(entry);
                    });
                })
                .catch(err => {
                    Sentry.notify(err);
                });
        }
    }

    private toggleIncludeEmptyTranslationsSubmenu() {
        if (this.includeEmptyCheckboxTarget.checked) {
            this.enableFallbackLocaleSelectize();
            this.includeTranslatedKeysCheckboxTarget.disabled = false;
            if (this.hasExcludeEmptyZeroFormsCheckboxTarget) {
                this.excludeEmptyZeroFormsCheckboxTarget.disabled = false;
            }
        } else {
            this.disableFallbackLocaleSelectize();
            this.includeTranslatedKeysCheckboxTarget.disabled = true;
            this.includeTranslatedKeysCheckboxTarget.checked = true;
            if (this.hasExcludeEmptyZeroFormsCheckboxTarget) {
                this.excludeEmptyZeroFormsCheckboxTarget.disabled = true;
            }
        }
    }

    private enableFallbackLocaleSelectize() {
        if (this.hasFallbackLocaleSelectTarget && this.fallbackLocaleSelectTarget.length > 0) {
            selectizeOf(this.fallbackLocaleSelectTarget)
                .then((selectize) => selectize.enable())
                .catch(err => {
                    Sentry.notify(err);
                });
        }
    }

    private disableFallbackLocaleSelectize() {
        if (this.hasFallbackLocaleSelectTarget && this.fallbackLocaleSelectTarget.length > 0) {
            selectizeOf(this.fallbackLocaleSelectTarget)
                .then((selectize) => selectize.disable())
                .catch(err => {
                    Sentry.notify(err);
                });
        }
    }

    toggleExportSystemTagsSubmenu() {
        if (this.hasExportSystemTagsCheckboxTarget) {
            this.exportSystemTagsCheckboxTarget.disabled = true;

            if (this.exportTagsCheckboxTarget.checked) {
                this.exportSystemTagsCheckboxTarget.disabled = false;
            } else {
                this.exportSystemTagsCheckboxTarget.disabled = true;
                this.exportSystemTagsCheckboxTarget.checked = false;
            }
        }
    }

    toggleFormatOptions() {
        this.formatOptionTargets.forEach(
            (div) => {
                if (this.optionForFormat(this.currentFormat(), div)) {
                    div.classList.remove("hidden");
                    Array.prototype.slice.call(div.querySelectorAll("input")).forEach(
                        (input) => {input.disabled = false;}
                    );
                } else {
                    div.classList.add("hidden");
                    Array.prototype.slice.call(div.querySelectorAll("input")).forEach(
                        (input) => {input.disabled = true;}
                    );
                }
            }
        );
    }

    private optionForFormat(format: string, div: HTMLDivElement): boolean {
        const formats = div.getAttribute("data-download-form-formats").split(",");
        const foundFormat = formats.find((f) => f === format);
        return foundFormat !== undefined;
    }

    updateEncodingList() {
        const currentFormat = this.currentFormat();
        if (currentFormat) {
            selectizeOf(this.encodingSelectTarget)
                .then((selectize) => {
                    selectize.clearOptions();

                    const allowedEncodings = this.getAllowedEncodingsForFormat(currentFormat);
                    const defaultEncoding = allowedEncodings[0] || "UTF-8";

                    allowedEncodings.forEach(
                        (encoding) => {
                            selectize.addOption(
                                {
                                    value: encoding,
                                    text: encoding
                                }
                            );
                        }
                    );
                    selectize.refreshOptions(true);

                    selectize.setValue(defaultEncoding);
                })
                .catch(err => {
                    Sentry.notify(err);
                });
        }
    }

    preservePluralSkeletonsToggle() {
        if(!this.hasReplaceTargetTranslationsWithEmptyStringCheckboxTarget) {
            return;
        }
        const targetTranslationCheckbox = this.replaceTargetTranslationsWithEmptyStringCheckboxTarget;
        const pluralSkeletonCheckbox = this.keepPluralSkeletonsCheckboxTarget;

        pluralSkeletonCheckbox.addEventListener("change", function() {
            targetTranslationCheckbox.checked = true;
        });

        targetTranslationCheckbox.addEventListener("change", function() {
            pluralSkeletonCheckbox.checked = false;
        });
    }

    toggleFilterTab(){
        if(this.filterableFormat()){
            this.hasFilteringTabTarget && this.filteringTabTarget.classList.remove("hidden");
        }
        else{
            this.hasFilteringTabTarget && this.filteringTabTarget.classList.add("hidden");
            this.metadataFieldsHolderTarget.innerHTML = "";
        }
    }

    private currentFormat() {
        return this.formatSelectTarget.value;
    }

    private filterableFormat(): boolean{
        const filterableFormats = ["csv", "xlsx", "txt", "strings_json"];

        return filterableFormats.includes(this.currentFormat());
    }

    private getAllowedEncodingsForFormat(format: string): Array<string> {
        return this.formats[format]["encoding"];
    }
}
