import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["frame", "container"];

    private frameTargets: HTMLInputElement[];
    private containerTargets: HTMLElement[];
    private hasFrameTarget: boolean;
    private hasContainerTarget: boolean;

    handleCheckboxToggle(event: Event) {
        this.clearContainers();

        if (this.hasFrameTarget) {
            this.frameTargets.forEach((frame) => {
                this.updateFrameSrc(frame);
            });
        }
    }

    // clear containers so that only results matching toggled state will be presented to user
    clearContainers() {
        if (!this.hasContainerTarget) return;

        this.containerTargets.forEach((container) => {
            container.innerHTML = "";
        });
    }

    updateFrameSrc(frame) {
        const url = new URL(frame.src, location.origin);
        const excludeLinkedKeys = (event.target as HTMLInputElement).checked.toString();

        url.searchParams.set("exclude_linked_keys", excludeLinkedKeys);
        url.searchParams.delete("page");

        frame.src = url.toString();
    }
}
