import { Controller } from 'stimulus';
import DataUtils from '@utils/data_utils';
import DomUtils from '@utils/dom_utils';
import Sentry from "@utils/sentry";
import Redirect from '@utils/redirect';
import Tracker from "@utils/tracker";

import { i18n } from '../../i18n/config';
import { selectizeOf } from '@utils/selectize';

interface State {
    demoFileUpload: boolean;
    form: {
        file: File;
        fileFormat: string;
        sourceLocale: string;
        autotranslate: boolean;
        tags: Array<string>;
        projectName: string;
        targetLocales: Array<string>;
    };
    uploadStatus: {
        pollingCount: number;
    };
}

interface FormErrors {
    tags: Array<string>;
    target_locales: Array<string>;
}

const MAXIMUM_UPLOAD_STATUS_REQUESTS = 120;
const TIME_BETWEEN_UPLOAD_STATUS_REQUESTS = 1000;

export default class extends Controller {
    state: State = {
        demoFileUpload: false,
        form: {
            file: null,
            fileFormat: null,
            sourceLocale: null,
            autotranslate: false,
            tags: [],
            projectName: null,
            targetLocales: [],
        },
        uploadStatus: {
            pollingCount: 0
        }
    };

    static targets = [
        // Slide 1
        'dragAndDropArea',
        'errorElement',

        'progressFilename',
        'progressCount',
        'progressBar',

        'demoFileWrapper',

        'configurationWrapper',
        'sourceLocaleSelect',
        'fileFormatSelect',

        'autotranslateCheckbox',
        'tagsSelect',

        'slideOneFooter',
        'slideOneFooterNextButton',

        // Slide 2
        'preparingProjectArea',

        'projectDetailsArea',
        'projectNameInput',
        'targetLocalesSelect',

        'sourceLocaleFlagImg',
        'sourceLocaleName',
        'reuploadLink',

        'slideTwoFooter',
        'slideTwoFooterFinishButton',
    ];

    private dragAndDropAreaTarget: HTMLElement;
    private errorElementTarget: HTMLElement;

    private progressFilenameTarget: HTMLElement;
    private progressCountTarget: HTMLElement;
    private progressBarTarget: HTMLElement;

    private demoFileWrapperTarget: HTMLElement;

    private configurationWrapperTarget: HTMLElement;
    private sourceLocaleSelectTarget: HTMLSelectElement;
    private fileFormatSelectTarget: HTMLSelectElement;

    private autotranslateCheckboxTarget: HTMLInputElement;
    private tagsSelectTarget: HTMLSelectElement;

    private slideOneFooterTarget: HTMLElement;
    private slideOneFooterNextButtonTarget: HTMLButtonElement;

    private preparingProjectAreaTarget: HTMLElement;

    private projectDetailsAreaTarget: HTMLElement;
    private projectNameInputTarget: HTMLInputElement;
    private targetLocalesSelectTarget: HTMLSelectElement;

    private sourceLocaleFlagImgTarget: HTMLImageElement;
    private sourceLocaleNameTarget: HTMLElement;
    private reuploadLinkTarget: HTMLLinkElement;

    private slideTwoFooterTarget: HTMLElement;
    private slideTwoFooterFinishButtonTarget: HTMLButtonElement;

    onDrop(e: DragEvent) {
        e.preventDefault();

        this.onDragLeave();
        this.hideErrorMessage();

        if (this.isDemoFileUpload(e)) {
            this.state.demoFileUpload = true;
            this.hideReuploadLink();
            this.goToNextSlide();

            Tracker.track("Uploaded demo language file", {
                "Source": "Translation Center",
                "Scope": "Onboarding",
            });

            return;
        }

        const fileList = e.dataTransfer.files;
        this.validateFile(fileList[0]);

        Tracker.track("Dragged and dropped own file", {
            "Source": "Translation Center",
            "Scope": "Onboarding",
        });
    }

    onDragEnter(e: DragEvent) {
        e.preventDefault();
        this.dragAndDropAreaTarget.classList.add('highlight');
    }

    onDragLeave() {
        this.dragAndDropAreaTarget.classList.remove('highlight');
    }

    onDragStartExample(e: DragEvent) {
        e.dataTransfer.setData('text', 'demo_file');
    }

    onFileInputChange(e: Event) {
        this.hideErrorMessage();
        this.state.demoFileUpload = false;
        this.showReuploadLink();

        const fileList = (e.target as HTMLInputElement).files;

        this.validateFile(fileList[0]);
    }

    handleRemoveFileClicked() {
        this.removeInlineErrorMessage(this.getFieldFormGroup('tags'));
        this.removeInlineErrorMessage(this.getFieldFormGroup('target_locales'));
        this.hideErrorMessage();
        this.hideUploadProgressArea();
        this.enableConfigurationForm();
        this.showDemoFileArea();
        this.hideConfigurationArea();
        this.showReuploadLink();

        this.resetFormFields();

        Tracker.track("Deleted uploaded file in Upload Modal", {
            "Source": "Translation Center",
            "Scope": "Onboarding",
        });
    }

    handleCloseModal(event: Event) {
        event.preventDefault();

        DataUtils.request(
            this.cancelOnboardingPath,
            { },
            { parse: true },
            { method: 'DELETE' },
        ).then(response => {
            Redirect.go(response.redirect_url);
        }).catch(exception => {
            Sentry.notify(exception);
        });
    }

    handleFinishSetup(event: Event) {
        event.preventDefault();

        this.showPreparingProjectArea();

        fetch(
            this.finishProjectOnboardingPath,
            {
                method: 'PUT',
                headers: this.requestHeaders(),
                body: this.buildFileUploadFormData(),
            }
        ).then(async response => {
            const parsedResponse = await response.json();

            if (parsedResponse.errors) {
                this.respondToFormErrors(parsedResponse.errors);
            } else {
                switch(parsedResponse.status) {
                    case 'failed':
                        this.respondToBackendError(parsedResponse.error, parsedResponse.description);

                        break;
                    case 'processing':
                        this.debounceCheckUploadStatus(parsedResponse.upload_url);

                        break;
                    case 'finished':
                        Tracker.track("Finished setup in Upload Modal", {
                            "Source": "Translation Center",
                            "Scope": "Onboarding",
                        });

                        Redirect.go(parsedResponse.redirect_url);

                        break;
                    default:
                        throw 'unhandled upload state';
                }
            }
        }).catch(exception => {
            this.respondToException(exception);
        });
    }

    private validateFile(file: File) {
        if (typeof(file) === 'undefined') return;

        this.showDragAndDropAreaSpinner();

        DataUtils.request(
            this.validateFilePath,
            { file_name: file.name },
            { parse: true },
            { method: 'POST' }
        ).then(response => {
            if (response.file_extension) {
                selectizeOf(
                    this.fileFormatSelectTarget
                ).then(selectize => {
                    selectize.setValue(response.file_extension);
                }).catch(err => {
                    Sentry.notify(err);
                });
            }

            if (response.error) {
                this.showErrorMessage(response.error);
            } else {
                this.state.form.file = file;

                this.showUploadProgressArea(file.name);
                this.showConfigurationArea();
                this.hideDemoFileArea();
            }
        }).catch(exception => {
            Sentry.notify(exception);

            this.showErrorMessage(i18n.t('errors.general.error_message_advice_javascript'));
        }).finally(() => {
            this.hideDragAndDropAreaSpinner();
        });
    }

    private checkUploadStatus(uploadUrl: string) {
        DataUtils.request(
            uploadUrl
        ).then(uploadStatusResponse => {
            switch(uploadStatusResponse.status) {
                case 'upload_provides_preview':
                case 'upload_is_document_based':
                    this.state.uploadStatus.pollingCount = 0;

                    DataUtils.request(
                        this.markOnboardingFinishedPath,
                        { },
                        { parse: true },
                        { method: 'POST' }
                    ).catch(exception => {
                        this.respondToException(exception);
                    });

                    Redirect.go(uploadStatusResponse.redirect_url);

                    break;
                case 'failed':
                    this.respondToBackendError(uploadStatusResponse.error, uploadStatusResponse.description);

                    break;
                case 'processing':
                    this.debounceCheckUploadStatus(uploadUrl);

                    break;
                case 'finished':
                    this.state.uploadStatus.pollingCount = 0;

                    DataUtils.request(
                        this.markOnboardingFinishedPath,
                        { },
                        { parse: true },
                        { method: 'POST' }
                    ).then(markFinishedOnboardingResponse => {
                        Redirect.go(markFinishedOnboardingResponse.redirect_url);
                    }).catch(exception => {
                        this.respondToException(exception);
                    });

                    break;
                default:
                    throw 'unhandled upload state';
            }
        }).catch(exception => {
            this.respondToException(exception);
        });
    }

    /* form handlers */

    handleSourceLocaleSelected() {
        this.updateCountryFlag();

        this.state.form.sourceLocale = this.sourceLocaleSelectTarget.selectize.getValue();
        this.removeInlineErrorMessage(this.getFieldFormGroup('target_locales'));

        this.enableNextButtonWhenFormIsValid();

        Tracker.track("Selected file language in Upload Modal", {
            "Source": "Translation Center",
            "Scope": "Onboarding",
        });
    }

    handleFileFormatSelected() {
        this.state.form.fileFormat = this.fileFormatSelectTarget.selectize.getValue();

        this.enableNextButtonWhenFormIsValid();
    }

    handleProjectNameChanged() {
        this.state.form.projectName = this.projectNameInputTarget.value;

        this.enableFinishButtonWhenFormIsValid();

        Tracker.track("Gave Project a name in Upload Modal", {
            "Source": "Translation Center",
            "Scope": "Onboarding",
        });
    }

    handleTargetLocalesSelected() {
        this.state.form.targetLocales = this.targetLocalesSelectTarget.selectize.getValue();

        this.enableFinishButtonWhenFormIsValid();

        Tracker.track("Selected target language in Upload Modal", {
            "Source": "Translation Center",
            "Scope": "Onboarding",
        });
    }

    handleAutotranslateChecked() {
        this.state.form.autotranslate = this.autotranslateCheckboxTarget.checked;

        Tracker.track("Enabled Autofill in Upload Modal", {
            "Source": "Translation Center",
            "Scope": "Onboarding",
        });
    }

    handleTagsSelected() {
        this.state.form.tags = this.tagsSelectTarget.selectize.getValue();
        this.removeInlineErrorMessage(this.getFieldFormGroup('tags'));
    }

    /* utility functions */

    private showErrorMessage(errorMessage: string, errorDescription?: string) {
        const errorHeadingElement =
            <HTMLElement>this.errorElementTarget.querySelector('.onboarding-alert__heading');
        const errorDescriptionElement =
            <HTMLElement>this.errorElementTarget.querySelector('.onboarding-alert__description');

        errorHeadingElement.innerHTML = errorMessage;

        if (errorDescription) {
            const preElement = document.createElement('pre');
            const codeElement = document.createElement('code');

            codeElement.classList.add('upload-error');
            codeElement.innerHTML = errorDescription;

            preElement.appendChild(codeElement);
            errorDescriptionElement.innerHTML = '';
            errorDescriptionElement.appendChild(preElement);

            DomUtils.showElement(errorDescriptionElement);
        } else {
            errorDescriptionElement.innerHTML = '';
            DomUtils.hideElement(errorDescriptionElement);
        }

        this.errorElementTarget.querySelector('.onboarding-alert').classList.remove('hidden');
        DomUtils.showElement(this.errorElementTarget);
    }

    private showInlineErrorMessage(field: string, messages: Array<string>) {
        const fieldFormGroup = this.getFieldFormGroup(field);
        const errorElement = document.createElement('span');

        errorElement.classList.add('help-block', 'error');
        errorElement.innerText = messages.join('<br />');

        fieldFormGroup.classList.add('has-error');
        fieldFormGroup.appendChild(errorElement);
    }

    private removeInlineErrorMessage(fieldFormGroup: Element) {
        fieldFormGroup.classList.remove('has-error');

        const helpBlockElement = fieldFormGroup.querySelector('.help-block');

        if (helpBlockElement) DomUtils.remove(helpBlockElement);
    }

    private getFieldFormGroup(field: string) {
        switch(field) {
            case 'tags': return this.tagsSelectTarget.closest('.form-group');
            case 'target_locales': return this.targetLocalesSelectTarget.closest('.form-group');
            default: throw 'unhandled form field error';
        }
    }

    private hideErrorMessage() {
        DomUtils.hideElement(this.errorElementTarget);
    }

    private showDragAndDropAreaSpinner() {
        this.dragAndDropAreaTarget.classList.add('checking');
    }

    private hideDragAndDropAreaSpinner() {
        this.dragAndDropAreaTarget.classList.remove('checking');
    }

    private showConfigurationArea() {
        DomUtils.showElement(this.configurationWrapperTarget);
        DomUtils.showElement(this.slideOneFooterTarget);
    }

    private hideConfigurationArea() {
        DomUtils.hideElement(this.configurationWrapperTarget);
        DomUtils.hideElement(this.slideOneFooterTarget);
    }

    private showDemoFileArea() {
        DomUtils.showElement(this.demoFileWrapperTarget);
    }

    private hideDemoFileArea() {
        DomUtils.hideElement(this.demoFileWrapperTarget);
    }

    private showReuploadLink() {
        DomUtils.showElement(this.reuploadLinkTarget);
    }

    private hideReuploadLink() {
        DomUtils.hideElement(this.reuploadLinkTarget);
    }

    private enableNextButtonWhenFormIsValid() {
        if (
            !!this.state.form.fileFormat &&
            !!this.state.form.sourceLocale
        ) {
            DomUtils.enableButton(this.slideOneFooterNextButtonTarget);
        } else {
            DomUtils.disableButton(this.slideOneFooterNextButtonTarget);
        }
    }

    private enableFinishButtonWhenFormIsValid() {
        if (
            !!this.state.form.projectName && this.state.form.targetLocales.length
        ) {
            DomUtils.enableButton(this.slideTwoFooterFinishButtonTarget);
        } else {
            DomUtils.disableButton(this.slideTwoFooterFinishButtonTarget);
        }
    }

    private disableConfigurationForm() {
        this.configurationWrapperTarget.classList.add('disabled-section');
        this.slideOneFooterTarget.classList.add('disabled-section');
    }

    private enableConfigurationForm() {
        this.configurationWrapperTarget.classList.remove('disabled-section');
        this.slideOneFooterTarget.classList.remove('disabled-section');
    }

    private hideUploadProgressArea() {
        this.dragAndDropAreaTarget.classList.remove('uploading');
    }

    private showUploadProgressArea(fileName: string) {
        this.disableConfigurationForm();
        this.progressFilenameTarget.textContent = fileName;
        this.dragAndDropAreaTarget.classList.add('uploading');

        let i = 0;
        const interval = setInterval(() => {
            ++i;

            this.progressCountTarget.textContent = `${i}%`;
            this.progressBarTarget.style.width = `${i}%`;
            if (i === 100) {
                clearInterval(interval);
                this.enableConfigurationForm();
            }

        }, 10);
    }

    private showPreparingProjectArea() {
        DomUtils.showElement(this.preparingProjectAreaTarget);
        DomUtils.hideElement(this.projectDetailsAreaTarget);
        DomUtils.hideElement(this.slideTwoFooterTarget);
    }

    private hidePreparingProjectArea() {
        DomUtils.hideElement(this.preparingProjectAreaTarget);
        DomUtils.showElement(this.projectDetailsAreaTarget);
        DomUtils.showElement(this.slideTwoFooterTarget);
    }

    private resetFormFields() {
        this.sourceLocaleSelectTarget.selectize.clear();
        this.fileFormatSelectTarget.selectize.clear();
        this.targetLocalesSelectTarget.selectize.clear();
        this.projectNameInputTarget.value = '';
        this.autotranslateCheckboxTarget.checked = false;
        this.tagsSelectTarget.selectize.clear();

        this.state.demoFileUpload = false;
        this.state.form.sourceLocale = null;
        this.state.form.fileFormat = null;
        this.state.form.file = null;
        this.state.form.autotranslate = false;
        this.state.form.tags = [];
        this.state.form.projectName = null;
        this.state.form.targetLocales = [];
    }

    private isDemoFileUpload(e: DragEvent) {
        return e.dataTransfer.getData('text') === 'demo_file';
    }

    private goToNextSlide() {
        this.element.dispatchEvent(new CustomEvent('modal-slides:go-to-next'));
    }

    private goToPrevSlide() {
        this.element.dispatchEvent(new CustomEvent('modal-slides:go-to-prev'));
    }

    private buildFileUploadFormData() {
        const formData = new FormData();

        if (!this.state.demoFileUpload) {
            formData.append('onboarding_project_form[file_format]', this.state.form.fileFormat);
            formData.append('onboarding_project_form[source_locale]', this.state.form.sourceLocale);
            formData.append('onboarding_project_form[file]', this.state.form.file);
            formData.append('onboarding_project_form[autotranslate]', this.state.form.autotranslate.toString());

            for (let i = 0; i < this.state.form.tags.length; i++) {
                formData.append('onboarding_project_form[tags][]', this.state.form.tags[i]);
            }
        }

        formData.append('onboarding_project_form[project_name]', this.state.form.projectName);
        for (let i = 0; i < this.state.form.targetLocales.length; i++) {
            formData.append('onboarding_project_form[target_locales][]', this.state.form.targetLocales[i]);
        }

        return formData;
    }

    private requestHeaders() {
        const csrfTag = (<HTMLMetaElement>document.querySelector('meta[name="csrf-token"]'));
        const headers = {};

        if (csrfTag) {
            headers["X-CSRF-Token"] = csrfTag.content;
        }

        return headers;
    }

    private debounceCheckUploadStatus(uploadUrl: string) {
        if (this.state.uploadStatus.pollingCount === MAXIMUM_UPLOAD_STATUS_REQUESTS) {
            this.respondToBackendError(i18n.t('errors.general.error_message_advice_javascript'));

            return;
        }

        window.setTimeout(() => {
            ++this.state.uploadStatus.pollingCount;
            this.checkUploadStatus(uploadUrl);
        }, TIME_BETWEEN_UPLOAD_STATUS_REQUESTS);
    }

    private respondToBackendError(errorMessage: string, errorDescription?: string) {
        this.state.uploadStatus.pollingCount = 0;

        this.showErrorMessage(errorMessage, errorDescription);
        this.goToPrevSlide();
        this.hidePreparingProjectArea();
    }

    private respondToFormErrors(errors: FormErrors) {
        this.removeInlineErrorMessage(this.getFieldFormGroup('tags'));
        this.removeInlineErrorMessage(this.getFieldFormGroup('target_locales'));

        this.state.uploadStatus.pollingCount = 0;

        for (const [field, messages] of Object.entries(errors)) {
            this.showInlineErrorMessage(field, messages);
        }

        if (Object.keys(errors).includes('tags')) {
            this.goToPrevSlide();
        }

        this.hidePreparingProjectArea();
    }

    private respondToException(exception: Error) {
        Sentry.notify(exception);

        this.state.uploadStatus.pollingCount = 0;

        this.showErrorMessage(i18n.t('errors.general.error_message_advice_javascript'));
        this.goToPrevSlide();
        this.hidePreparingProjectArea();
    }

    private updateCountryFlag() {
        const localeTextAndCode = this.sourceLocaleSelectTarget.innerText.split(' - ');
        const localeText = localeTextAndCode[0];
        const localeCode = localeTextAndCode[1];

        this.sourceLocaleNameTarget.textContent = `${localeText} (${localeCode})`;

        this.sourceLocaleFlagImgTarget.classList.remove(
            `country-flag--${this.sourceLocaleFlagImgTarget.dataset.flagFlagcode}`
        );
        this.sourceLocaleFlagImgTarget.dataset.flagFlagcode = localeCode;
        this.sourceLocaleFlagImgTarget.classList.add(`country-flag--${localeCode}`);
    }

    /* data getters */

    private get finishProjectOnboardingPath(): string {
        return this.data.get('finishProjectOnboardingPath');
    }

    private get validateFilePath(): string {
        return this.data.get('validate-file-path');
    }

    private get cancelOnboardingPath(): string {
        return this.data.get('cancel-onboarding-path');
    }

    private get markOnboardingFinishedPath(): string {
        return this.data.get('mark-onboarding-finished-path');
    }
}
