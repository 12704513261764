import Sentry from "@utils/sentry";
import Redirect from "@utils/redirect";
import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        if (this.data.get("url") === "") {
            Sentry.notify("Missing redirect URL");
        }
    }

    trigger(event: Event) {
        const target = event.target as HTMLElement;
        const prevent = target.dataset.clickablePrevent == 'true';
        if (prevent) {
            return;
        }
        if (["A", "INPUT", "SELECT", "TEXTAREA"].includes(target.tagName)) {
            return;
        }

        let parent: HTMLElement = target.parentElement;
        while (parent) {
            if (parent.tagName == "A" || parent.dataset.clickablePrevent == 'true') {
                return;
            }
            parent = parent.parentElement;
        }

        const redirectUrl = this.data.get("url");
        if (redirectUrl !== "") {
            Redirect.go(redirectUrl);
        }
    }
}
