import { Controller } from "stimulus";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = ['text'];

    element: HTMLDivElement;
    textTarget: HTMLDivElement;

    private url: string;
    private runningText: string;
    private outOfQuotaText: string;
    private outOfQuotaLinkURL: string;
    private pollingCount: number;

    connect() {
        this.pollingCount = 0;
        this.url = this.data.get("url");
        this.runningText = this.data.get("running-text");
        this.outOfQuotaText = this.data.get("out-of-quota-text");
        this.outOfQuotaLinkURL = this.data.get("out-of-quota-link");
        this.checkInProgress();
    }

    checkInProgress(): void {
        this.pollingCount++;

        fetch(this.url, { credentials: "same-origin" })
            .then(response => response.json())
            .then(data => {
                if (data.autotranslation_running) {
                    this.updatePercentage(data);

                    if (this.pollingCount < 200) {
                        setTimeout(() => { this.checkInProgress(); }, 3000);
                    }
                } else if (data.out_of_quota_error) {
                    this.setFailure(data);
                } else {
                    this.remove();
                }
            })
            .catch((error) => { this.remove(); });
    }

    private remove(): void {
        DomUtils.remove(this.element);
        this.disconnect();
    }

    private updatePercentage(data): void {
        let title = this.runningText;
        if (data.keys_untranslated_percentage) {
            const progress = 100 - Number(data.keys_untranslated_percentage);
            title = `${this.runningText} (${progress} %)`;
        }
        this.element.setAttribute("title", title);
    }

    private setFailure(data): void {
        const link = document.createElement("a");
        link.setAttribute("href", this.outOfQuotaLinkURL);
        const oldChild = this.element.firstChild;
        this.element.setAttribute("title", this.outOfQuotaText);
        this.element.replaceChild(link, oldChild);
        link.appendChild(oldChild);

        this.textTarget.classList.add("autopilot-progress__text--error");
    }
}
