import { camelize } from "@utils/camelize";
import { Controller } from "stimulus";

export default class ModalController extends Controller {
    static targets = [
        "modal"
    ];

    protected modalTarget: HTMLDivElement;
    protected hasModalTarget: boolean;

    connect() {
        this.element[camelize(this.identifier)] = this;

        if (this.hasModalTarget) {
            if (this.data.get("autoShow")) {
                this.show();
            }

            (<any>window).$(this.modalTarget).on('hide.bs.modal', () => {
                this.modalTarget.dispatchEvent(new CustomEvent('jquery:modal:hide', { bubbles: true }));
            });
        }
    }

    disconnect() {
        if (this.hasModalTarget) {
            (<any>window).$(this.modalTarget).off('hide.bs.modal');
        }
    }

    show(event?: MouseEvent) {
        event?.preventDefault();

        if (this.hasModalTarget) {
            (<any>window).$(this.modalTarget).modal("show");
        }
    }

    hide(event?: MouseEvent) {
        event?.preventDefault();

        if (this.hasModalTarget) {
            (<any>window).$(this.modalTarget).modal("hide");
        }
    }
}
