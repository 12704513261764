export default class DomUtils {
    static empty(element: Element) { //same as jquery.empty()
        while (element.firstChild) element.removeChild(element.firstChild);
    }

    static remove(element: Element) { //same as NodeElement.remove() - not supported IE
        element.parentNode.removeChild(element);
    }

    static showElement(element: HTMLElement): void {
        element.classList.remove('hidden');
    }

    static hideElement(element: HTMLElement): void {
        element.classList.add('hidden');
    }

    static elementIsHidden(element: HTMLElement): boolean {
        return element.classList.contains('hidden');
    }

    static enableButton(button: HTMLButtonElement): void {
        button.disabled = false;
    }

    static disableButton(button: HTMLButtonElement): void {
        button.disabled = true;
    }
}
