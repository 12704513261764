import { i18n } from "../../i18n/config";
import { Controller } from "stimulus";
import DataUtils from "../../util/data_utils";
import Sentry from "@utils/sentry";

export default class extends Controller {

    static targets = [
        "createBranchButton",
        "modal",
        "nameField",
        "form",
        "formGroup",
        "errorMessage",
    ];

    createBranchButtonTarget: HTMLButtonElement;
    modalTarget: HTMLDivElement;
    nameFieldTarget: HTMLInputElement;
    formTarget: HTMLFormElement;
    formGroupTarget: HTMLDivElement;
    errorMessageTarget: HTMLSpanElement;

    connect() {
        this.createBranchButtonTarget.disabled = true;
        this.formTarget.addEventListener("submit", (e) => { e.preventDefault(); });
        this.nameFieldTarget.addEventListener("keyup", this.listenForNameFieldInput.bind(this));
    }

    openModal(event: Event): void {
        event.preventDefault();
        this.nameFieldTarget.value = "";
        (<any>window).$(this.modalTarget).modal("show");
        setTimeout(() => this.nameFieldTarget.focus(), 500);
    }

    async createBranchOnEnter(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            await this.createBranch(event);
        }
    }

    async createBranch(event: Event) {
        this.lockButton();
        this.errorMessageTarget.classList.add("hidden");
        this.formGroupTarget.classList.remove("has-error");

        await DataUtils.request(
            this.data.get("url"),
            { name: this.nameFieldTarget.value },
            { parse: false },
            { method: "POST" }
        ).then((response: Response) => {
            if (response.ok) {
                return (<any>window).location.reload();
            }
        }).catch(error => {
            if (error.status === 422) {
                return error.json();
            } else {
                this.showErrorMessage(i18n.t("branch_selector.create_branch_request_error"));
                this.unlockButton();
                Sentry.notify(error);
            }
        }).then(errors => {
            if (errors) {
                (errors["errors"] as string[]).forEach((error) => {
                    if (error["field"] === "name") {
                        this.showErrorMessage(error["message"]);
                    }
                });
                this.unlockButton();
            }
        });
    }

    private lockButton(): void {
        this.createBranchButtonTarget.disabled = true;
        this.createBranchButtonTarget.innerText = this.data.get("disabledButtonText");
    }

    private unlockButton(): void {
        this.createBranchButtonTarget.disabled = false;
        this.createBranchButtonTarget.innerText = this.data.get("buttonText");
    }

    private listenForNameFieldInput(event: Event): void {
        if (this.nameFieldTarget.value === "") {
            this.createBranchButtonTarget.disabled = true;
        } else {
            this.createBranchButtonTarget.disabled = false;
        }
    }

    private showErrorMessage(error: string) {
        this.formGroupTarget.classList.add("has-error");
        this.errorMessageTarget.classList.remove("hidden");

        const errorMessage = i18n.t(error);
        this.errorMessageTarget.innerText = errorMessage;
    }
}
