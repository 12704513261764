import { Controller } from 'stimulus';
import DomUtils from '@utils/dom_utils';



export default class extends Controller {
    static targets = [
        "assignedItemCount",
        "searchField",
        "assignedItems",
        "unassignedItems",
        "item",
    ];

    assignedItemCountTarget: HTMLElement;

    searchFieldTarget: HTMLInputElement;

    assignedItemsTarget: HTMLElement;
    unassignedItemsTarget: HTMLElement;

    itemTargets: HTMLElement[];

    remove(event: Event) {
        this.unassignedItemsTarget.prepend(this.itemTarget(event.currentTarget as HTMLElement));
        this.searchFieldTarget.focus();
    }

    add(event: Event) {
        this.assignedItemsTarget.prepend(this.itemTarget(event.currentTarget as HTMLElement));
        this.searchFieldTarget.focus();
    }

    toggle(event: Event) {
        if (this.unassignedItemsTarget.contains(event.currentTarget as HTMLElement)) {
            this.add(event);
        } else {
            this.remove(event);
        }
    }

    updateAssignedItemCount() {
        this.assignedItemCountTarget.innerText = String(this.assignedItemsTarget.children.length);
    }

    updateUnassignedItemsVisibility() {
        if (this.searchFieldTarget.value === "") {
            DomUtils.showElement(this.unassignedItemsTarget);
        }
    }

    preventDefault(event: Event) {
        event.preventDefault();
    }


    private itemTarget(target: HTMLElement): HTMLElement {
        return this.itemTargets.find(itemTarget => itemTarget.contains(target));
    }
}
