import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "watchedItem",
        "messageContainer",
        "contentContainer",
    ];

    contentContainerTarget: HTMLFormElement;
    messageContainerTarget: HTMLFormElement;
    watchedItemTargets: HTMLElement[];

    // TODO: Replace MutationObserver with callbacks (https://stimulus.hotwired.dev/reference/targets#connected-and-disconnected-callbacks)
    itemObserver: MutationObserver;

    connect() {
        this.itemObserver = new MutationObserver(this.updateListVisibility.bind(this));
        this.itemObserver.observe(this.element, {
            childList: true,
            attributes: false,
            subtree: true
        });
        this.updateListVisibility();
    }

    disconnect() {
        this.itemObserver.disconnect();
    }



    private updateListVisibility() {
        if(this.filterParamsAvailable) return;

        if (this.messageContainerTarget) {
            this.messageContainerTarget.classList.toggle("hidden", this.watchedItemTargets.length !== 0);
        }
        this.contentContainerTarget.classList.toggle("hidden", this.watchedItemTargets.length == 0);
    }

    private get filterParamsAvailable() {
        const nonSearchParamKeys = ["page", "sort", "order"];
  
        return this.filterParamsArray.some(([key, value]) => {
            return !nonSearchParamKeys.includes(key) && value !== "";
        });
    }

    private get filterParamsArray() {
        const location = window.location.href;
        const url = new URL(location);

        return Array.from(url.searchParams.entries());
    }
}
