import { Controller } from "stimulus";
import { ModalContainer } from '@utils/types';

export default class extends Controller {

    static targets = [
        "modal",
        "submit"
    ];

    element: HTMLDivElement;
    modalTarget: HTMLFormElement & ModalContainer;
    submitTarget: HTMLInputElement;

    show(event?: Event) {
        event?.preventDefault();
        this.modalTarget.modal.show();
        this.setButton();
    }

    setButton() {
        if (!this.forceReload() && this.isProjectLimitedRole()) {
            this.submitTarget.value = this.data.get("save-button-next");
        } else {
            this.submitTarget.value = this.data.get("save-button-default");
        }
    }

    saved() {
        let event = new CustomEvent('user:reload');
        if (!this.forceReload() && this.isProjectLimitedRole()) event = new CustomEvent('user:assign:projects');

        this.submitTarget.classList.add("disabled");
        this.modalTarget.dispatchEvent(event);
    }

    private forceReload() {
        return this.data.get("forceReload") === "true";
    }

    private get projectLimitedRoles() {
        const projectLimitedRoles = this.data.get("projectLimitedRoles");
        if (projectLimitedRoles === "undefined" || projectLimitedRoles === "") return [];
        return JSON.parse(projectLimitedRoles);
    }

    private get resource() {
        return this.data.get("resource");
    }

    private get selectedRole() {
        return this.modalTarget.elements[`${this.resource}[role]`].value;
    }

    private isProjectLimitedRole() {
        return this.projectLimitedRoles.indexOf(this.selectedRole) !== -1;
    }
}
