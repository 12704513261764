import { camelize } from "@utils/camelize";
import DomUtils from "@utils/dom_utils";
import { Controller } from "stimulus";

export interface ListResource {
    action: string;
    resource_name: string;
    resource_id: string;
    item_html: string;
    flash_message: string;
}

export default class ListViewController extends Controller {
    static targets = [
        "itemContainer",
        "list",
        "item",
        "empty",
    ];

    private itemContainerTarget: HTMLElement;
    private listTarget: HTMLElement;
    private itemTargets: HTMLElement[];
    private emptyTarget: HTMLElement;
    private hasEmptyTarget: boolean;
    private hasListTarget: boolean;

    connect() {
        this.element.addEventListener(`list-view-add-${this.modelName}`, this.add.bind(this));
        this.element.addEventListener(`list-view-update-${this.modelName}`, this.update.bind(this));
        this.element.addEventListener(`list-view-remove-${this.modelName}`, this.remove.bind(this));
    }

    disconnect() {
        this.element.removeEventListener(`list-view-add-${this.modelName}`, this.add.bind(this));
        this.element.removeEventListener(`list-view-update-${this.modelName}`, this.update.bind(this));
        this.element.removeEventListener(`list-view-remove-${this.modelName}`, this.remove.bind(this));
    }

    private add(event: CustomEvent) {
        if (this.hasEmptyTarget) {
            DomUtils.hideElement(this.emptyTarget);
        }

        if (this.hasListTarget) {
            DomUtils.showElement(this.listTarget);
        }

        const resource: ListResource = event.detail;
        this.itemContainerTarget.insertAdjacentHTML("beforeend", resource.item_html);
        this.highlightItem(this.itemContainerTarget.lastChild as HTMLElement);

        if (resource.flash_message) {
            this.renderFlashMessage(resource.flash_message);
        }
    }

    protected update(event: CustomEvent) {
        const resource: ListResource = event.detail;
        let item = this.getItem(resource.resource_name, resource.resource_id);
        item.outerHTML = resource.item_html;
        item = this.getItem(resource.resource_name, resource.resource_id);
        this.highlightItem(item);

        if (resource.flash_message) {
            this.renderFlashMessage(resource.flash_message);
        }
    }

    private remove(event: CustomEvent) {
        const resource: ListResource = event.detail;
        const item = this.getItem(resource.resource_name, resource.resource_id);
        item.parentNode.removeChild(item);

        if (resource.flash_message) {
            this.renderFlashMessage(resource.flash_message);
        }
    }

    private renderFlashMessage(message: string) {
        document.getElementById("flash-messages").innerHTML = message;
    }

    protected getItem(resourceName: string, id: string): HTMLElement {
        const attributeName = camelize(resourceName + "-id");
        return this.itemTargets.find((item) => item.dataset[attributeName] == id);
    }

    protected highlightItem(element: HTMLElement) {
        element.classList.add("animated", "animated-yellowfade");
    }

    private get modelName(): string {
        return this.data.get("model-name");
    }
}
