import { SynProductSwitcher, SynProductSwitcherApplication, SynProductSwitcherApplicationType, SynProductSwitcherTranslation } from "@phrase/syntax-ui";
import DataUtils from "@utils/data_utils";
import Sentry from "@utils/sentry";
import { Controller } from "stimulus";
import { createApp } from "vue/dist/vue.esm-bundler.js";
import { reactive, h } from "vue";
import { i18n } from "../i18n/config";

export default class extends Controller {
    static targets = ["mountElement"];
    mountElementTarget: HTMLElement;
    private attrs;

    async connect() {
        this.attrs = JSON.parse(this.data.get('attrs'));
        const props = reactive({
            companyName: this.attrs.companyName,
            translation: this.translation(),
            currentApplication: this.currentApplication(),
            applications: [],
            dashboardUrl: this.attrs.dashboardUrl
        });
        const app = createApp({
            render: () => h({
                template: this.template(),
                props: ["translation", "companyName", "dashboardUrl", "applications", "currentApplication"],
                methods: {
                    callback(e) {
                        const event = new CustomEvent("hide-sidebar");
                        window.dispatchEvent(event);
                    }
                }
            },
            props)
        });
        app.component("SynProductSwitcher", SynProductSwitcher);
        app.mount(this.mountElementTarget);

        await DataUtils.request(
            this.attrs.path,
            void 0,
            { parse: false },
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "X-Requested-With": "XMLHttpRequest" }
            }).then(async (data) => {
            const response = await data.json();
            props.applications = response.products;
        }).catch((err: string) => {
            Sentry.notify(err);
        });
    }

    private translation(): SynProductSwitcherTranslation {
        return {
            dashboard: i18n.t("product_switcher.dashboard"),
            startTrial: i18n.t("product_switcher.start_trial"),
            learnMore: i18n.t("product_switcher.learn_more"),
            tms: i18n.t("product_switcher.applications.tms"),
            strings: i18n.t("product_switcher.strings"),
            enable: i18n.t("product_switcher.enable"),
            upgrade: i18n.t("product_switcher.upgrade"),
            inactive: i18n.t("product_switcher.inactive"),
            currently: i18n.t("product_swticher.currently"),
        };
    }

    private currentApplication(): SynProductSwitcherApplication {
        return {
            title: "Strings",
            type: SynProductSwitcherApplicationType.STRINGS,
        };
    }

    private template(): string {
        return `<SynProductSwitcher @sidebar-toggle=callback v-bind="$props">
        <template #prefix >
        <svg width="32" height = "32" viewBox = "0 0 32 32" fill = "none" xmlns = "http://www.w3.org/2000/svg" >
        <rect width="32" height = "32" rx = "8" fill = "#03EAB3"/>
        <path d="M18.7954 6.07961H10.2412C9.64334 6.07961 9.15869 6.56425 9.15869 7.16216V20.6264C9.15869 21.2243 9.64334 21.709 10.2412 21.709H15.8861V19.6792H11.1885V8.1094H18.7954C19.8044 8.1094 20.6223 8.92727 20.6223 9.93621V17.8524C20.6223 18.8613 19.8044 19.6792 18.7954 19.6792H17.1716C17.0222 19.6792 16.901 19.8004 16.901 19.9498V21.4383C16.901 21.5879 17.0222 21.709 17.1716 21.709H18.7954C20.9254 21.709 22.652 19.9824 22.652 17.8524V9.93621C22.652 7.80628 20.9254 6.07961 18.7954 6.07961Z" fill = "#181818" />
        <path d="M14.1445 25.6942L9.96721 22.4888C9.43318 22.0791 9.12012 21.4443 9.12012 20.7712V7.16462C9.12012 6.26704 10.1496 5.75933 10.8617 6.30575L15.039 9.51112C15.573 9.92087 15.8861 10.5557 15.8861 11.2288V24.8354C15.8861 25.7329 14.8566 26.2406 14.1445 25.6942Z" fill = "white" />
        </svg>
        </template>
        </SynProductSwitcher>`;
    }
}
