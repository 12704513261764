import { Spinner } from 'spin.js';

export default class {
    readonly commonOptions = {
        color: '#808080',
        lines: 13,
        speed: 2.0,
        opacity: 0.25,
        trail: 60,
        zIndex: 0
    };

    readonly flavors = {
        "default": {
            length: 8,
            width: 5,
            radius: 20,
            scale: 0.5
        },
        "small": {
            length: 7,
            width: 4,
            radius: 10,
            scale: 0.4
        }
    };

    private spinner: any;
    private target: Element;
    private flavor: string;
    private options: object;

    constructor(target: Element, flavor = "default", options = {}) {
        // important: make sure the target element is positioned relative
        this.target = target;
        this.flavor = flavor;
        this.options = options;
        this.spinner = this.buildSpinner();
    }

    start(): void {
        this.spinner.spin(this.target);
    }

    stop(): void {
        this.spinner.stop();
    }

    destroy() {
        const element = this.spinner.el;
        if (element) {
            element.parentNode.removeChild(element);
        }
    }

    private buildSpinner(): any {
        const spinnerOptions = {
            ...this.commonOptions,
            ...this.flavors[this.flavor],
            ...this.options
        };
        return new Spinner(spinnerOptions);
    }
}
