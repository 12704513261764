import { Controller } from 'stimulus';
import Sentry from "@utils/sentry";
import DataUtils from '@utils/data_utils';

export default class extends Controller {
    static targets = [
        'email',
        'form',
        'submit',
    ];

    emailTarget: HTMLInputElement;
    formTarget: HTMLFormElement;
    submitTarget: HTMLButtonElement;

    validate(e: Event) {
        e.preventDefault();

        this.requestValidations()
            .then(parsedResponse => {
                this.removeValidationBox();

                if (parsedResponse.status === 'ok') {
                    this.formTarget.submit();
                } else {
                    this.showValidationBox(parsedResponse.errors);
                }
            }).catch(err => {
                Sentry.notify(err);
            });
    }

    removeValidationBox() {
        this.emailFormGroup.classList.remove('has-error');

        const existingHelpBlock = this.emailFormGroup.querySelector('.help-block');
        if (existingHelpBlock) existingHelpBlock.remove();
    }

    private showValidationBox(errors: Array<string>) {
        const validationBoxElement = document.createElement('span');
        validationBoxElement.classList.add('help-block', 'error');
        validationBoxElement.innerHTML = errors[0];

        this.emailFormGroup.append(validationBoxElement);
        this.emailFormGroup.classList.add('has-error');
    }

    private requestValidations() {
        return DataUtils.request(
            this.data.get('validation-url'),
            { signup: { email: this.email } },
            { parse: false },
            { method: 'POST' },
        ).then(response => response.json());
    }

    private get email(): string {
        return this.emailTarget.value;
    }

    private get emailFormGroup(): HTMLElement {
        return this.emailTarget.closest('.form-group');
    }
}
