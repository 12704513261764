import { Controller } from "stimulus";
import { i18n } from "../i18n/config";


export default class BatchSelectionController extends Controller {
    private readonly paginationCount = 25;

    static targets = [
        'item',
        'selectionScope',
        'selectionScopeValue',
        'selectionCountInfo',
        'modalBodyText',
        'deleteButton'
    ];

    itemTargets: HTMLInputElement[];
    selectionScopeTarget: HTMLElement;
    selectionScopeValueTarget: HTMLInputElement;
    selectionCountInfoTarget: HTMLElement;
    modalBodyTextTarget:  HTMLElement;
    deleteButtonTarget: HTMLButtonElement;

    hasModalBodyTextTarget: boolean;
    translationKey: string;
    deleteConfirmationModalKey: string;
    totalCount: number;
    affectedCount: number;


    connect() {
        this.translationKey = this.data.get('translation-key');
        this.deleteConfirmationModalKey = this.data.get('confirmation-text');
        this.totalCount = parseInt(this.data.get("total-count"));
        this.affectedCount = parseInt(this.data.get("count"));
    }

    toggleScope(event) {
        if (this.totalCount > this.paginationCount) {
            if(event.target.checked){
                this.selectionScopeTarget.classList.remove("hidden");
            } else {
                this.selectionScopeTarget.classList.add("hidden");
            }
        }

        if(event.target.checked){
            this.itemTargets.filter(item => item.checked = true);
        } else {
            this.itemTargets.filter(item => item.checked = false);
        }

        this.updateSelectionInfo(event);
    }

    updateSelectionInfo(event) {
        if (!event.target.checked) {
            this.selectionScopeValueTarget.checked = false;
            this.selectionScopeTarget.classList.add("hidden");
        }

        let selectedCount = 0;
        if (this.selectionScopeValueTarget.checked) {
            selectedCount = this.affectedCount;
        } else {
            selectedCount = this.itemTargets.filter(item => item.checked).length;
        }

        if (this.hasModalBodyTextTarget) {
            if (selectedCount === 0) {
                this.disableDeleteButton();
            } else {
                this.enableDeleteButton();
                this.modalBodyTextTarget.innerHTML = i18n.t(this.deleteConfirmationModalKey, { count: selectedCount });
            }
        }
        this.selectionCountInfoTarget.innerHTML = i18n.t(this.translationKey, { selected_count: selectedCount, total_count: this.totalCount });
    }

    private disableDeleteButton() {
        this.deleteButtonTarget.disabled = true;
    }

    private enableDeleteButton() {
        this.deleteButtonTarget.disabled = false;
    }
}
