import { Controller } from "stimulus";

export default class extends Controller {

    static targets = [
        "radioSelectLocales",
        "localesSelectField",
    ];

    private radioSelectLocalesTarget: HTMLInputElement;
    private localesSelectFieldTarget: HTMLDivElement;

    connect() {
        this.showLocalesSelection();
    }

    handleRadioButtonChange(event: Event): void {
        this.showLocalesSelection();
    }

    private showLocalesSelection(): void {
        if (this.radioSelectLocalesTarget.checked) {
            this.localesSelectFieldTarget.classList.remove("hidden");
        } else {
            this.localesSelectFieldTarget.classList.add("hidden");
        }
    }

}
