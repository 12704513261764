import { Controller } from "stimulus";

export default class extends Controller {
    handleSuccess(e: CustomEvent) {
        this.element.dispatchEvent(
            new CustomEvent('subscription-checkout:success', {
                detail: {
                    hostedPage: e.detail.xhr.responseJSON.hosted_page
                }
            })
        );
    }

    handleError(e: CustomEvent) {
        console.error("Checkout Error:", e.detail.xhr.responseJSON.errors);
        document.getElementById("flash-messages").innerHTML = e.detail.xhr.responseJSON.flash_message;
    }
}
