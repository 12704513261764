import { Controller } from "stimulus";
import Sentry from "@utils/sentry";
import DomUtils from "@utils/dom_utils";
import { selectizeOf } from "@utils/selectize";

export default class extends Controller {
    static targets = [
        "locales",
        "deleteLocale",
        "defaultDropdown",
        "defaultNameField",
        "localeNameField",
        "additionalDropdown",
    ];

    private localesTarget: HTMLElement;
    private deleteLocaleTargets: [HTMLElement];
    private nextIndex: number;
    private defaultDropdownTarget: HTMLInputElement;
    private defaultNameFieldTarget: HTMLInputElement;
    private localeNameFieldTargets: HTMLInputElement[];
    private additionalDropdownTargets: HTMLElement[];

    connect() {
        this.nextIndex = 1;
    }

    prefillDefaultLocaleName() {
        if (this.defaultNameFieldTarget.dataset.changed !== "changed") {
            this.defaultNameFieldTarget.value = this.defaultDropdownTarget.value;
        }
    }

    defaultNameFieldChanged() {
        this.defaultNameFieldTarget.dataset.changed = "changed";
    }

    localeNameFieldChanged(event: Event) {
        (<HTMLInputElement>event.currentTarget).dataset.changed = "changed";
    }

    addLocaleClicked(event: Event) {
        event.preventDefault();

        fetch(`${this.data.get("other-locale-form-url")}?index=${this.nextIndex}`, {
            credentials: "same-origin"
        })
            .then((response) => response.text())
            .then((html) => {
                const element = document.createElement("div");
                element.innerHTML = html;
                element
                    .firstElementChild
                    .getElementsByClassName("other-locale-form-fields")[0]
                    .childNodes
                    .forEach((node) => this.localesTarget.appendChild(node));

                if (this.deleteLocaleTargets.length > 0) {
                    this.showDeleteButtons();
                }

                selectizeOf(this.additionalDropdownTargets[this.additionalDropdownTargets.length - 1])
                    .then((selectize) => selectize.focus())
                    .catch(err => {
                        Sentry.notify(err);
                    });

                this.nextIndex = this.nextIndex + 1;
            })
            .catch(err => {
                Sentry.notify(err);
            });
    }

    prefillName(event) {
        const localeCode = event.target.value;
        const index = event.target.dataset.index;

        this.localeNameFieldTargets.forEach((field) => {
            if (field.dataset.index === index && field.dataset.changed !== "changed") {
                field.value = localeCode;
            }
        });
    }

    deleteLocaleClicked(event: Event) {
        event.preventDefault();

        const index = (<HTMLElement>event.currentTarget).dataset.index;
        DomUtils.remove(document.querySelector(`[data-target="locale-form.locale-${index}"]`));

        window.requestAnimationFrame(() => {
            if (this.deleteLocaleTargets.length === 1) {
                this.hideDeleteButtons();
            }
        });
    }


    private showDeleteButtons() {
        this.deleteLocaleTargets.forEach(DomUtils.showElement);
    }

    private hideDeleteButtons() {
        this.deleteLocaleTargets.forEach(DomUtils.hideElement);
    }
}
