import { Controller } from 'stimulus';
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = [
        "filterRowContainer",
        "filterRowIndex",
        "selectLabelUrl"
    ];

    filterRowContainerTarget: any;
    filterRowIndexTarget: HTMLInputElement;
    selectLabelUrlTarget: HTMLInputElement;

    selectLabel(event: Event) {
        const metaDataLabelSelect = event.currentTarget as HTMLInputElement;
        const selectedLabel = metaDataLabelSelect.value;

        if(selectedLabel) {
            const customMetadataFieldFrame = metaDataLabelSelect.closest("[data-target='advanced-search-filter.filterRowContainer']") as HTMLInputElement;
            const selectLabelIndexField = customMetadataFieldFrame.querySelector("[data-target='advanced-search-filter.filterRowIndex']") as HTMLInputElement;
            const selectLabeIndex = selectLabelIndexField.value;
            const selectLabelUrlField = customMetadataFieldFrame.querySelector("[data-target='advanced-search-filter.selectLabelUrl']") as HTMLInputElement;
            const selectLabelUrl = selectLabelUrlField.value;
            customMetadataFieldFrame.src = `${selectLabelUrl}?index=${selectLabeIndex}&label_code=${selectedLabel}`;
        }
    }

    removeFilterRow(event: Event) {
        event.preventDefault();
        const metaDataLabelSelect = event.currentTarget as HTMLInputElement;
        const customMetadataFieldFrame = metaDataLabelSelect.closest("[data-target='advanced-search-filter.filterRowContainer']") as HTMLInputElement;
        const selectLabelIndexField = customMetadataFieldFrame.querySelector("[data-target='advanced-search-filter.filterRowIndex']") as HTMLInputElement;
        const selectLabeIndex = selectLabelIndexField.value;

        if(parseInt(selectLabeIndex) !== 0) {
            DomUtils.remove(customMetadataFieldFrame);
        }
    }
}
