import { Controller } from "stimulus";
import DataUtils from "@utils/data_utils";
import { debounce } from "@utils/debounce";

export default class extends Controller {

    static targets = [ 'nameInput', 'results' ];

    nameInputTarget: HTMLInputElement;
    resultsTarget: HTMLUListElement;

    connect() {
        if (this.hasResults) this.resultsTarget.hidden = true;
    }

    onInputBlur() {
        if (this.hasResults) this.resultsTarget.hidden = true;
    }

    onInputFocus() {
        if (this.hasResults) this.resultsTarget.hidden = false;
    }

    onKeydown(event) {
        switch (event.key) {
            case 'Escape':
                if (!this.resultsTarget.hidden) {
                    this.hideAndRemoveOptions();
                    event.stopPropagation();
                    event.preventDefault();
                }
                break;
            case 'ArrowDown':
                {
                    this.navigateNext();
                    event.preventDefault();
                }
                break;
            case 'ArrowUp':
                {
                    const item = this.sibling(false);
                    if (item) this.select(item);
                    event.preventDefault();
                }
                break;
            case 'Tab':
                {
                    this.navigateNext();
                    event.preventDefault();
                }
                break;
            case 'Enter':
                {
                    const selected = this.resultsTarget.querySelector('[aria-selected="true"]');
                    if (selected && !this.resultsTarget.hidden) {
                        this.commit(selected);
                        event.preventDefault();
                    }
                }
                break;
        }
    }

    navigateNext() {
        const item = this.sibling(true);
        if (item) this.select(item);
    }

    sibling(next) {
        const options = Array.from(this.resultsTarget.querySelectorAll('[role="option"]'));
        const selected = this.resultsTarget.querySelector('[aria-selected="true"]');
        const index = options.indexOf(selected);
        const sibling = next ? options[index + 1] : options[index - 1];
        const def = next ? options[0] : options[options.length - 1];
        return sibling || def;
    }

    select(target) {
        const selectedItems = Array.from(this.resultsTarget.querySelectorAll('[aria-selected="true"]'));
        for (const el of selectedItems) {
            el.removeAttribute('aria-selected');
            el.classList.remove('active');
        }
        target.setAttribute('aria-selected', 'true');
        target.classList.add('active');
        this.nameInputTarget.setAttribute('aria-activedescendant', target.id);
    }

    commit(selected) {
        if (selected.getAttribute('aria-disabled') === 'true') return;

        if (selected instanceof HTMLAnchorElement) {
            selected.click();
            this.resultsTarget.hidden = true;
            return;
        }

        const textValue = selected.textContent.trim();
        const value = selected.getAttribute('data-suggested-value') || textValue;
        this.nameInputTarget.value = value;

        this.nameInputTarget.focus();
        this.hideAndRemoveOptions();
        this.fetchResults();
    }

    onInputChange() {
        debounce(() => {
            this.element.removeAttribute('value');
            this.fetchResults();
        }, 400);
    }

    fetchResults() {
        const query = this.nameInputTarget.value.trim();

        if (!query || query.length < this.minLength) {
            this.hideAndRemoveOptions();
            return;
        }

        if (!this.src) return;

        const url = new URL(this.src, window.location.href);
        const params = new URLSearchParams(url.search.slice(1));
        params.append('q', query);
        params.append('per_page', '5');
        url.search = params.toString();

        DataUtils.request(url.toString())
            .then(data => {
                this.resultsTarget.innerHTML = this.buildHTML(data, query);
                this.resultsTarget.hidden = !this.hasResults;

                if (this.hasResults) {
                    const item = this.sibling(true);
                    if (item) this.select(item);
                }
            })
            .catch((error) => {
                // on server error its logged by rails
                // other (4xx or offline) errors could be handled through an error alert
                console.log(error);
            });
    }

    buildHTML(data: [], query) {
        let html = '';
        const enter_badge = "<span class='badge'>Enter</span>";

        for (const key of data) {
            if (key == query) continue;

            let key_name: string = key;

            const index = key_name.indexOf(query);
            if (index >= 0) {
                key_name = key_name.substring(0, index) +
                    "<strong>" +
                    key_name.substring(index, index+query.length) +
                    "</strong>" +
                    key_name.substring(index + query.length);
            }
            html += "<li class='list-group-item' data-role='list-item' data-suggested-value='" + (key as string) + "' role='option'>" + key_name + enter_badge + '</li>';
        }

        return html;
    }

    hideAndRemoveOptions() {
        this.resultsTarget.hidden = true;
        this.resultsTarget.innerHTML = null;
    }

    get hasResults() {
        return !!this.resultsTarget.querySelector('[role="option"]');
    }

    get src() {
        return this.data.get("url");
    }

    get minLength() {
        const minLength = this.data.get("min-length");
        if ( !minLength ) return 0;

        return parseInt(minLength, 10);
    }
}
