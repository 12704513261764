import { Controller } from 'stimulus';
import Tribute from "tributejs";
import DataUtils from "../../util/data_utils";
import Sentry from "@utils/sentry";
import escapeHTML from "escape-html";

export default class extends Controller {
    private tribute: Tribute<HTMLElement>;
    private cache: object;

    connect() {
        this.cache = {};
        const options = {
            values: async (text: string, callback) => {
                if (!this.cache[text]) {
                    const items = await DataUtils.request(
                        this.data.get("url"), {q: text}, { parse: false }
                    ).then((response: Response) => {
                        return response.json();
                    }).catch(error => {
                        Sentry.notify(error);
                    });
                    this.cache[text] = items;
                }
                let elements = this.cache[text];
                if ("job".includes(text)) {
                    elements = [{
                        name: this.data.get("allLabel"),
                        username: "job",
                    }].concat(elements);
                }
                callback(elements);
            },
            lookup: (item: any, _mentionText: string) => {
                return `${item.username}${item.name}`;
            },
            fillAttr: "username",
            noMatchTemplate: () => { return null; },
            menuItemTemplate: (item: any) => {
                return `${escapeHTML(item.original.username)} <small>${escapeHTML(item.original.name)}</small>`;
            },
        };
        this.tribute = new Tribute(options);
        this.tribute.attach(this.element);
    }

    disconnect() {
        this.tribute.detach(this.element);
    }
}
