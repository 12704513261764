import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        (<any>window).$(this.element).on("shown.bs.dropdown", this.positionDropdown);
        (<any>window).$(this.element).on("show.bs.dropdown", () => { this.dispatchDropdownEvent("jquery:dropdown:show"); });
        (<any>window).$(this.element).on("shown.bs.dropdown", () => { this.dispatchDropdownEvent("jquery:dropdown:shown"); });
        (<any>window).$(this.element).on("hide.bs.dropdown", () => { this.dispatchDropdownEvent("jquery:dropdown:hide"); });
        (<any>window).$(this.element).on("hidden.bs.dropdown", () => { this.dispatchDropdownEvent("jquery:dropdown:hidden"); });
    }

    disconnect() {
        (<any>window).$(this.element).off("shown.bs.dropdown", this.positionDropdown);
        (<any>window).$(this.element).off("show.bs.dropdown", () => { this.dispatchDropdownEvent("jquery:dropdown:show"); });
        (<any>window).$(this.element).off("shown.bs.dropdown", () => { this.dispatchDropdownEvent("jquery:dropdown:shown"); });
        (<any>window).$(this.element).off("hide.bs.dropdown", () => { this.dispatchDropdownEvent("jquery:dropdown:hide"); });
        (<any>window).$(this.element).off("hidden.bs.dropdown", () => { this.dispatchDropdownEvent("jquery:dropdown:hidden"); });
    }

    preventClose(_) {
        (<any>window).$(this.element).one("hide.bs.dropdown", () => {
            return false;
        });
    }

    positionDropdown(event: Event) {
        const target = <HTMLElement>event.currentTarget;
        const scrollableList = target.closest('.resource-list__wrapper') as HTMLElement;
        const itemContainer = target.closest('.resource-list__item-container') as HTMLElement;

        if (scrollableList && itemContainer) {
            scrollableList.scroll({
                top: itemContainer.offsetTop - scrollableList.offsetTop,
                behavior: 'smooth'
            });
        }
    }

    private dispatchDropdownEvent(eventName: string): void {
        this.element.dispatchEvent(new CustomEvent(eventName, { bubbles: true }));
    }
}
