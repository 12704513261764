import { Controller } from "stimulus";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = [
        'priceContainer',
    ];

    private priceContainerTargets: HTMLInputElement[];

    updatePrice(e: CustomEvent) {
        this.priceContainerTargets.forEach((priceContainerTarget) => {
            if (priceContainerTarget.dataset.billingCycle == e.detail.billingCycle && priceContainerTarget.dataset.currency == e.detail.currency) {
                DomUtils.showElement(priceContainerTarget);
            } else {
                DomUtils.hideElement(priceContainerTarget);
            }
        });
    }
}
