import { Controller } from "stimulus";
import { i18n } from "../../i18n/config";
import interact from "interactjs";
import DomUtils from '@utils/dom_utils';
export default class extends Controller {
    static targets = [
        "screenshotCanvas",
    ];

    private screenshotCanvasTarget: HTMLElement;
    private markersObject = {};
    private activeMarker: HTMLElement;

    connect() {
        const customEvent = new CustomEvent('markers-controller-connected', { bubbles: true });
        this.screenshotCanvasTarget.dispatchEvent(customEvent);
    }

    attachKey() {
        this.createMarker({
            markerX: this.screenshotCanvasX() + 50,
            markerY: this.screenshotCanvasY() + 50,
            markerWidth: 100,
            markerHeight: 100,
            markerTranslationKeyId: "",
            markerTranslationKeyName: "",
            markerRemove: ""
        });

        this.dispatchUpdateMarkers();
        this.dispatchEnableButtons();
    }

    createMarkerFromEvent(event: CustomEvent) {
        const marker = event.detail.marker;

        this.markersObject[marker.markerTranslationKeyId] = marker;
        this.createMarker(marker, event.detail.noFocus);
    }

    addSuggestableValue(event) {
        if (event.detail) {
            const markerElement = event.detail.markerElement;
            const translationKeyId = event.detail.translationKeyId;
            const translationKeyName = event.detail.translationKeyName;

            markerElement.dataset.markerTranslationKeyId = translationKeyId;
            markerElement.dataset.markerTranslationKeyName = translationKeyName;

            markerElement.dataset.markerModalUrl = this.generateMarkerEditUrl(translationKeyId);
            this.dispatchUpdateMarkers();
            this.dispatchEnableButtons();
        }
    }

    async generateOcr(event: Event) {
        event.preventDefault();
        const ocrUrl = this.data.get('ocr-path');

        const result = await fetch(ocrUrl);
        const data = await result.json() as {
            content: string;
            key_code?: string;
            key_name?: string;
            vertices: { x: number; y: number }[];
        }[];

        let isAnyMarkerSaveable = false;
        data.forEach(marker => {
            const markerObj = {
                markerX: marker.vertices[0].x,
                markerY: marker.vertices[0].y,
                markerWidth: marker.vertices[1].x - marker.vertices[0].x,
                markerHeight: marker.vertices[2].y - marker.vertices[1].y,
                markerTranslationKeyName: '',
                markerTranslationKeyId: "",
                markerModalUrl: "",
                markerRemove: "",
                markerContent: marker.content,
            };

            if (marker.key_code) {
                const existingMarker = this.markersObject[marker.key_code];

                if (existingMarker) {
                    DomUtils.remove(this.element.querySelector(`[data-marker-id="${existingMarker.markerId}"]`));
                    Object.assign(markerObj, {
                        markerId: existingMarker.markerId
                    });
                }

                Object.assign(markerObj, {
                    markerTranslationKeyName: marker.key_name,
                    markerTranslationKeyId: marker.key_code,
                    markerModalUrl: this.generateMarkerEditUrl(marker.key_code)
                });

                isAnyMarkerSaveable = true;
            }
            this.createMarkerDomElement(markerObj, true);
        });
        if (isAnyMarkerSaveable) {
            this.dispatchUpdateMarkers(true);
            this.dispatchEnableButtons();
        }
    }

    updateKeyName(e: CustomEvent) {
        const markerElement = e.target as HTMLElement;
        this.recreateMarkerWithNewKeyname(e.detail, markerElement);
    }

    onMarkerEdit(e: Event) {
        this.activeMarker = (e.currentTarget as HTMLElement).parentElement.parentElement;
    }

    updateEditMarker(e: CustomEvent) {
        this.recreateMarkerWithNewKeyname(e.detail, this.activeMarker);
    }

    private generateMarkerEditUrl(keyId: string) {
        return this.data.get('modal-url').replace('CODE', keyId);
    }
    private recreateMarkerWithNewKeyname({ translationKeyName }, element: HTMLElement) {
        const markerObject = this.markersObject[element.dataset.markerTranslationKeyId];
        markerObject.markerTranslationKeyName = translationKeyName;
        DomUtils.remove(element);
        this.createMarkerDomElement(markerObject);
    }

    private createMarker(marker, noFocus = false) {
        this.createMarkerDomElement(marker, noFocus);
    }

    private createMarkerDomElement(marker, noFocus = false) {
        const markerElementId = `screenshots-edit__marker-item-${this.randomId()}`;
        const markerElement = document.createElement("div");

        markerElement.id = markerElementId;
        markerElement.classList.add("screenshot-marker");
        markerElement.style.top = `${marker.markerY}px`;
        markerElement.style.left = `${marker.markerX}px`;
        markerElement.style.width = `${marker.markerWidth}px`;
        markerElement.style.height = `${marker.markerHeight}px`;
        markerElement.setAttribute("data-role", "screenshots-edit-marker-item");
        markerElement.setAttribute("data-marker-id", marker.markerId || "");
        markerElement.setAttribute("data-marker-x", marker.markerX);
        markerElement.setAttribute("data-marker-y", marker.markerY);
        markerElement.setAttribute("data-marker-width", marker.markerWidth);
        markerElement.setAttribute("data-marker-height", marker.markerHeight);
        markerElement.setAttribute("data-marker-translation-key-id", marker.markerTranslationKeyId);
        markerElement.setAttribute("data-marker-translation-key-name", marker.markerTranslationKeyName);
        markerElement.setAttribute("data-marker-remove", marker.markerRemove);
        markerElement.setAttribute("data-marker-modal-url", marker.markerModalUrl);
        markerElement.setAttribute("data-marker-content", marker.markerContent || "");
        markerElement.setAttribute("data-marker-editor-link", marker.markerEditorLink || "");

        this.addCloseButtonToMarkerElement(markerElement);
        this.addResizeButtonToMarkerElement(markerElement);
        this.handleDraggableMarkerEvents(markerElement);

        this.screenshotCanvasTarget.appendChild(markerElement);

        if (!marker.markerTranslationKeyId) {
            this.addTranslationKeySuggestionField(markerElement, markerElementId, noFocus);
            markerElement.setAttribute("data-action", "screenshots-markers-insert-suggestable->screenshots--markers#addSuggestableValue");
        } else {
            this.addTranslationKeyNameElement(markerElement);
        }

        return markerElement;
    }

    private randomId() {
        return Math.floor((Math.random() * 999999) + 1);
    }

    private addCloseButtonToMarkerElement(markerElement) {
        const closeButton = document.createElement("i");
        closeButton.setAttribute("data-action", "click->screenshots--editor#removeMarker");
        closeButton.classList.add("screenshot-marker__close");
        closeButton.classList.add("material-icons");
        closeButton.innerHTML = "close";
        markerElement.appendChild(closeButton);
    }

    private addResizeButtonToMarkerElement(markerElement) {
        const resizeButton = document.createElement("i");
        resizeButton.classList.add("screenshot-marker__resize");
        resizeButton.classList.add("material-icons");
        resizeButton.innerHTML = "arrow_drop_up";
        markerElement.appendChild(resizeButton);
    }

    private addTranslationKeySuggestionField(markerElement, markerElementId, noFocus: boolean) {
        const suggestWrap = document.createElement("div");
        suggestWrap.classList.add("screenshot-marker__suggestion-wrap");
        markerElement.appendChild(suggestWrap);

        const suggestField = document.createElement("input");
        suggestField.classList.add("screenshot-marker__suggestion-field");
        suggestField.setAttribute("type", "text");
        suggestField.setAttribute("placeholder", i18n.t("screenshots.update.suggestions_placeholder"));
        suggestField.setAttribute("value", markerElement.dataset.markerTranslationKeyName);
        suggestField.setAttribute("data-role", "screenshots-edit-marker-suggestion-field");
        suggestField.setAttribute("data-controller", "screenshots--marker-suggestable");
        suggestField.setAttribute("data-screenshots--marker-suggestable-screenshot-marker-id", markerElementId);
        suggestField.setAttribute("data-screenshots--marker-suggestable-url", this.data.get("suggestions-url"));
        suggestField.setAttribute("data-suggestions-accept-space-bar", "true");

        // Add Key
        if (this.data.get("add-key-allowed") === "true") {
            suggestField.setAttribute("data-controller", "screenshots--marker-suggestable screenshots--add-key");
            suggestField.setAttribute("data-target", "screenshots--add-key.suggestableField");
            suggestField.setAttribute("data-screenshots--add-key-modal-url", this.data.get("add-key-modal-url"));
            suggestField.setAttribute("data-screenshots--add-key-content", markerElement.dataset.markerContent);
        }

        suggestWrap.appendChild(suggestField);
        if (!noFocus) suggestField.focus();
    }

    private addTranslationKeyNameElement(markerElement) {
        const keyName = markerElement.dataset.markerTranslationKeyName;
        const editorLink = markerElement.dataset.markerEditorLink;
        const translationKeyElement = document.createElement("div");
        const translationKeyLink = document.createElement("a");

        translationKeyElement.classList.add("screenshot-marker__translation-key-name");
        translationKeyLink.href = editorLink;
        translationKeyLink.innerText = keyName;
        translationKeyElement.appendChild(translationKeyLink);
        markerElement.setAttribute('data-action', 'screenshots-markers-update-keyname->screenshots--markers#updateKeyName');

        let modalUrl = markerElement.dataset.markerModalUrl;
        modalUrl = `${modalUrl}&name=${keyName}&screenshot_marker_id=${markerElement.dataset.markerId}`;

        const editKeyButtonElement = document.createElement('a');
        editKeyButtonElement.href = modalUrl;
        editKeyButtonElement.classList.add("btn");
        editKeyButtonElement.classList.add("btn-primary");
        editKeyButtonElement.classList.add("btn-xs");
        editKeyButtonElement.classList.add("modalized");
        editKeyButtonElement.setAttribute("data-modal-flavor", "large-form");
        editKeyButtonElement.setAttribute("data-modal-submit-button-label", i18n.t("general.save"));
        editKeyButtonElement.style.marginLeft = "5px";
        editKeyButtonElement.setAttribute('data-action', 'click->screenshots--markers#onMarkerEdit');

        const icon = document.createElement("i");
        icon.classList.add("material-icons");
        icon.innerHTML = "edit";
        editKeyButtonElement.appendChild(icon);

        translationKeyElement.appendChild(editKeyButtonElement);


        markerElement.appendChild(translationKeyElement);
    }

    private handleDraggableMarkerEvents(markerElement) {
        interact(markerElement).draggable({
            onmove: this.handleMarkerDrag,
            onend: (event) => {
                const target = event.target;
                const parentRectangle = target.parentNode.getBoundingClientRect();

                const parentX = event.clientX - parentRectangle.left;
                const parentY = event.clientY - parentRectangle.top;

                const rectangle = target.getBoundingClientRect();
                const eventTargetX = event.clientX - rectangle.left;
                const eventTargetY = event.clientY - rectangle.top;

                target.setAttribute('data-marker-x', Math.floor(parentX - eventTargetX));
                target.setAttribute('data-marker-y', Math.floor(parentY - eventTargetY));
                this.dispatchUpdateMarkers();
                this.dispatchEnableButtons();
            },
            modifiers: [
                interact.modifiers.restrictRect({
                    restriction: 'parent',
                    elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
                })
            ],
        })
            .resizable({
                modifiers: [
                    interact.modifiers.restrictEdges({
                        outer: 'parent',
                        endOnly: true,
                    }),
                    interact.modifiers.restrictSize({
                        min: { width: 30, height: 30 },
                    })
                ],
                edges: {
                    bottom: '.screenshot-marker__resize',
                    right: '.screenshot-marker__resize',
                },
                onmove: this.handleMarkerResize,
                onend: () => {
                    this.dispatchUpdateMarkers();
                    this.dispatchEnableButtons();
                }
            });
    }

    private handleMarkerDrag(event) {
        const originalPositionMarkerX = event.clientX0;
        const originalPositionMarkerY = event.clientY0;
        const target = event.target;

        const shiftPlacementX = event.clientX - originalPositionMarkerX;
        const shiftPlacementY = event.clientY - originalPositionMarkerY;

        target.style.webkitTransform = target.style.transform = `translate(${shiftPlacementX}px, ${shiftPlacementY}px)`;
    }

    private handleMarkerResize(event) {
        const target = event.target;
        const x = (Number(target.getAttribute('data-dragdrop-x')) || 0);
        const y = (Number(target.getAttribute('data-dragdrop-y')) || 0);

        target.style.width = `${event.rect.width}px`;
        target.style.height = `${event.rect.height}px`;
        target.style.webkitTransform = target.style.transform = `translate(${x}px, ${y}px)`;

        target.setAttribute('data-dragdrop-x', Math.floor(x));
        target.setAttribute('data-dragdrop-y', Math.floor(y));
        target.setAttribute('data-marker-width', Math.floor(event.rect.width));
        target.setAttribute('data-marker-height', Math.floor(event.rect.height));
    }

    private dispatchUpdateMarkers(noFocus = false) {
        const customEvent = new CustomEvent('update-markers', { bubbles: true, detail: { noFocus } });
        this.screenshotCanvasTarget.dispatchEvent(customEvent);
    }

    private dispatchEnableButtons() {
        const customEvent = new CustomEvent('enable-buttons', { bubbles: true });
        this.screenshotCanvasTarget.dispatchEvent(customEvent);
    }

    private screenshotCanvasX(): number {
        return (parseInt(this.screenshotCanvasTarget.dataset.screenshotX) || 0) * -1;
    }

    private screenshotCanvasY(): number {
        return (parseInt(this.screenshotCanvasTarget.dataset.screenshotY) || 0) * -1;
    }
}
