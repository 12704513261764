import { bootstrapWhiteList } from "@utils/bootstrap_white_list";
import { Controller } from "stimulus";
import DomUtils from "../util/dom_utils";

export default class extends Controller {
    private popover: any;
    private popoverId: string;

    connect() {
        const options = {
            content: this.data.get("content"),
            html: this.data.get("html") === 'true',
            container: 'body',
            placement: this.data.get("placement") || "bottom",
            whiteList: bootstrapWhiteList,
            trigger: this.data.get("trigger") || "click",
        };

        this.popover = (<any>window).$(this.element).popover(options);
        // in order to be able to still click on links
        // where only hover is used as a trigger we need to
        // only preventDefaults for usage with click as trigger
        if (options.trigger == "click" ) {
            this.popover.on("click", (event: Event) => {
                event.preventDefault();
                (<any>window).$(this.element).tooltip("destroy");
            });
        }
        this.popover.on("inserted.bs.popover", (_event: Event) => {
            this.popoverId = this.element.getAttribute("aria-describedby");
        });
    }

    disconnect() {
        this.popover.popover('destroy');
        // @HACK: Fix popover removal
        // Removing popovers bound to body container does not work reliably so we need to remove the element manually
        // This can hopefully be removed when moving away from Bootstrap 3
        if (this.popoverId) {
            const orphanedPopover = document.getElementById(this.popoverId);
            if (orphanedPopover) {
                DomUtils.remove(orphanedPopover);
            }
        }
    }
}
