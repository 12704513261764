import { Controller } from "stimulus";
import { ModalContainer, ListSearchContainer } from '@utils/types';
import DataUtils from "./../../util/data_utils";
import Sentry from "@utils/sentry";

export default class extends Controller {

    static targets = [
        "modal"
    ];

    element: HTMLDivElement & ListSearchContainer;
    private modalTarget: HTMLDivElement & ModalContainer;
    private hasModalTarget: boolean;

    async dropped(event: CustomEvent): Promise<any> {
        event.preventDefault();

        const dragAndDropProperties = event.detail;

        if (dragAndDropProperties.id) {
            this.data.set("projectCode", dragAndDropProperties.id);
            this.data.set("oldSpaceCode", dragAndDropProperties.originElement.dataset.dragAndDropDropzoneId);
            this.data.set("newSpaceCode", dragAndDropProperties.dropzoneElement.dataset.dragAndDropDropzoneId);
            this.data.set("updateUrl", dragAndDropProperties.dropzoneElement.dataset.spaceUpdateUrl);

            if (dragAndDropProperties.originElement.dataset.projectAssigned === "true") {
                this.modalTarget.modal.show();
                Promise.resolve().catch(() => null);
            } else {
                await this.updateSpaceAssignment();
            }
        }
    }

    async confirmModal(event: Event): Promise<any> {
        await this.updateSpaceAssignment();
    }

    private resetDroppableData(): void {
        this.data.set("projectCode", null);
        this.data.set("oldSpaceCode", null);
        this.data.set("newSpaceCode", null);
        this.data.set("updateUrl", null);
    }

    private updateSpaceAssignment(): Promise<any> {
        return DataUtils.request(
            this.data.get("updateUrl"),
            { id: this.data.get("projectCode") },
            { parse: false },
            { method: "POST" }
        ).catch(error => {
            Sentry.notify(error);
        }).finally(() => {
            if (this.hasModalTarget) {
                this.modalTarget.modal.hide();
            }

            this.element.listSearch.reload();
            this.updateSpaceProjectCount();
            this.resetDroppableData();
        });
    }

    private updateSpaceProjectCount(): void {
        const oldSpaceLinkCounter = (this.element.querySelector(`[data-space-code*="${this.data.get("oldSpaceCode")}"] .spaces-list__list_counter`) as HTMLElement);
        if (oldSpaceLinkCounter) {
            oldSpaceLinkCounter.innerText = (parseInt(oldSpaceLinkCounter.innerText, 10) - 1).toString();
        }
        const newSpaceLinkCounter = (this.element.querySelector(`[data-space-code*="${this.data.get("newSpaceCode")}"] .spaces-list__list_counter`) as HTMLElement);
        newSpaceLinkCounter.innerText = (parseInt(newSpaceLinkCounter.innerText, 10) + 1).toString();
    }
}
