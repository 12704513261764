import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        'fileInput',
        'fileNameField',
        'previewImage'
    ];

    fileInputTarget: HTMLInputElement;
    fileNameFieldTarget: HTMLInputElement;
    previewImageTarget: HTMLImageElement;

    update(_e: Event) {
        if (this.fileInputTarget.files && this.fileInputTarget.files.length > 0) {
            const file = this.fileInputTarget.files[0];
            this.showPreview(file);
            this.showFilename(file);
        }
    }

    private showPreview(file: File) {
        const fileReader = new FileReader();
        fileReader.onload = (_e: Event) => {
            this.previewImageTarget.style.display="inline-block";
            const imageBase64 = fileReader.result as string;
            this.previewImageTarget.src = imageBase64;
        };
        fileReader.readAsDataURL(file);
    }

    private showFilename(file: File) {
        this.fileNameFieldTarget.value = file.name;
    }
}
