export default class CreateElement {
    static appendToHead(tagType: string, options: object) {
        document.head.appendChild(this.createElement(tagType, options));
    }

    static appendToBody(tagType: string, options: object) {
        document.body.appendChild(this.createElement(tagType, options));
    }

    static createElement(tagType: string, options: object) {
        const s = document.createElement(tagType);

        for (const opt in options) {
            s[opt] = options[opt];
        }

        return s;
    }
}