import Sentry from "@utils/sentry";
import DataUtils from "@utils/data_utils";
import DomUtils from "@utils/dom_utils";
import { Controller } from "stimulus";
import Spinner from "../../util/spinner";

export default class extends Controller {
    static targets = [
        'container',
        'modalLocales',
        'modalTranslationKeys',
        'modalTranslations',
        'errorMessage',
    ];

    private containerTarget: HTMLElement;
    private modalLocalesTarget: HTMLElement;
    private modalTranslationKeysTarget: HTMLElement;
    private modalTranslationsTarget: HTMLElement;
    private errorMessageTarget: HTMLElement;

    private spinner: Spinner;

    async connect() {
        this.spinner = new Spinner(this.containerTarget);
        this.spinner.start();
        await this.createComparison();
    }


    // istanbul ignore next
    showLocalesModal(event: Event) {
        const target = event.currentTarget as HTMLElement;
        this.showModal(this.modalLocalesTarget, target.firstElementChild as HTMLElement);
    }

    // istanbul ignore next
    showKeysModal(event: Event) {
        const target = event.currentTarget as HTMLElement;
        this.showModal(this.modalTranslationKeysTarget, target.firstElementChild as HTMLElement);
    }

    // istanbul ignore next
    showTranslationsModal(event: Event) {
        const target = event.currentTarget as HTMLElement;
        this.showModal(this.modalTranslationsTarget, target.firstElementChild as HTMLElement);
    }

    private showModal(modalTarget: HTMLElement, modalBody: HTMLElement) {
        modalTarget.querySelector(".modal-body").innerHTML = modalBody.innerHTML;
        (<any>window).$(modalTarget).modal("show");
    }

    toggleTable(event: Event) {
        const target = event.currentTarget as HTMLElement;
        target.nextElementSibling.classList.toggle('hidden');

        const toggleArrow = target.querySelector('[data-target="branches--compare.arrow"]');
        if (toggleArrow.textContent === 'keyboard_arrow_up') {
            toggleArrow.textContent = 'keyboard_arrow_down';
        } else {
            toggleArrow.textContent = 'keyboard_arrow_up';
        }
    }

    private async createComparison() {
        await DataUtils.request(
            this.comparisonPath,
            void 0,
            { parse: false },
            { method: "POST" }
        ).then(async () => {
            await this.fetchComparison();
        }
        ).catch((response) => {
            Sentry.notify(response);
            this.showErrorMessage();
        });
    }

    private async fetchComparison() {
        await DataUtils.request(
            this.comparisonPath,
            void 0,
            { parse: false },
            { method: "GET" }
        ).then(async (response) => {
            if (response.ok) {
                this.spinner.stop();
                this.containerTarget.innerHTML = await response.text();
            } else {
                setTimeout(this.fetchComparison.bind(this), 500);
            }
        }).catch((response) => {
            if (response.status === 404) {
                setTimeout(this.fetchComparison.bind(this), 500);
            } else {
                Sentry.notify(response);
                this.showErrorMessage();
            }
        });
    }

    private showErrorMessage() {
        this.spinner.stop();
        DomUtils.hideElement(this.containerTarget);
        DomUtils.showElement(this.errorMessageTarget);
    }

    private get comparisonPath(): string {
        return this.data.get("comparison-path");
    }
}
