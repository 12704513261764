import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "parent",
    ];

    private parentTarget: HTMLInputElement;

    update(event: Event) {
        const isUnchecked = !(event.currentTarget as HTMLInputElement).checked;

        if (this.parentTarget.checked && isUnchecked) {
            this.parentTarget.checked = false;
            this.parentTarget.dispatchEvent(new Event('change'));
        }
    }
}
