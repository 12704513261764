import { Controller } from "stimulus";

export default class extends Controller {
    private changedBeforeHiding: boolean;

    connect() {
        this.changedBeforeHiding = false;

        (<any>window).$(this.element).multiselect({
            numberDisplayed: 1,
            maxHeight: 200,
            includeSelectAllOption: true,
            selectAllNumber: false,
            enableCaseInsensitiveFiltering: true,
            enableFiltering: this.enableFiltering,
            allSelectedText: this.allSelectedText,
            nonSelectedText: this.nonSelectedText,
            selectAllText: this.selectAllText,
            nSelectedText: this.nSelectedText,
            onChange: () => this.changedBeforeHiding = true,
            onSelectAll: () => this.changedBeforeHiding = true,
            onDeselectAll: () => this.changedBeforeHiding = true,
            onDropdownHidden: this.dispatchChangeEvent.bind(this),
        });
    }


    private dispatchChangeEvent() {
        if (this.changedBeforeHiding) {
            this.element.dispatchEvent(new Event('change'));
        }
    }

    private get enableFiltering(): boolean {
        return this.data.get("enableFiltering") === "true";
    }


    // I18N

    private get allSelectedText(): string {
        return this.data.get("allSelectedText");
    }

    private get nonSelectedText(): string {
        return this.data.get("nonSelectedText");
    }

    private get selectAllText(): string {
        return this.data.get("selectAllText");
    }

    private get nSelectedText(): string {
        return this.data.get("nSelectedText");
    }
}
