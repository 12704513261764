import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["toggleElement"];

    protected toggleElementTargets: HTMLElement[];

    active(event: Event) {
        (event.currentTarget as HTMLElement).classList.toggle(this.toggleClass, true);
    }

    show(event: Event) {
        event.preventDefault();
        this.toggleElements(false);
    }

    hide(event: Event) {
        event.preventDefault();
        this.toggleElements(true);
    }

    toggle(event: Event) {
        event.preventDefault();
        this.toggleElements();
    }

    protected toggleElements(val = undefined) {
        this.toggleElementTargets.forEach((element) => {
            element.classList.toggle(this.toggleClass, val);
        });
    }

    protected get toggleClass() {
        return this.data.has("class") ? this.data.get("class") : "hidden";
    }
}
