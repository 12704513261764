import { Controller } from 'stimulus';
import DomUtils from '../util/dom_utils';

export default class extends Controller {
    static targets = [
        'tooltip',
        'tooltipContainer'
    ];

    private tooltipTargets: HTMLElement[];
    private tooltipContainerTargets: HTMLElement[];

    showTooltip(e: Event) {
        e.preventDefault();
        const onboardingElement = this.getOnboardingElement(e.target as HTMLElement);
        const key = this.getOnboardingKey(onboardingElement);
        this.attachTooltip(key);
        DomUtils.remove(onboardingElement);
    }

    closeTooltip(e: Event) {
        e.preventDefault();
        const onboardingElement = this.getOnboardingElement(e.target as HTMLElement);
        DomUtils.remove(onboardingElement);
    }

    private attachTooltip(key: string) {
        const tooltip = this.tooltipForKey(key);
        const container = this.containerForKey(key);

        if (tooltip && container) {
            DomUtils.remove(tooltip);
            tooltip.classList.remove('hidden');
            container.append(tooltip);
        }
    }

    private tooltipForKey(key: string): HTMLElement | undefined {
        return this.tooltipTargets.find(tooltip => this.hasKey(tooltip, key));
    }

    private containerForKey(key: string): HTMLElement | undefined {
        return this.tooltipContainerTargets.find(container => {
            return this.hasKey(container, key) && this.isVisible(container);
        });
    }

    private hasKey(element: HTMLElement, key: string): boolean {
        return this.getOnboardingKey(element) === key;
    }

    private isVisible(element: HTMLElement) {
        const rect = element.getBoundingClientRect();
        return rect.height > 0;
    }

    private getOnboardingElement(htmlElement: HTMLElement): HTMLElement {
        return htmlElement.closest('[data-onboarding-key]');
    }

    private getOnboardingKey(htmlElement: HTMLElement) {
        return htmlElement.getAttribute('data-onboarding-key');
    }
}
