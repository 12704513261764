import DomUtils from '@utils/dom_utils';
import { Controller } from 'stimulus';
import { i18n } from '../../i18n/config';


interface TranslationKey {
    id: string;
    name: string;
}

export default class extends Controller {
    static targets = [
        "keysContainer",
        "emptyMessage",
        "keyTemplate",
        "key",
        "removeSelectionButton",
        "keyCheckbox",
        "selectAllCheckbox",
        "selectAllKeysContainer",
        "keyCount",
    ];

    private keysContainerTarget: HTMLDivElement;
    private keyTemplateTarget: HTMLTemplateElement;
    private emptyMessageTarget: HTMLDivElement;
    private keyTargets: HTMLDivElement[];
    private keyCheckboxTargets: HTMLInputElement[];
    private removeSelectionButtonTarget: HTMLButtonElement;
    private selectAllKeysContainerTarget: HTMLDivElement;
    private selectAllCheckboxTarget: HTMLInputElement;
    private keyCountTarget: HTMLDivElement;

    private selectedKeyCodes = new Set<string>();

    connect() {
        const selectedCodes: string[] = JSON.parse(this.data.get("selectedKeyCodes"));
        selectedCodes.forEach(code => this.selectedKeyCodes.add(code));

        this.updateEmptyMessage();
        this.updateSelectAllKeys();
    }

    addKey(event: CustomEvent) {
        event.preventDefault();

        const key: TranslationKey = event.detail;

        if (!this.selectedKeyCodes.has(key.id)) {
            const newKey = (this.keyTemplateTarget.cloneNode(true) as HTMLTemplateElement).content.firstChild as HTMLElement;
            newKey.setAttribute("data-jobs--keys-selected-code", key.id);
            newKey.querySelector("#job_translation_key_ids_").setAttribute("value", key.id);
            newKey.querySelector("span").textContent = key.name;
            newKey.querySelector("[data-controller=tooltip]").setAttribute('title', key.name);
            this.keysContainerTarget.appendChild(newKey);
            this.updateEmptyMessage();
            this.updateSelectAllKeys();
        }
    }

    removeKey(event: Event) {
        event.preventDefault();
        const keyElement = (event.currentTarget as HTMLElement).closest('[data-target="jobs--keys-selected.key"]') as HTMLElement;
        this.removeKeyElement(keyElement);
        this.updateEmptyMessage();
        this.updateSelectAllKeys();
    }

    toggleKey(event: Event) {
        const checked = (event.currentTarget as HTMLInputElement).checked;
        if (checked) {
            this.removeSelectionButtonTarget.disabled = false;
        } else {
            this.removeSelectionButtonTarget.disabled = !this.keyCheckboxTargets.some(checkbox => checkbox.checked);
        }

        this.selectAllCheckboxTarget.checked = this.keyCheckboxTargets.every(checkbox => checkbox.checked);
    }

    toggleAllKeys() {
        const checked = this.selectAllCheckboxTarget.checked;
        this.removeSelectionButtonTarget.disabled = !this.selectAllCheckboxTarget.checked;
        this.keyCheckboxTargets.forEach(checkbox => {
            checkbox.checked = checked;
        });
    }

    removeSelection(event: Event) {
        event.preventDefault();

        this.keyCheckboxTargets.filter(checkbox => checkbox.checked).forEach(checkbox => {
            const keyElement = checkbox.closest('[data-target="jobs--keys-selected.key"]') as HTMLElement;
            this.removeKeyElement(keyElement);
        });

        this.updateEmptyMessage();
        this.updateSelectAllKeys();
        this.selectAllCheckboxTarget.checked = false;
        this.removeSelectionButtonTarget.disabled = true;
    }

    private updateSelectAllKeys() {
        if (this.keyTargets.length == 0) {
            DomUtils.hideElement(this.selectAllKeysContainerTarget);
        } else {
            DomUtils.showElement(this.selectAllKeysContainerTarget);
        }

        this.keyCountTarget.textContent = i18n.t("jobs.keys_selected", {
            key_count: this.keyTargets.length, total: JSON.parse(this.data.get("totalKeyCount"))
        });
    }

    private updateEmptyMessage() {
        if (this.keyTargets.length > 0) {
            DomUtils.hideElement(this.emptyMessageTarget);
        } else {
            DomUtils.showElement(this.emptyMessageTarget);
        }
    }

    private removeKeyElement(elem: HTMLElement) {
        const keyCode = elem.getAttribute("data-jobs--keys-selected-code");
        elem.remove();

        this.selectedKeyCodes.delete(keyCode);
        const removeKeyEvent = new CustomEvent("jobs--remove-key", { detail: { id: keyCode } });
        this.element.dispatchEvent(removeKeyEvent);
    }
}
