import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "listItem",
        "empty",
        "searchField"
    ];

    listItemTargets: HTMLElement[];
    emptyTarget: HTMLElement;
    searchFieldTarget: HTMLInputElement;

    private hasEmptyTarget: boolean;

    connect(): void {
        this.toggleEmptyTarget();
    }

    search(event: Event): void {
        const searchTerm = this.searchFieldTarget.value;
        this.hideItemsBasedOnSearchTerm(searchTerm);
        this.toggleEmptyTarget();
        this.emitFilterEvent(searchTerm);
    }

    clear(): void {
        this.searchFieldTarget.value = "";
        this.listItemTargets.forEach((listItem) => {
            listItem.classList.remove("hidden");
        });
        this.emitFilterEvent("");
    }

    preventSubmit(event: Event): void {
        event.preventDefault();
    }

    private toggleEmptyTarget() {
        if (!this.hasEmptyTarget) return;
        const hasVisibleElements = this.hiddenListItems.length !== this.listItemTargets.length;
        this.emptyTarget.classList.toggle("hidden", hasVisibleElements);
    }

    private hideItemsBasedOnSearchTerm(searchTerm): void {
        this.listItemTargets.forEach((listItem) => {
            const listItemText = listItem.dataset.textContent || listItem.textContent;
            const match = listItemText.toLowerCase().includes(searchTerm.toLowerCase());

            if (match && searchTerm !== "") {
                listItem.classList.remove("hidden");
            } else if (searchTerm === "") {
                listItem.classList.remove("hidden");
            } else {
                listItem.classList.add("hidden");
            }
        });
    }

    private emitFilterEvent(searchTerm: string): void {
        this.element.dispatchEvent(new CustomEvent("list-filter-update"));
    }

    private get hiddenListItems(): HTMLElement[] {
        return this.listItemTargets.filter((item) => item.classList.contains("hidden"));
    }
}
