import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [];

    element: HTMLElement;

    onSuccess(event: CustomEvent) {
        this.clearErrors();
        if(event.detail.xhr.responseJSON.flash_message) {
            this.renderFlashMessage(event.detail.xhr.responseJSON.flash_message);
        }
        this.element.dispatchEvent(new CustomEvent('remote-link:success'));
    }

    onError(event: CustomEvent) {
        if (event.detail.xhr.status >= 500) {
            return;
        }

        this.renderErrors(event.detail.xhr.responseJSON.errors);
    }

    private renderErrors(errors: object) {
        this.clearErrors();
        Object.keys(errors).forEach((key) => {
            const messages = errors[key];
            if (Array.isArray(errors[key])) {
                this.renderError(this.modelName, key, messages);
            } else {
                // support for nested model forms
                const nestedModelName = `${this.modelName}[${key}]`;
                Object.keys(messages).forEach((key) => {
                    this.renderError(nestedModelName, key, messages);
                });
            }
        });
    }

    private clearErrors() {
        this.element.classList.remove("has-error");
        this.element.parentElement.querySelectorAll("span.help-block.error").forEach((elem) => {
            elem.remove();
        });
    }

    private renderError(modelName: string, field: string, messages: string[]) {
        this.appendErrorMessages(field, messages);
    }

    private appendErrorMessages(field: string, messages: string[]) {
        this.element.classList.add('has-error');
        this.element.parentElement.insertAdjacentHTML(
            'beforeend',
            `
              <div class="remote-link-error">
                <span class="help-block error">${field} ${messages.join('<br />')}</span>
                <span class="remote-link-error__close" onclick="this.parentElement.remove()"><i class="material-icons">close</i></span>
              </div>
            `
        );
    }

    private get modelName(): string {
        return this.data.get("modelName");
    }

    private renderFlashMessage(message: string) {
        document.getElementById("flash-messages").innerHTML = message;
    }
}
