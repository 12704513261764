import { Controller } from "stimulus";
import Tracker from "../util/tracker";

export default class extends Controller {
    track() {
        Tracker.track(this.eventName, this.eventProperties);
    }

    private get eventName(): string {
        return this.data.get("event-name");
    }

    private get eventProperties(): any {
        const properties = this.data.get("event-properties");

        if (properties && this.isValidJson(properties)) {
            return JSON.parse(properties);
        } else {
            return {};
        }
    }

    private isValidJson(json): boolean {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }
}
