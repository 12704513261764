import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "parent",
        "child",
    ];

    private parentTarget: HTMLInputElement;
    private childTargets: HTMLInputElement[];


    connect() {
        this.updateParentCheckbox();
    }

    update() {
        this.updateParentCheckbox();
    }


    private updateParentCheckbox() {
        const isChecked = (child) => child.checked;

        if (!this.parentTarget.checked || this.childTargets.every(isChecked)) {
            this.parentTarget.removeAttribute("indeterminate");
        } else {
            this.parentTarget.setAttribute("indeterminate", "true");
        }
    }
}
