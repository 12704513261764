import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        this.element.addEventListener("submit", this.preventFormSubmit);
    }

    disconnect() {
        this.element.removeEventListener("submit", this.preventFormSubmit);
    }

    private preventFormSubmit(event: Event): void {
        event.preventDefault();
    }
}
