import { Controller } from "stimulus";
import { camelize } from "@utils/camelize";

export default class extends Controller {
    static targets = [
        "form",
        "focusInput",
    ];

    private formTarget: HTMLFormElement;
    private focusInputTarget: HTMLInputElement;

    hasFormTarget: boolean;
    hasFocusInputTarget: boolean;

    connect() {
        this.element[camelize(this.identifier)] = this;
    }

    focus() {
        const timeout = 500;

        if (this.hasFocusInputTarget) {
            setTimeout(() => {
                this.focusInputTarget.focus();
                this.focusInputTarget.selectionStart = this.focusInputTarget.selectionEnd = this.focusInputTarget.value.length;
            }, timeout);
        }
    }

    submit() {
        if (this.hasFormTarget) {
            this.formTarget.submit();
        }
    }
}
