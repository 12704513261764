import { Controller } from "stimulus";
import serialize from "form-serialize";
import Spinner from "@utils/spinner";
import { debounce } from "@utils/debounce";
import { camelize } from "@utils/camelize";

export default class extends Controller {

    static targets = [
        "form",
        "button",
        "resultList",
        "multiSelect"
    ];

    element: HTMLElement;
    spinner: Spinner;

    formTarget: HTMLFormElement;
    buttonTarget: HTMLButtonElement;
    resultListTarget: HTMLElement;
    multiSelectTarget: HTMLSelectElement | undefined;

    hasResultListTarget: boolean;
    hasMultiSelectTarget: boolean;

    connect() {
        this.element[camelize(this.identifier)] = this;

        this.spinner = new Spinner(this.hasResultListTarget ? this.resultListTarget : this.element);
        if (this.data.get("preload")) {
            this.reload();
        }
    }

    update(event: CustomEvent) {
        if (!this.data.get("omit-browser-history")) {
            const eventTarget = <HTMLElement>event.target;
            const url = (eventTarget.tagName === 'A') ? this.urlByLink(eventTarget) : this.urlByForm();
            history.pushState(
                { turbo: { restorationIdentifier: (window as any).Turbo.navigator.history.restorationIdentifier } },
                document.title,
                url
            );
        }

        if (this.hasResultListTarget) {
            this.resultListTarget.innerHTML = event.detail.data;
            this.resultListTarget.dispatchEvent(new CustomEvent('list-search:updated'));
        }

        this.spinner.stop();
    }

    updateAction(event: CustomEvent) {
        const eventTarget = <HTMLElement>event.target;
        this.formTarget.setAttribute("action", eventTarget.dataset.url);
    }

    showSpinner() {
        this.spinner.start();
    }

    hideSpinner() {
        this.spinner.stop();
    }

    reload() {
        this.submitSearch();
    }

    submit() {
        if (!this.hasResultListTarget) {
            return;
        }
        if(this.isAllElementsSelected()){
            const multiselect = $(this.multiSelectTarget).data('multiselect');
            multiselect.deselectAll(false);
            multiselect.updateButtonText();
        }
        debounce(this.submitSearch.bind(this));
    }

    reset() {
        this.formTarget.reset();
        this.formTarget.dispatchEvent(new CustomEvent('list-search:reset'));
    }

    private urlByLink(eventTarget: HTMLElement) {
        const baseUrl = this.data.get("url") || this.formTarget.action;
        const params = eventTarget.getAttribute('href').split('?')[1];
        return params ? `${baseUrl}?${params}` : baseUrl;
    }

    private urlByForm() {
        const baseUrl = this.data.get("url") || this.formTarget.action;
        let serializedForm;
        if (this.isAllElementsSelected()) {
            this.multiSelectTarget.disabled = true;
            serializedForm = `${baseUrl}?${serialize(this.formTarget)}`;
            this.multiSelectTarget.disabled = false;
        } else {
            serializedForm = `${baseUrl}?${serialize(this.formTarget)}`;
        }

        return serializedForm;
    }


    private submitSearch() {
        this.resultListTarget.innerHTML = "";
        this.spinner.start();
        this.buttonTarget.click();
    }

    private isAllElementsSelected(): boolean {
        if(!this.hasMultiSelectTarget) return false;
        return this.multiSelectTarget.selectedOptions.length === this.multiSelectTarget.options.length;
    }
}
