import Sentry from "@utils/sentry";
import { Controller } from "stimulus";

export default class SelectizeController extends Controller {
    connect() {
        const options: any = {
            plugins: ["clear_on_type"],
            create: (this.data.get("create") || false),
            persist: false,
        };

        if (this.data.get("delimiter")) {
            options.delimiter = this.data.get("delimiter");
        }

        if (this.data.get('maxItems')) {
            options.maxItems = this.data.get('maxItems');
        }

        if (this.data.get('allowEmptyOption')) {
            options.allowEmptyOption = !!this.data.get('allowEmptyOption');
        }

        if (this.data.get("url")) {
            options.valueField = this.data.get("valueField");
            options.labelField = this.data.get("labelField");
            options.searchField = options.labelField;
            options.load = (query: string, callback) => {
                if (!query.length) {
                    return callback();
                } else {
                    const url = new URL(this.data.get("url"));
                    url.searchParams.append(this.data.get("queryParam"), query);
                    fetch(url.toString(), { credentials: "same-origin" })
                        .then(response => response.json())
                        .then(data => { callback(data); })
                        .catch((error) => {
                            Sentry.notify(error);
                        });
                }
            };
        }

        (<any>window).$(this.element).selectize(options);
        (<any>window).$(this.element).on("change", this.dispatchChangeEvent.bind(this));

        (this.element as any).selectize.on("item_add", (value, item) => this.dispatchAddEvent(value, item));
        (this.element as any).selectize.on("item_remove", (value, item) => this.dispatchRemoveEvent(value, item));
    }

    protected dispatchChangeEvent() {
        this.element.dispatchEvent(new Event('jqueryChange'));
    }

    protected dispatchAddEvent(value, item) {
        this.element.dispatchEvent(new CustomEvent('selectize:item_add', { detail: { value: value, item: item } }));
    }

    protected dispatchRemoveEvent(value, item) {
        this.element.dispatchEvent(new CustomEvent('selectize:item_remove', { detail: { value: value, item: item } }));
    }
}
