import ToggleController from "./toggle_controller";

export default class SearchToggleController extends ToggleController {
    private isActive = false;

    onSearch(event: Event) {
        const input = event.currentTarget as HTMLInputElement;
        const query = input.value;
        const minLength = input.minLength;


        if (query.length >= minLength && !this.isActive) {
            this.toggle(event);
            this.isActive = true;
        } else if (query.length < minLength && this.isActive) {
            this.toggle(event);
            this.isActive = false;
        }
    }

    hide(event: Event) {
        if (this.isActive) {
            this.isActive = false;
            super.toggle(event);
        }
    }
}
