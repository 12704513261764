import { Controller } from "stimulus";
import DataUtils from "@utils/data_utils";
import DomUtils from "@utils/dom_utils";
import Sentry from "@utils/sentry";

export default class extends Controller {
    static targets = [
        "trigger",
        "container",
    ];

    private triggerTarget: HTMLAnchorElement;
    private containerTarget: HTMLDivElement;

    async load(e: Event) {
        e.preventDefault();
        this.triggerTarget.classList.add("disabled");
        await DataUtils.request(
            this.triggerTarget.href,
            void 0,
            {
                parse: false
            }, {
                method: "GET",
                credentials: "same-origin",
                headers: { "X-Requested-With": "XMLHttpRequest" }
            })
            .then(async (data) => {
                this.containerTarget.insertAdjacentHTML('beforeend', await data.text());
            })
            .catch((err: string) => {
                Sentry.notify(err);
            }
            );
        DomUtils.remove(this.triggerTarget);
    }
}
