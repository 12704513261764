import { Controller } from "stimulus";
import * as QRCode from "qrcode";

export default class extends Controller {

    static targets = ["canvas"];

    private canvasTarget: HTMLCanvasElement;

    connect() {
        QRCode.toCanvas(this.canvasTarget, this.content, { margin: 0, width: 200 }, (error) => {
            if (error) {
                console.error(error);
            }
        });
    }

    private get content() {
        return this.data.get("data");
    }
}
