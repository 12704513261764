import {Controller} from "stimulus";
import {i18n} from "../../i18n/config";


export default class extends Controller {
    static targets = [
        "previewContent",
        "columnContentType",
        "firstContentRow",
        "firstNonEmptyRow",
        "column",
        "total",
        "ignored",
        "unknown",
        "new",
        "ignore",
        "submit",
        "submitWrapper",
        "cell",
        "columnControl",
        "columnContent"
    ];

    columnContentTypeTargets: HTMLSelectElement[];
    previewContentTarget: HTMLElement;
    firstNonEmptyRowTarget: HTMLInputElement;
    firstContentRowTarget: HTMLInputElement;
    columnTargets: HTMLElement[];
    totalTarget: HTMLElement;
    ignoredTarget: HTMLElement;
    unknownTarget: HTMLElement;
    newTarget: HTMLElement;
    ignoreTargets: HTMLInputElement[];
    submitTarget: HTMLButtonElement;
    submitWrapperTarget: HTMLElement;
    cellTargets: HTMLElement[];
    columnControlTargets: HTMLElement[];
    columnContentTargets: HTMLElement[];

    existingLocales: string[];

    connect() {
        this.existingLocales = <string[]>JSON.parse(this.data.get("existingLocales"));
        this.previewContentTarget.classList.remove("hidden");
        this.columnContentTypeTargets.forEach((column) => {
            (<any>window).$(column).on("change", (event) => {
                this.changeContentType(event);
            });
        });

        this.validateForm();
        this.refresh();
    }

    changeContentType(event) {
        const value = event.currentTarget.value;
        if (value != "") {
            (<HTMLInputElement>event.currentTarget).checked = true;
        }

        this.validateForm();
        this.refresh();
    }

    ignoreColumn(event) {
        this.validateForm();
        this.refresh();
    }

    refresh() {
        this.refreshStatisticsUI();
        this.refreshContentRows();
        this.refreshColumns();
    }

    refreshContentRows() {
        const firstNonEmptyRow = parseInt(this.firstNonEmptyRowTarget.value);
        const firstContentRow = parseInt(this.firstContentRowTarget.value);
        this.cellTargets.forEach((cell) => {
            const dataRow = parseInt(cell.dataset.rowIndex);
            if (dataRow < firstContentRow - firstNonEmptyRow - 1) {
                cell.classList.add("preview-form__cell--ignored");
            } else {
                cell.classList.remove("preview-form__cell--ignored");
            }
        });
    }

    private selectedContentType(column: number): string {
        return this.columnContentTypeTargets[column].value;
    }

    private columnIgnored(index: number): boolean {
        return this.ignoreTargets[index].checked;
    }

    private totalColumns() {
        return this.columnTargets.length;
    }

    private refreshColumns() {
        this.columnContentTargets.forEach((column, index) => {
            if (this.columnIgnored(index)) {
                const selectize = this.columnContentTypeTargets[index].selectize;
                if (selectize)
                    selectize.clear();
                column.classList.add("preview-form__content--ignored");
            } else {
                column.classList.remove("preview-form__content--ignored");
            }
        });

        this.columnControlTargets.forEach((column, index) => {
            if (index) {
                if (this.columnIgnored(index) || this.selectedContentType(index) !== "") {
                    column.classList.remove("preview-form__controls--undefined");
                } else {
                    column.classList.add("preview-form__controls--undefined");
                }
            }
        });
    }

    private validateForm() {
        const allColumnsKnown = this.columnContentTypeTargets.every((column, i) => {
            return column.value !== "" || this.columnIgnored(i);
        });
        const noKeyName = this.columnContentTypeTargets.every((column, i) => {
            return column.value == "key_name" && !this.columnIgnored[i];
        });
        const hasLocale = this.columnContentTypeTargets.some((column, i) => {
            return !["", "key_name", "comment", "tag", "max_characters"].includes(column.value) && !this.columnIgnored(i);
        });

        let toolTipText = '';
        if (!allColumnsKnown) {
            toolTipText = i18n.t('locale_file_imports.preview.warning.unknown_columns_javascript');
        }
        if (noKeyName) {
            toolTipText += " " + i18n.t('locale_file_imports.preview.warning.no_key_name_javascript');
        }

        if (allColumnsKnown && !noKeyName && hasLocale) {
            this.submitTarget.disabled = false;
            (<any>window).$(this.submitWrapperTarget).tooltip("destroy");
        } else {
            this.submitTarget.disabled = true;
            this.submitWrapperTarget.setAttribute("data-original-title", toolTipText);
            (<any>window).$(this.submitWrapperTarget).tooltip('fixTitle');
        }
    }

    private refreshStatisticsUI() {
        const newColumns = this.columnContentTypeTargets.filter((column, i) => {
            return !["", "key_name", "comment", "tag", "max_characters"].includes(column.value)
                && !this.columnIgnored(i)
                && !this.existingLocales.includes(column.value);
        }).length;

        const ignoredColumns = this.ignoreTargets.filter(item => item.checked).length;
        const unknownColumns = this.columnContentTypeTargets.filter((column, index) => {
            return this.selectedContentType(index) == "" && !this.columnIgnored(index);
        }).length;

        this.totalTarget.innerHTML = this.totalColumns().toString();
        this.newTarget.innerHTML = newColumns.toString();
        this.ignoredTarget.innerHTML = ignoredColumns.toString();
        this.unknownTarget.innerHTML = unknownColumns.toString();
    }
}
