import { Controller } from "stimulus";

export default class extends Controller {
    openCheckout(e: CustomEvent) {
        e.preventDefault();

        this.element.dispatchEvent(
            new CustomEvent('translation-order-checkout:success', {
                detail: {
                    hostedPage: JSON.parse(this.data.get('hostedPage'))
                }
            })
        );
    }
}
