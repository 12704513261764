import { Controller } from "stimulus";
import DomUtils from "@utils/dom_utils";

export default class extends Controller {
    static targets = [
        "ssoEnforced",
        "usersWithoutIdentityContainer",
        "userList",
        "checkboxContainer",
        "skip"
    ];

    ssoEnforcedTarget: HTMLFormElement;
    skipTarget: HTMLFormElement;
    usersWithoutIdentityContainerTarget: HTMLDivElement;
    userListTarget: HTMLDivElement;
    checkboxContainerTarget: HTMLDivElement;
    private skipEnforceForOwners: boolean;

    connect() {
        this.skipEnforceForOwners = this.data.get("skip-enforce-for-owner") == "true";
    }

    ssoEnforced() {
        if (this.ssoEnforcedTarget.checked) {
            if (this.skipEnforceForOwners) {
                this.skipTarget.checked = true;
                this.skipTarget.addEventListener("click", this.readOnly, true);
            }
            this.loadUsersWithoutIdentity();
        }
    }

    setEnforced() {
        this.ssoEnforcedTarget.checked = true;
        this.ssoEnforcedTarget.disabled = false;
        this.checkboxContainerTarget.classList.remove("hidden");
        this.usersWithoutIdentityContainerTarget.classList.add("hidden");
    }

    cancelEnforced() {
        this.ssoEnforcedTarget.checked = false;
        this.ssoEnforcedTarget.disabled = false;
        this.skipTarget.removeEventListener("click", this.readOnly, true);
        this.checkboxContainerTarget.classList.remove("hidden");
        this.usersWithoutIdentityContainerTarget.classList.add("hidden");
    }

    reloadUserList() {
        this.userListError("hide");
        this.loadUsersWithoutIdentity();
    }

    private readOnly(event: Event) {
        event.preventDefault();
    }

    private loadUsersWithoutIdentity() {
        fetch(
            this.data.get("load-users-path")
        ).then((response) => {
            if (response.ok) {
                return response.text().then((html) => {
                    return Promise.resolve({ success: true, body: html });
                });
            } else {
                return response.text().then((html) => {
                    return Promise.resolve({ success: false, body: html });
                });
            }
        }).then((data) => {
            const users = JSON.parse(data.body);

            if (users.length >= 1) {
                this.userListError("hide");
                this.showEnforcementWarning(users);
            }
        }).catch(() => {
            this.cancelEnforced();
            this.userListError("show");
        });
    }

    private showEnforcementWarning(users) {
        this.ssoEnforcedTarget.checked = false;
        this.ssoEnforcedTarget.disabled = true;
        this.checkboxContainerTarget.classList.add("hidden");
        this.usersWithoutIdentityContainerTarget.classList.remove("hidden");

        this.insertUserList(users);
    }

    private insertUserList(users) {
        this.removeOldUserListItems();

        if (users.length > 5) {
            this.userListTarget.setAttribute("style", "height: 250px; overflow: auto;");
        }

        users.forEach((user) => {
            const userItem = document.createElement("li");
            userItem.classList.add("list-group-item");
            userItem.dataset.role = "sso-enforce-user-item";
            userItem.innerHTML = `${user.name} (${user.username})`;

            this.userListTarget.appendChild(userItem);
        });
    }

    private removeOldUserListItems() {
        document.querySelectorAll("[data-role='sso-enforce-user-item']").forEach((el) => {
            DomUtils.remove(el);
        });
    }

    private userListError(visibility) {
        const error = document.querySelector("[data-role='sso-enforce-loading-error']");
        (visibility === "show") ? error.classList.remove("hidden") : error.classList.add("hidden");
    }
}
