import { Controller } from 'stimulus';
import DomUtils from '@utils/dom_utils';

const PHRASE_SNACKBAR_CONFIG_KEY = 'Phrase::SnackbarConfig';

export default class extends Controller {
    connect() {
        const snackbarConfig = this.fetchSnackbarConfig();

        if (snackbarConfig.includes(this.snackbarName)) {
            DomUtils.remove(this.element);
        }
    }

    closeSnackbar(): void {
        const snackbarConfig = this.fetchSnackbarConfig();

        localStorage.setItem(
            PHRASE_SNACKBAR_CONFIG_KEY,
            JSON.stringify(snackbarConfig.concat([this.snackbarName]))
        );

        DomUtils.remove(this.element);
    }

    private fetchSnackbarConfig () {
        try {
            return JSON.parse(localStorage.getItem(PHRASE_SNACKBAR_CONFIG_KEY) ?? '[]');
        } catch(e) {
            return [];
        }
    }

    private get snackbarName() {
        return this.data.get('snackbarName');
    }
}
