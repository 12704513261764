import { Controller } from "stimulus";
import DiffMatchPatch from "diff-match-patch";

export default class DiffController extends Controller {
    static targets = [
        "displayContainer",
    ];

    private displayContainerTarget: HTMLDivElement;

    private static readonly DIFF_INSERT: number = 1;
    private static readonly DIFF_DELETE: number = -1;
    private static readonly DIFF_EQUAL: number = 0;

    connect() {
        this.render();
    }

    render() {
        const diffMatchPatch = new DiffMatchPatch();
        const diffs: Array<any> = diffMatchPatch.diff_main(this.data.get("string-was"), this.data.get("string-is"));
        diffMatchPatch.diff_cleanupSemantic(diffs);
        const diffAsHtml = diffs.map((diff: (string|number)[]) => {
            const operation = diff[0] as number;
            const data = diff[1] as string;
            return this.buildHtml(operation, data);
        });
        this.displayContainerTarget.innerHTML = diffAsHtml.join("");
    }

    private buildHtml(operation: number, data: string): string {
        let html = "";
        const text = data.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/\n/g, '<br>');
        switch(operation) {
            case DiffController.DIFF_INSERT: {
                html = `<ins>${text}</ins>`;
                break;
            }
            case DiffController.DIFF_DELETE: {
                html = `<del>${text}</del>`;
                break;
            }
            case DiffController.DIFF_EQUAL: {
                html = `<span>${text}</span>`;
                break;
            }
        }
        return html;
    }
}
