import { Controller } from "stimulus";
import Sentry from "@utils/sentry";
import DataUtils from "@utils/data_utils";
import DomUtils from "../util/dom_utils";
import { debounce } from "../util/debounce";

export default class extends Controller {
    static targets = [
        "email",
        "name",
        "company",
        "password",
        "visibilityIcon",
        "validations",
        "successNote",
    ];

    emailTarget: HTMLInputElement;
    hasEmailTarget: boolean;
    nameTarget: HTMLInputElement;
    hasNameTarget: boolean;
    companyTarget: HTMLInputElement;
    hasCompanyTarget: boolean;
    passwordTarget: HTMLInputElement;
    visibilityIconTarget: HTMLElement;

    validationsTarget: HTMLElement;
    successNoteTarget: HTMLElement;

    private hasSuccessNoteTarget: boolean;
    private preventingBlur: boolean;

    validationsAreAlwaysVisible = false;

    connect() {
        this.validationsAreAlwaysVisible = this.data.get('validations-are-always-visible') === 'true';

        if (this.validationsAreAlwaysVisible) this.updateValidations();
    }

    togglePasswordVisibility() {
        if (this.passwordTarget.type === "password") {
            this.passwordTarget.type = "text";
            this.visibilityIconTarget.innerHTML = "visibility";
        } else {
            this.passwordTarget.type = "password";
            this.visibilityIconTarget.innerHTML = "visibility_off";
        }
        this.passwordTarget.focus();
    }

    preventBlur() {
        this.preventingBlur = true;
    }

    allowBlur() {
        this.preventingBlur = false;
    }

    showValidations() {
        DomUtils.showElement(this.validationsTarget);
    }

    hideValidations() {
        if (this.preventingBlur || this.validationsAreAlwaysVisible) return;

        DomUtils.hideElement(this.validationsTarget);
    }

    hasAtLeastOneError(html: string): boolean {
        const fakeElement = document.createElement('div');
        fakeElement.innerHTML = html;
        const allLiElements = fakeElement.querySelectorAll('li');

        let isError = false;
        for (let i = 0, len = allLiElements.length; i < len; ++i) {
            if (allLiElements[i].classList.contains('password-help__validation--you-shall-not-pass')) {
                isError = true;
                break;
            }
        }
        return isError;
    }

    updateValidations() {
        debounce(() =>
            this.requestValidations()
                .then(text => {
                    this.validationsTarget.innerHTML = text;

                    if (!this.hasAtLeastOneError(text)) {
                        setTimeout(() => {
                            this.hideValidations();
                            this.hasSuccessNoteTarget && DomUtils.showElement(this.successNoteTarget);
                        }, 1000);
                    } else {
                        this.showValidations();
                        this.hasSuccessNoteTarget && DomUtils.hideElement(this.successNoteTarget);
                    }
                })
                .catch(err => {
                    Sentry.notify(err);
                })
        );
    }

    requestValidations() {
        return DataUtils.request(
            this.data.get("validation-url"),
            {
                signup: {
                    name: this.name,
                    company: this.company,
                    email: this.email,
                    password: this.passwordTarget.value,
                },
            },
            { parse: false },
            {
                method: 'POST',
            },
        )
            .then(response => response.text());
    }

    private get name(): string {
        return this.hasNameTarget ? this.nameTarget.value : "";
    }

    private get company(): string {
        return this.hasCompanyTarget ? this.companyTarget.value : "";
    }

    private get email(): string {
        return this.hasEmailTarget ? this.emailTarget.value : "";
    }
}
