export function load(url: string) {
    if ((<any>window).Turbo) {
        (<any>window).Turbo.visit(url);
    } else {
        window.location.assign(url);
    }
}

export function reload() {
    if ((<any>window).Turbo) {
        (<any>window).Turbo.visit(window.location.toString());
    } else {
        window.location.reload();
    }
}
