import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ['button'];

    private buttonTarget: HTMLInputElement;
    private formChanged = false;

    submit(event: Event) {
        const target = (event.currentTarget as HTMLInputElement);
        const form = target.form || target.closest("form");
        (<any>window).$(form).trigger("submit.rails");
    }

    enable(event: Event) {
        this.buttonTarget.disabled = false;
    }

    disable(event: Event) {
        this.buttonTarget.disabled = true;
    }

    submitIfChanged(event: Event) {
        if (this.formChanged) this.submit(event);
    }

    changed() {
        this.formChanged = true;
    }
}
