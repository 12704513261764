import { Controller } from "stimulus";
import Spinner from "@utils/spinner";

export default class extends Controller {
    private spinner: Spinner;

    connect() {
        this.spinner = new Spinner(this.element);
    }

    showSpinner(event: Event) {
        this.spinner.start();
    }

    hideSpinner(event: Event) {
        this.spinner.stop();
    }

    removeListItem(event: Event) {
        this.removeItem();
        this.hideSpinner(event);
    }

    private removeItem() {
        const parent = this.element.parentNode;
        parent.removeChild(this.element);
    }
}
