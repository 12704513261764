import { Controller } from "stimulus";
import Prism from "prismjs";
import "prismjs/components/prism-json";
import "prismjs/components/prism-yaml";
import "prismjs/components/prism-haml";
import "prismjs/components/prism-ini";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-properties";
import "prismjs/plugins/line-numbers/prism-line-numbers";

export default class CodeBlockController extends Controller {
    connect() {
        Prism.highlightElement(this.element);
    }
}
