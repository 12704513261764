import ListViewController, { ListResource } from "../list_view_controller";

interface BranchListResource extends ListResource {
    branch: string;
}


export default class extends ListViewController {
    protected update(event: CustomEvent) {
        const resource: BranchListResource = event.detail;
        let item = this.getItem(resource.resource_name, resource.resource_id);
        item.outerHTML = resource.item_html;
        item = this.getItem(resource.resource_name, resource.resource_id);
        this.highlightItem(item);

        if (resource.branch) {
            this.updateBranchParam(resource.branch);
        }
    }
    private updateBranchParam(branch: string) {
        const url = new URL(window.location.href);
        url.searchParams.set("branch", branch);
        const newUrl = url.toString();
        window.location.assign(newUrl);
    }
}
