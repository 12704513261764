import { Controller } from "stimulus";
import { ListSearchContainer, AsyncRenderContainer } from '@utils/types';


export default class extends Controller {
    static targets = [
        "dependentList",
        "independentListItem",
        "independentList"
    ];

    private dependentListTargets: (HTMLElement & ListSearchContainer & AsyncRenderContainer)[];
    private independentListItemTargets: HTMLElement[];
    private independentListTarget: ListSearchContainer & AsyncRenderContainer;

    private hasIndependentListTarget: boolean;

    reload() {
        if (this.hasIndependentListTarget && this.independentListItemTargets.length == 0) {
            this.reloadIndependentList();
        }
        this.reloadDependentLists();
    }

    reloadCurrent(event) {
        const el = event.target;
        for (const list of this.dependentListTargets) {
            if (list.contains(el)) this.reloadList(list);
        }
    }

    reloadIndependentList() {
        this.reloadList(this.independentListTarget);
    }

    private reloadDependentLists() {
        for (const list of this.dependentListTargets) {
            this.reloadList(list);
        }
    }

    private reloadList(list) {
        if (list.listSearch) list.listSearch.reload();
        else list.asyncRender.reload();
    }
}
