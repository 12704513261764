export const bootstrapWhiteList = {
    '*': ['class', 'dir', 'id', 'lang', 'role', /^aria-[\w-]*$/i, /^data-[\w-]+/i],
    a: ['target', 'href', 'title', 'rel'],
    area: [],
    b: [],
    br: [],
    col: [],
    code: [],
    div: [],
    em: [],
    hr: [],
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    i: [],
    img: ['src', 'alt', 'title', 'width', 'height'],
    li: [],
    ol: [],
    p: [],
    pre: [],
    s: [],
    small: [],
    span: [],
    sub: [],
    sup: [],
    strong: [],
    u: [],
    ul: [],
    kbd: [],
    progress: ['class', 'max', 'value', 'title'],
    input: ['type', 'readonly', 'value', 'class', 'id'],
    button: ['type', 'value', 'class', 'id', /^data-[\w-]*$/i],
};
