import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        "activatableElement",
    ];

    private activatableElementTargets: HTMLElement[];

    markActive(event: Event): void {
        this.activatableElementTargets.forEach((element) => {
            element.classList.remove("active");
        });

        (event.target as HTMLElement).closest("[data-target*='activatable.activatableElement']").classList.add("active");
    }
}
