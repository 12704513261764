import { Controller } from "stimulus";
import DomUtils from "@utils/dom_utils";

interface State {
    defaultBillingCycleValue: string;
    defaultPlanValue: string;
    defaultSeatValue: string;
}

export default class extends Controller {
    static targets = [
        'currencyField',
        'billingCycleField',
        'seatsField',
        'planItemContainer',
        'featureComparisonContainer',
        'featureComparisonListContainer',
        'checkoutButton',
        'plan',
    ];

    state: State = {
        defaultBillingCycleValue: null,
        defaultPlanValue: null,
        defaultSeatValue: null
    };

    private currencyFieldTarget: HTMLInputElement;
    private billingCycleFieldTargets: HTMLInputElement[];
    private seatsFieldTarget: HTMLSelectElement;
    private planItemContainerTargets: HTMLElement[];
    private featureComparisonContainerTarget: HTMLElement;
    private featureComparisonListContainerTarget: HTMLElement;
    private checkoutButtonTarget: HTMLButtonElement;
    private planTargets: HTMLInputElement[];

    connect() {
        this.updateFeatureComparison();
        this.updateSeatLimits();

        // update prices initially after all controllers are successfully connected
        window.requestAnimationFrame(() => {
            this.updatePrices();
        });

        this.state.defaultBillingCycleValue = this.billingCycleValue;
        this.state.defaultPlanValue = this.selectedPlanValue;
        this.state.defaultSeatValue = this.selectedSeatsValue;

        if (this.currentSubscriptionPresent == true) {
            DomUtils.disableButton(this.checkoutButtonTarget);
        }
    }

    updatePrices() {
        const event = new CustomEvent('checkout-form-changed', {
            detail: {
                currency: this.currencyValue,
                billingCycle: this.billingCycleValue,
            }
        });
        this.planItemContainerTargets.forEach((planItemContainerTarget) => {
            planItemContainerTarget.dispatchEvent(event);
        });
    }

    updateSeatLimits() {
        const limits = this.selectedPlanUserLimits;

        if (!limits) return;

        let min = limits.minSeatsForCurrencyAndBillingCycle(this.currencyValue, this.billingCycleValue);
        if (this.currentPlanSeats) {
            min = Math.max(this.currentPlanSeats, min);
        }
        const max = limits.maxSeatsForCurrencyAndBillingCycle(this.currencyValue, this.billingCycleValue);
        const preselectedValue = this.seatsFieldTarget.value || this.currentPlanSeats;
        this.seatsFieldTarget.innerHTML = '';
        for (let i = min; i <= max; i++) {
            const option = document.createElement('option');
            option.text = option.value = i.toString();
            option.selected = preselectedValue == i;
            this.seatsFieldTarget.appendChild(option);
        }
    }
    toggleProceedButton() {
        if (this.currentSubscriptionPresent == true) {
            if (this.selectionHasNotChanged()) {
                DomUtils.disableButton(this.checkoutButtonTarget);
            } else {
                DomUtils.enableButton(this.checkoutButtonTarget);
            }
        }
    }

    updateFeatureComparison() {
        if (this.currentPlanFeatures && this.selectedPlanFeatures.length > 0) {
            const lostFeaturesBySwitchingToSelectedPlan =
                this.currentPlanFeatures.filter((feature) => {
                    return !this.selectedPlanFeatures.includes(feature);
                });

            if (lostFeaturesBySwitchingToSelectedPlan.length > 1) {
                DomUtils.showElement(this.featureComparisonContainerTarget);
                DomUtils.disableButton(this.checkoutButtonTarget);

                this.updateFeatureComparisionList(lostFeaturesBySwitchingToSelectedPlan);
            } else {
                DomUtils.hideElement(this.featureComparisonContainerTarget);
                DomUtils.enableButton(this.checkoutButtonTarget);
            }
        }
    }

    toggleConfirmCheckout(event) {
        if (event.target.checked) {
            DomUtils.enableButton(this.checkoutButtonTarget);
        } else {
            DomUtils.disableButton(this.checkoutButtonTarget);
        }
    }

    private selectionHasNotChanged() {
        return this.selectedPlanValue == this.state.defaultPlanValue &&
            this.billingCycleValue == this.state.defaultBillingCycleValue &&
            this.selectedSeatsValue == this.state.defaultSeatValue;
    }

    private updateFeatureComparisionList(lostFeaturesBySwitchingToSelectedPlan: Array<string>) {
        this.featureComparisonListContainerTarget.innerHTML = '';

        lostFeaturesBySwitchingToSelectedPlan.forEach((feature) => {
            const featureListItem = document.createElement('p');
            featureListItem.classList.add('callout__message');

            const featureListItemIconWrapper = document.createElement('span');
            featureListItemIconWrapper.classList.add('callout__header-status-icon');

            const featureListItemIcon = document.createElement('i');
            featureListItemIcon.classList.add('material-icons');
            featureListItemIcon.innerHTML = 'block';

            featureListItemIconWrapper.appendChild(featureListItemIcon);
            featureListItem.appendChild(featureListItemIconWrapper);
            featureListItem.innerHTML += feature;
            this.featureComparisonListContainerTarget.appendChild(featureListItem);
        });
    }

    private get currencyValue(): string {
        return this.currencyFieldTarget.value;
    }

    private get billingCycleValue(): string {
        let billingCycle: string;

        this.billingCycleFieldTargets.forEach((billingCycleFieldTarget) => {
            if (billingCycleFieldTarget.checked || billingCycleFieldTarget.type == "hidden") {
                billingCycle = billingCycleFieldTarget.value;
            }
        });

        return billingCycle;
    }

    private get selectedPlanUserLimits(): UserLimits {
        const checkedPlanTarget = this.planTargets.find((target) => target.checked);

        if (!checkedPlanTarget) return null;

        return new UserLimits(checkedPlanTarget.dataset.userLimits);
    }

    private get selectedPlanFeatures(): Array<string> {
        const checkedPlanTarget = this.planTargets.find((target) => target.checked);


        return checkedPlanTarget ? JSON.parse(checkedPlanTarget.dataset.selectedPlanFeatures) : [];
    }

    private get selectedPlanValue(): string {
        const checkedPlanTarget = this.planTargets.find((target) => target.checked);

        return checkedPlanTarget.value;
    }

    private get selectedSeatsValue(): string {
        const selectedSeatTarget = this.seatsFieldTarget.value;

        return selectedSeatTarget;
    }

    private get currentPlanFeatures(): Array<string> {
        return JSON.parse(this.data.get('currentPlanFeatures'));
    }

    private get currentPlanSeats(): number | null {
        return JSON.parse(this.data.get('currentPlanSeats'));
    }

    private get currentSubscriptionPresent(): boolean {
        return JSON.parse(this.data.get('currentSubscriptionPresent'));
    }
}

class UserLimits {
    private parsedLimits: any;

    constructor(limits_json) {
        this.parsedLimits = JSON.parse(limits_json);
    }

    minSeatsForCurrencyAndBillingCycle(currency: string, billingCycle: string): number {
        return this.parsedLimits[currency][billingCycle]["min"];
    }

    maxSeatsForCurrencyAndBillingCycle(currency: string, billingCycle: string): number {
        return this.parsedLimits[currency][billingCycle]["max"];
    }
}
