import { Controller } from 'stimulus';

export default class LocaleSelectionController extends Controller {
    static targets = [
        'localesSelectFrame'
    ];

    private localesSelectFrameTarget: any;

    updateLocales(event: CustomEvent) {
        const target = event.target as HTMLDivElement;
        const accountId = this.data.get('account-id');
        const projectId = this.data.get("project-id");
        const selectedBranch = target.getAttribute('data-syntax-list-select-selected-item-value');
        const localesSelectFrame = this.localesSelectFrameTarget;

        localesSelectFrame.src = this.localesFetchPath(accountId, projectId, selectedBranch);
    }

    private localesFetchPath(accountId: string, projectId: string, branch: string){
        const localesPath = this.data.get('locale-index-path').replace(":account_id", accountId).replace(":project_id", projectId);
        const queryString = new URLSearchParams();
        queryString.append('branch_name', branch);

        return `${localesPath}?${queryString.toString()}`;
    }
}
