type Action
    = { kind: "redirectTo"; url: string }
    | { kind: "reload" }
    | { kind: "phaseOnePolling" }
    | { kind: "phaseTwoPolling" }
    | { kind: "phaseThreePolling" }
    | { kind: "stopPolling" };

const PHASE_ONE_POLLING_COUNT = 15;
const PHASE_TWO_POLLING_COUNT = PHASE_ONE_POLLING_COUNT + 9;
const PHASE_THREE_POLLING_COUNT = PHASE_TWO_POLLING_COUNT + 48;

export function update(redirectUrl: string | null, pollingCount: number, state: string): Action {
    if (state === "success" || state === "waiting_for_preview") {
        if (redirectUrl) {
            return {
                kind: "redirectTo",
                url: redirectUrl,
            };
        } else {
            return { kind: "reload" };
        }
    } else if (state === "error") {
        return { kind: "reload" };
    } else if (pollingCount < PHASE_ONE_POLLING_COUNT) {
        return { kind: "phaseOnePolling" };
    } else if (pollingCount < PHASE_TWO_POLLING_COUNT) {
        return { kind: "phaseTwoPolling" };
    } else if (pollingCount < PHASE_THREE_POLLING_COUNT) {
        return { kind: "phaseThreePolling" };
    } else {
        return { kind: "stopPolling" };
    }
}
