type SentryError = Error | string;

export default class Sentry {
    static notify(err: SentryError | SentryError[], context?: any) {
        if (!(<any>window).sentryClient) return;
        if (!Array.isArray(err)) err = [err];

        err.forEach(err => {
            if (typeof (err) === "string") {
                (<any>window).sentryClient.captureMessage(err, { extra: context });
            } else if (typeof (err) === "object" && err.hasOwnProperty('graphQLErrors')) {
                err['graphQLErrors'].forEach((e: SentryError) => (<any>window).sentryClient.captureException(e, { extra: context }));
            } else {
                (<any>window).sentryClient.captureException(err, { extra: context });
            }
        });
    }
}
