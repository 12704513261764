//
// What does this do?
//
// This controller should hide group headers, if all their children (group items)
// are hidden. This is a feature that we need for the permissions table, but could
// come in handy in other cases as well.
//
// The current implementation is specific to the permissions table implementation.
//
// We do this because of the permissions table structure like this:
//
// table data-controller="list-filter list-parent"
//  thead data-target="list-parent.item" <- needs to hide/show on event
//    <some group header>
//  tbody data-target="list-parent.item" <- needs to hide/show on event
//    <permission description> <list filter event is emitted here>
//
// This implementation will look for the `nextElementSibling` and will check
// if it has any children left that are not hidden. If they're all hidden, it will
// remove itself from the list and vise versa.
//
// In an ideal scenario there should be some kind of parent/child strucutre in the
// DOM and not a search for siblings. A future implementation could look like this:
//
// div data-controller="list-filter"
//   div data-controller="list-parent" data-action="list-filter-update->list-parent#update"
//     div class="list-filter.listItem" Some text
//     div class="list-filter.listItem" Some other text
//
// If the `list-filter-update` event is emitted by a `listItem`, the parent controller
// should be called and shows/hides itself. This is currently not possible because of the
// existing permissions table implementation that will change in the near future (road map
// already defined)
//
// Please remove this comment once the changes are applied :)
//

import { Controller } from "stimulus";

export default class extends Controller {
    static targets = [
        "item",
    ];

    itemTargets: HTMLElement[];

    update(): void {
        this.itemTargets.forEach((item) => {

            if (item.nextElementSibling) {
                const remainingChildren = Array.from(item.nextElementSibling.children).filter(
                    (item) => item.classList.contains("hidden") === false
                );

                if (remainingChildren.length === 0) {
                    item.classList.add("hidden");
                } else {
                    item.classList.remove("hidden");
                }
            }
        });
    }
}
