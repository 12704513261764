import { Controller } from "stimulus";
import { selectizeOf } from "@utils/selectize";
import Sentry from "@utils/sentry";
import { i18n } from "../i18n/config";

const localStorageKey = "Last selected users for job";
const customUserListOrder = [
    "lastmatched",
    "translator",
    "designer",
    "projectmanager",
    "admin",
    "developer",
];

interface User {
    [key: string]: string;
}

type Users = Array<User>;

export default class extends Controller {
    static targets = ["userList"];

    private userListTarget: HTMLSelectElement;

    connect() {
        const dropdown = this.element.querySelector(
            ".user-select__dropdown"
        ) as HTMLElement;
        const title = this.element.querySelector(
            ".user-select__dropdown-title"
        );
        const selectOptions = this.element.querySelectorAll(
            ".user-select__dropdown-list-item"
        );
        const activeSelect = this.element.querySelector(
            ".user-select__dropdown-list-item,.disabled"
        ) as HTMLUListElement;

        // Prepare select dropdown
        if (dropdown && title && selectOptions) {
            this.removeLastMatchedSelectIfEmpty(selectOptions, dropdown);
            this.showActiveListItemAsTitle(selectOptions, title.innerHTML);
            this.removeDropdownForOneSelect(selectOptions, dropdown);
        }

        // Prepare userlist
        if (activeSelect) {
            const activeSelectName = activeSelect.dataset.sorting;

            if (activeSelectName === "last-matched") {
                this.sortUserListByLastMatched();
            } else {
                this.createSelectize(
                    this.userListTarget,
                    this.selectOptionData()
                );
            }
        }
    }

    saveUsersInLocalstorage(event: Event) {
        const target = event.currentTarget as HTMLInputElement;
        const jobLocalesForm = target.form.querySelector(
            "#job-locales-selection-form"
        );
        const inputs = jobLocalesForm.querySelectorAll(".item");
        const dataForLocalStorage = {};

        Array.from(inputs).map((input: HTMLElement) => {
            dataForLocalStorage[input.dataset.value] = input.innerHTML;
        });

        localStorage.setItem(
            localStorageKey,
            JSON.stringify(dataForLocalStorage)
        );
    }

    updateSortingList(event: Event) {
        event.preventDefault();

        const target = <HTMLAnchorElement>event.currentTarget;
        const key = target.dataset.sorting;
        const label = target.dataset.label;
        const dropdown = target.closest(".user-select__dropdown");
        const title = dropdown.querySelector(".user-select__dropdown-title");
        const selectOptions = dropdown.querySelectorAll(
            ".user-select__dropdown-list-item"
        );

        // If user selects list item, update dropdown title
        title.innerHTML = label;

        this.showActiveListItemAsTitle(selectOptions, title.innerHTML);
        this.changeSortingAfterUserInput(key);
    }

    private getUsersFromLocalStorage() {
        const data = localStorage.getItem(localStorageKey);

        if (!data) return null;

        return JSON.parse(data);
    }

    private removeLastMatchedSelectIfEmpty(selectOptions: NodeList, dropdown: HTMLElement) {
        const localStorageData = this.getUsersFromLocalStorage();
        const dropdownTitle = this.element.querySelector(
            ".user-select__dropdown-title"
        );

        if (localStorageData !== null) return;

        selectOptions.forEach(option => {
            const listItem = <HTMLElement>option;

            if (listItem.dataset.sorting === "last-matched") {
                listItem.remove();
            }

            if (listItem.dataset.sorting === "role") {
                dropdownTitle.innerHTML = listItem.dataset.label;
            }
        });

        const updatedDropdownList = this.element.querySelectorAll(
            ".user-select__dropdown-list-item"
        );

        this.removeDropdownForOneSelect(updatedDropdownList, dropdown);
    }

    private removeDropdownForOneSelect(selectOptions: NodeList, dropdown) {
        if (selectOptions.length > 1) return;

        dropdown.remove();
    }

    private showActiveListItemAsTitle(selectOptions: NodeList, title: string) {
        selectOptions.forEach(option => {
            const listItem = <HTMLElement>option;

            if (listItem.dataset.label === title) {
                listItem.classList.add("syn_list_item--disabled");
            } else {
                listItem.classList.remove("syn_list_item--disabled");
            }
        });
    }

    private changeSortingAfterUserInput(key: string) {
        if (key === "role") {
            this.createSelectize(this.userListTarget, this.selectOptionData());
        }

        if (key === "last-matched") {
            this.sortUserListByLastMatched();
        }

        if (key === "name-asc") {
            this.sortUserListAlphabetically("asc");
        }

        if (key === "name-desc") {
            this.sortUserListAlphabetically("desc");
        }
    }

    private restoreRemovedItems(target: HTMLSelectElement, userList: object) {
        target.selectize.off("item_remove");
        target.selectize.on("item_remove", () => {
            this.createSelectize(this.userListTarget, userList);
        });
    }

    private createSelectize(target: HTMLSelectElement, userList: object) {
        selectizeOf(target)
            .then((selectize) => {
                const preselectedUser = selectize.getValue(target);

                selectize.clearOptions();
                selectize.clearOptionGroups();

                for (const optGroup in userList) {
                    const availableUsers = userList[optGroup];
                    // Create option groups
                    selectize.addOptionGroup(optGroup, {
                        label: i18n.t(`user-select.${optGroup}`),
                    });

                    // Fill each option group with users
                    Object.values(availableUsers).forEach(user => {
                        // All users with set roles
                        if (customUserListOrder.includes(optGroup)) {
                            selectize.addOption({
                                text: Object.values(user)[1],
                                value: Object.values(user)[0],
                                optgroup: optGroup,
                                $order: customUserListOrder.indexOf(optGroup) + 1,
                            });
                        }

                        // All users with special roles outside customUserListOrder
                        selectize.addOption({
                            text: Object.values(user)[1],
                            value: Object.values(user)[0],
                            optgroup: optGroup,
                        });
                    });
                }

                selectize.setValue(preselectedUser);

                selectize.refreshOptions(false);
                selectize.refreshItems();

                this.restoreRemovedItems(target, userList);
            })
            .catch((err: string) => {
                Sentry.notify(err);
            });
    }

    private selectOptionData() {
        return JSON.parse(this.data.get("options"));
    }

    private sortUserListByLastMatched() {
        /*
        Delete users from original userlist that are also last matches
        because otherwise they won't be shown in last matches
        */
        const lastMatchedStorageData = this.getUsersFromLocalStorage();
        if(!lastMatchedStorageData) return;

        const originalUserList = this.selectOptionData() as Record<string, Users>;
        const users = Object.values(originalUserList).flat().map((u) => u.value);
        const lastMatched = Object.keys(lastMatchedStorageData).filter((key) => users.includes(key)).map((key) => Object.assign({}, { value: key, text: lastMatchedStorageData[key] }));
        const userListWithLastMatched = Object.assign(originalUserList, { "lastmatched": lastMatched });
        const doubledValues = lastMatched.map((u) => u.value);

        // remove last matched users from other optgroups
        Object.keys(userListWithLastMatched).filter((optGroup) => optGroup != "lastmatched").forEach((optGroup) => {
            const users = userListWithLastMatched[optGroup];
            users.forEach((user, i) => {
                if (doubledValues.includes(user.value)) {
                    users.splice(i, 1);
                }
            });
        });

        this.createSelectize(this.userListTarget, userListWithLastMatched);
    }

    private sortUserListAlphabetically(sorting: string) {
        const originalUserList = this.selectOptionData() as Record<string, Users>;
        const allUsers: Users = [];

        for (const key in originalUserList) {
            const users = originalUserList[key];
            users.forEach((user) => {
                allUsers.push(user);
            });
        }

        // Sort users alphabetically
        allUsers.sort((a, b) => {
            const nameA = Object.values(a)[1].toLowerCase();
            const nameB = Object.values(b)[1].toLowerCase();

            if (sorting === "asc") {
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
            }

            if (sorting === "desc") {
                if (nameB < nameA) return -1;
                if (nameB > nameA) return 1;
            }

            return 0;
        });

        const userListSortedAlphabetically: Record<string, Users> = {};

        if (sorting === "asc") {
            userListSortedAlphabetically.name_asc = allUsers;
        }

        if (sorting === "desc") {
            userListSortedAlphabetically.name_desc = allUsers;
        }

        this.createSelectize(this.userListTarget, userListSortedAlphabetically);
    }
}
