import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        'jobTemplateCheckbox',
        'jobTemplateNameContainer'
    ];

    jobTemplateCheckboxTarget: HTMLInputElement;
    jobTemplateNameContainerTarget: HTMLInputElement;

    hasJobTemplateNameContainerTarget: boolean;

    scrollTop() {
        document.documentElement.scrollTop = 0;
    }

    toggleJobTemplateNameVisibility() {
        if (!this.hasJobTemplateNameContainerTarget) return;

        if (this.jobTemplateCheckboxTarget.checked) {
            this.jobTemplateNameContainerTarget.classList.remove('hidden');
        } else {
            this.jobTemplateNameContainerTarget.classList.add('hidden');
        }
    }
}
