import { Controller } from "stimulus";
import DataUtils from "../util/data_utils";
import Spinner from "../util/spinner";

export default class extends Controller {
    static targets = [
        "wrapperContainer",
        "loadingContainer",
    ];

    private wrapperContainerTarget: HTMLElement;
    private loadingContainerTarget: HTMLElement;

    private spinner: Spinner;
    private bottomScrollDetectOffset = 50;
    private batchSize = 5;
    private isLoading = false;
    private morePages = true;
    private total = 0;
    private offset = 0;
    private isTourSet = false;

    async connect() {
        await this.loadInitialPage();
    }

    private async loadInitialPage() {
        await this.loadNextPage();
        this.observeScrolling();
    }

    private observeScrolling() {
        this.wrapperContainerTarget.addEventListener('scroll', async () => {
            if (this.reachedNearBottomOfPage()) {
                if (!this.isCurrentlyLoading() && this.hasMorePages()) {
                    await this.loadNextPage();
                }
            }
        });
    }

    private get streamUrl(): string {
        return (this.element as HTMLElement).dataset.streamUrl;
    }

    private reachedNearBottomOfPage(): boolean {
        return this.wrapperContainerTarget.scrollTop + this.wrapperContainerTarget.offsetHeight > this.wrapperContainerTarget.offsetHeight - this.bottomScrollDetectOffset;
    }

    private markAsIsLoading() {
        this.isLoading = true;
        this.displayLoadingAnimation();
    }

    private markAsDoneLoading() {
        this.isLoading = false;
        this.removeLoadingAnimation();
    }

    private displayLoadingAnimation() {
        this.loadingContainerTarget.classList.remove("hidden");
        this.spinner = new Spinner(this.loadingContainerTarget);
        this.spinner.start();
    }

    private removeLoadingAnimation() {
        this.spinner.stop();
        this.loadingContainerTarget.classList.add("hidden");
    }

    private markAsNoMorePagesAvailable() {
        this.morePages = false;
    }

    private hasMorePages() {
        return this.morePages === true;
    }

    private isCurrentlyLoading() {
        return this.isLoading === true;
    }

    private updateOffset() {
        const newOffset = this.offset + this.batchSize;
        this.offset = newOffset;
        this.wrapperContainerTarget.setAttribute('data-stream-offset', newOffset.toString());
    }

    private async requestData(): Promise<any> {
        const offset = this.offset && { offset: this.offset.toString() };

        const data = await DataUtils.request(
            this.streamUrl,
            { ...offset },
            { parse: false },
            {
                method: "GET",
                credentials: "same-origin",
                headers: { "X-Requested-With": "XMLHttpRequest" }
            },
        ).catch(() => {
            console.error('Unexpected error with downloading comment list');
        });
        return data;
    }

    private addNewCommentsToList(data: string) {
        const tmpElement = document.createElement('div');
        tmpElement.insertAdjacentHTML('beforeend', data);

        const newListItems = tmpElement.querySelectorAll("[data-role='comment-item']") as NodeListOf<HTMLElement>;
        if (this.total > 0 && (tmpElement.querySelector("[data-role='comment-item']") as HTMLElement).dataset.noComments) {
            this.markAsNoMorePagesAvailable();
        }
        this.total = this.total + newListItems.length;
        const firstMarkedElement = tmpElement.querySelectorAll('.comment-item__icon--marked')[0] as HTMLElement;
        if (firstMarkedElement && !this.isTourSet) {
            this.isTourSet = true;
            firstMarkedElement.dataset.controller = 'onboarding-tour';
            firstMarkedElement.dataset.onboardingTourId = '4';
            firstMarkedElement.dataset.onboardingTourStepId = '1';
            firstMarkedElement.dataset.onboardingTourPosition = 'left';
            firstMarkedElement.dataset.onboardingTourText = (this.element as HTMLElement).dataset.tourText;
            firstMarkedElement.addEventListener('click', e => e.stopPropagation());
        }

        if (newListItems.length > 0) {
            this.wrapperContainerTarget.append(tmpElement.firstChild);
        }

        this.updateOffset();

        if (newListItems.length < this.batchSize) {
            this.markAsNoMorePagesAvailable();
        }
    }

    private async loadNextPage() {
        this.markAsIsLoading();

        let data = await this.requestData();
        if (data) {
            data = await data.text();
            this.addNewCommentsToList(data);
        }

        this.markAsDoneLoading();
        this.wrapperContainerTarget.classList.remove("hidden");
    }
}
