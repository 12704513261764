import { Controller } from 'stimulus';
import Sentry from "@utils/sentry";

export default class extends Controller {
    static targets = [
        "counter"
    ];

    counterTargets: HTMLElement[];

    update(event: CustomEvent) {
        event.detail.xhr.then((data: { members_count: string }) => {
            if (data.members_count) {
                this.countTarget.innerText = String(data.members_count);
            } else {
                Sentry.notify(new Error("CountController: 'members_count' field missing in response"));
            }
        });
    }

    increment(event: Event) {
        if (this.singleCounter()) this.changeValue(this.countTarget, 1);
        else this.changeValue(this.counterTargetByName(this.counterName(event)), 1);
    }

    decrement(event: Event) {
        if (this.singleCounter()) this.changeValue(this.countTarget, -1);
        else this.changeValue(this.counterTargetByName(this.counterName(event)), -1);
    }

    private changeValue(element, byCount) {
        const currentCount = parseInt(element.innerText);
        element.innerText = String(currentCount + byCount);
    }

    private counterTargetByName(name) {
        return this.counterTargets.find((target) => target.dataset.counter === name);
    }

    private counterName(event) {
        return (event.target as HTMLElement).dataset.counter;
    }

    private singleCounter() {
        return this.counterTargets.length === 1;
    }

    private get countTarget() {
        return this.counterTargets[0];
    }
}
