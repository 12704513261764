import { ListResource } from "./list_view_controller";
import RemoteLinkController from "./remote_link_controller";

export default class RemoteListLinkController extends RemoteLinkController {
    onSuccess(event?: CustomEvent) {
        if (event) {
            event.stopPropagation();
        }

        super.onSuccess(event);

        const listResource: ListResource = event.detail.xhr.responseJSON;
        const eventName = `list-view-${listResource.action}-${listResource.resource_name}`;
        const newEvent = new CustomEvent(eventName, { detail: listResource });

        document.querySelectorAll(`[data-list-view-model-name="${listResource.resource_name}"]`).forEach((element) => {
            element.dispatchEvent(newEvent);
        });
    }

    onError(event?: CustomEvent) {
        if (event) {
            event.stopPropagation();
        }

        super.onError(event);
    }
}
