import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        'slidesContainer',
        'dotsContainer'
    ];

    private slidesContainerTarget: HTMLDivElement;
    private dotsContainerTarget: HTMLDivElement;
    private activeSlide: number;

    connect() {
        this.activeSlide = parseInt(this.data.get('active'));

        this.dotsContainerTarget.childNodes.forEach((el: HTMLElement, i: number) => {
            if(i === this.activeSlide){
                el.classList.add('active');
            }
        });

        this.slidesContainerTarget.childNodes.forEach((el: HTMLElement, i: number) => {
            if(i === this.activeSlide){
                el.classList.add('active');
            }
        });

        this.slide();
    }

    goToPrev(e: Event){
        e.preventDefault();
        this.activeSlide = this.activeSlide - 1;
        this.slide();
    }

    goToNext(e: Event){
        e.preventDefault();

        this.activeSlide = this.activeSlide + 1;
        this.slide();
    }

    private slide() {
        this.setActiveClass(this.dotsContainerTarget);
        this.setActiveClass(this.slidesContainerTarget);
    }

    private setActiveClass(container: HTMLElement){
        Array.from(container.childNodes).forEach((el: HTMLElement, i: number) => {
            el.classList.toggle('active', i === this.activeSlide);
        });
    }
}
