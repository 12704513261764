import { Controller } from "stimulus";
import DataUtils from "@utils/data_utils";
import Sentry from "@utils/sentry";

export default class extends Controller {
    markAsSeen() {
        DataUtils.request(
            this.markAsSeenPath,
            {},
            { parse: false },
            { method: "DELETE" },
        )
            .catch(err => {
                Sentry.notify(err);
            });
    }


    private get markAsSeenPath(): string {
        return this.data.get("markAsSeenPath");
    }
}
