import { Controller } from 'stimulus';
import { Turbo } from "@hotwired/turbo-rails";

interface HTMLTurboFrameElement extends HTMLElement {
    src: string
}

export default class extends Controller {
    static targets = ['frame', 'checkbox'];

    private frameTarget: HTMLTurboFrameElement;
    private checkboxTargets: HTMLInputElement[];
    private hasFrameTarget: boolean;
    private param: string;

    connect() {
        this.param = this.data.get("param");
    }

    updateSrcParam(event: Event) {
        if (!this.hasFrameTarget) return;
        const frameLocation = this.frameTarget.src;
        const url = new URL(frameLocation, location.origin);
        url.searchParams.delete(this.param);
        this.checkboxTargets.forEach((checkbox) => {
            if (checkbox.checked) {
                url.searchParams.append(this.param, checkbox.value);
            }
        });

        Turbo.visit(url.href, { action: "replace", frame: this.frameTarget.id });
    }
}
